import AppConstants from './AppConstants'
import WebAPIUtils from '../actions/WebAPIUtils'
import AuctionFunctions from './AuctionFunctions'
import moment from 'moment'

export default {
  isVINValid: (rawVIN) => isVINValid(rawVIN),

  isVINCheckDigitCorrect: (vin) => isVINCheckDigitCorrect(vin),

  sanitizedVIN: function(rawVIN) {
    let convertedVIN = rawVIN
    if (convertedVIN) {
      convertedVIN = convertedVIN.toUpperCase()
    }
    // Only alphanumeric
    convertedVIN = convertedVIN.replace(/[^A-Z0-9]/gi,'')
    // Replace chars not allowed in VINS
    convertedVIN = convertedVIN.replace(/I/g, "1")
    convertedVIN = convertedVIN.replace(/O/g, "0")
    convertedVIN = convertedVIN.replace(/Q/g, "0")
    return convertedVIN
  },

  detectVINInString: function(vinString) {
    var uppercasedString = ''
    if (vinString) {
      uppercasedString = vinString.toUpperCase()
    }
    if (isVINValid(uppercasedString) && isVINCheckDigitCorrect(uppercasedString)) {
      return uppercasedString
    } else if (uppercasedString.length > AppConstants.VINLength) {
      // Look for VIN in the full string
      const regex = /(?:^|\W)(\w{17})(?:\W|$)/
      const results = uppercasedString.match(regex)
      if (results && results.length >= 1) {
        var vinCandidate = results[1]
        if (vinCandidate && isVINValid(vinCandidate) && isVINCheckDigitCorrect(vinCandidate)) { return vinCandidate }
      }
    }

    return null
  },

  sanitizedMileage: function(rawMileage) {
    return rawMileage.replace(/[^0-9.]/gi,'')
  },

  stripNonNumeric: function(sourceString) {
    return sourceString.replace(/[^0-9.]/gi,'')
  },

  stripNonNumericNoDecimal: function(sourceString) {
    return sourceString.replace(/[^0-9]/gi,'')
  },

  numberIsInThousands: (srcNumber) => numberIsInThousands(srcNumber),

  trueMileageFromInput: function(inputMileage) {
    return numberIsInThousands(inputMileage) ? inputMileage * 1000 : inputMileage
  },

  hasValidIDMatchProp: function(props) {
    if (props.navMatch && props.navMatch.params && props.navMatch.params.id) {
      return true
    }
    return false
  },

  valuationURL: function(valuation) {
    return `/vehicles/show/${valuation.uuid}`
  },

  folderURL: function(folder) {
    return `/vehicles/folder/${parseInt(folder.id) + 11200}`
  },

  folderIDFromParam: function(idParam) {
    return parseInt(idParam - 11200)
  },

  isValidEmailAddress: function(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  arrayMove: function(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr
  },

  formattedProviderNamesList: function(providerKeys) {
    if (!providerKeys || providerKeys.length === 0) {
      return null
    }

    var trialBookNames = providerKeys.map((t) => AppConstants.providerNames[t])

    if (trialBookNames.length > 1) {
      trialBookNames[trialBookNames.length - 1] = `and ${trialBookNames[trialBookNames.length - 1]}`
    }
    return trialBookNames.join(', ')
  },

  reorderOfferingsForDisplay: function(offerings) {
    let reorderedOfferings = offerings && offerings.length > 0 ? [...offerings] : []
    let pmrIndex = reorderedOfferings.findIndex((offering) => offering.sku === AppConstants.skuMappings['pmr'])
    if (pmrIndex !== -1) {
      reorderedOfferings.unshift(reorderedOfferings.splice(pmrIndex, 1)[0])
    }

    let llmIndex = reorderedOfferings.findIndex((offering) => offering.sku === AppConstants.skuMappings['universe'])
    if (llmIndex !== -1) {
      reorderedOfferings.unshift(reorderedOfferings.splice(llmIndex, 1)[0])
    }

    let marketTrackerIndex = reorderedOfferings.findIndex((offering) => offering.sku === AppConstants.skuMappings['marketTracker'])
    if (marketTrackerIndex !== -1) {
      reorderedOfferings.unshift(reorderedOfferings.splice(marketTrackerIndex, 1)[0])
    }

    let auctionsPlusIndex = reorderedOfferings.findIndex((offering) => offering.sku === AppConstants.skuMappings['auctions_plus'])
    if (auctionsPlusIndex !== -1) {
      reorderedOfferings.unshift(reorderedOfferings.splice(auctionsPlusIndex, 1)[0])
    }

    let lotSenseIndex = reorderedOfferings.findIndex((offering) => offering.sku === AppConstants.skuMappings['my_lot'])
    if (lotSenseIndex !== -1) {
      reorderedOfferings.unshift(reorderedOfferings.splice(lotSenseIndex, 1)[0])
    }

    let carblyBundleIndex = reorderedOfferings.findIndex((offering) => offering.sku === AppConstants.skuMappings['carbly_plus_bundle'])

    if (carblyBundleIndex !== -1) {
      reorderedOfferings.unshift(reorderedOfferings.splice(carblyBundleIndex, 1)[0])
    }
    return reorderedOfferings
  },

  round: function(value, decimals = 0) {
    const roundFactor = 10 ** decimals
    return Math.round((value +  Number.EPSILON) * roundFactor) / roundFactor
  },

  stateAbbreviations: function() {
    return {
      'AL': 'Alabama',
      'AK': 'Alaska',
      'AS': 'America Samoa',
      'AZ': 'Arizona',
      'AR': 'Arkansas',
      'CA': 'California',
      'CO': 'Colorado',
      'CT': 'Connecticut',
      'DE': 'Delaware',
      'DC': 'District of Columbia',
      'FM': 'Federated States Of Micronesia',
      'FL': 'Florida',
      'GA': 'Georgia',
      'GU': 'Guam',
      'HI': 'Hawaii',
      'ID': 'Idaho',
      'IL': 'Illinois',
      'IN': 'Indiana',
      'IA': 'Iowa',
      'KS': 'Kansas',
      'KY': 'Kentucky',
      'LA': 'Louisiana',
      'ME': 'Maine',
      'MH': 'Marshall Islands',
      'MD': 'Maryland',
      'MA': 'Massachusetts',
      'MI': 'Michigan',
      'MN': 'Minnesota',
      'MS': 'Mississippi',
      'MO': 'Missouri',
      'MT': 'Montana',
      'NE': 'Nebraska',
      'NV': 'Nevada',
      'NH': 'New Hampshire',
      'NJ': 'New Jersey',
      'NM': 'New Mexico',
      'NY': 'New York',
      'NC': 'North Carolina',
      'ND': 'North Dakota',
      'OH': 'Ohio',
      'OK': 'Oklahoma',
      'OR': 'Oregon',
      'PW': 'Palau',
      'PA': 'Pennsylvania',
      'PR': 'Puerto Rico',
      'RI': 'Rhode Island',
      'SC': 'South Carolina',
      'SD': 'South Dakota',
      'TN': 'Tennessee',
      'TX': 'Texas',
      'UT': 'Utah',
      'VT': 'Vermont',
      'VI': 'Virgin Island',
      'VA': 'Virginia',
      'WA': 'Washington',
      'WV': 'West Virginia',
      'WI': 'Wisconsin',
      'WY': 'Wyoming'
    }
  },

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = function() {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error)
    };
  },

  uploadVehiclePhotos: function(photos, vehicleUUID, onSuccess, onError, clientID) {
    Promise.all(photos.map(function (photo) {
      var prefix = ''
      if (photo.data.data.indexOf('data:image/') === -1) {
        prefix = 'data:image/png;base64,'
      }
      return WebAPIUtils.uploadVehiclePhoto(`${prefix}${photo.data.data}`, photo.primary === true, vehicleUUID, clientID)
    }))
    .then((response) => {
      onSuccess(response)
    })
    .catch((error) => {
      onError(error)
    })
  },

  boundingBoxForCoordinates: function(locations) {
    var minLongitude, maxLongitude, minLatitude, maxLatitude
    for (let loc of locations) {
      const compLatitude = parseFloat(loc.latitude)
      const compLongitude = parseFloat(loc.longitude)

      minLongitude = !minLongitude || compLongitude < minLongitude ? compLongitude : minLongitude
      maxLongitude = !maxLongitude || compLongitude > maxLongitude ? compLongitude : maxLongitude
      minLatitude = !minLatitude || compLatitude < minLatitude ? compLatitude : minLatitude
      maxLatitude = !maxLatitude || compLatitude > maxLatitude ? compLatitude : maxLatitude
    }

    return [[minLatitude, minLongitude], [maxLatitude, maxLongitude]]
  },

  cacheKeyFromObject: function(obj) {
    function sortObject(o) {
      if (Array.isArray(o)) {
        return o.map(sortObject).sort((a, b) => {
          if (typeof a === 'object' && typeof b === 'object') {
            return JSON.stringify(a).localeCompare(JSON.stringify(b))
          }
          return a > b ? 1 : -1
        })
      } else if (o !== null && typeof o === 'object') {
        return Object.keys(o).sort().reduce((accum, key) => {
          accum[key] = sortObject(o[key])
          return accum
        }, {})
      } else {
        return o
      }
    }
    const ordered = sortObject(obj)
    return JSON.stringify(ordered)
  },

  distanceInMilesBetweenCoordinates: function(lat1, lon1, lat2, lon2) {
    if (!lat1 || !lon1 || !lat2 || !lon2) { return null }
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344  // Kilometers
    dist = dist * 0.621371 // Miles
    return dist
  },

  stripCommas: function(value) {
    if (Number.isInteger(value) || typeof value === 'number') return value
    if (value === null || value === undefined || value === '') return 0
    return parseInt(value.replace(/[$,]/g, ''))
  },

  calculatorComputedValueForField: function(fieldName, valuation) {
    if (!valuation) return 0
    const sp = this.stripCommas(valuation.sale_price_cents)
    const pp = this.stripCommas(valuation.purchase_price_cents)
    const rc = this.stripCommas(valuation.recon_cost_cents)
    const tc = this.stripCommas(valuation.transportation_cost_cents)
    const af = this.stripCommas(valuation.auction_fees_cost_cents)
    const oc = this.stripCommas(valuation.misc_fees_cost_cents)
    const profit = this.stripCommas(valuation.profit_cents)

    switch (fieldName) {
      case 'profit_cents':
        if (sp && pp) {
          return sp - pp - rc - tc - af - oc
        }
        return 0

      case 'sale_price_cents':
        return pp + rc + tc + af + oc + profit

      case 'purchase_price_cents':
        return sp - rc - tc - af - oc - profit
    }

    return 0
  },

  calculatorInitialValuesForValuation: function(valuation, rooftop, wholesalePriceEstimate, retailPriceEstimate, auctionListingCRScore, auctionListingLongitude, auctionListingLatitude, marketPrice, hasAuctionListing = true) {

    if (valuation.purchase_price_cents === null || valuation.purchase_price_cents === undefined) {
      if (wholesalePriceEstimate) {
        valuation.purchase_price_cents = `${wholesalePriceEstimate}`
      } else {
        valuation.purchase_price_cents = 0
      }
    } else {
      valuation.purchase_price_cents = valuation.purchase_price_cents / 100
    }

    if (valuation.sale_price_cents === null || valuation.sale_price_cents === undefined) {
      if (valuation.lot_vehicle?.price_cents && valuation.lot_vehicle?.price_cents > 0) {
        valuation.sale_price_cents = `${valuation.lot_vehicle?.price_cents / 100}`
      } else if (valuation.lot_vehicle?.retail_market_price) {
        valuation.sale_price_cents = `${valuation.lot_vehicle?.retail_market_price}`
      } else if (marketPrice) {
        valuation.sale_price_cents = `${marketPrice}`
      } else if (retailPriceEstimate) {
        valuation.sale_price_cents = `${retailPriceEstimate}`
      } else {
        valuation.sale_price_cents = 0
      }
    } else {
      valuation.sale_price_cents = valuation.sale_price_cents / 100
    }


    if (!valuation.profit_cents) {
      valuation.profit_cents = 0
    } else {
      valuation.profit_cents = valuation.profit_cents / 100
    }

    let computedCRScore = null
    if (valuation.recon_cost_cents === null || valuation.recon_cost_cents === undefined) {
      if (auctionListingCRScore !== null && auctionListingCRScore !== undefined) {
        computedCRScore = auctionListingCRScore
        const computedReconCost = AuctionFunctions.reconCostForCRScore(computedCRScore) / 100
        valuation.recon_cost_cents < 20 ? 20 : valuation.recon_cost_cents
        valuation.recon_cost_cents = `${parseInt(computedReconCost)}`
      } else if ((auctionListingCRScore === null || auctionListingCRScore === undefined) && hasAuctionListing && rooftop.vehicle_condition_none_cost_cents) {
        valuation.recon_cost_cents = rooftop.vehicle_condition_none_cost_cents / 100
      } else {
        valuation.recon_cost_cents = rooftop.recon_cost_cents || 0
      }

    } else {
      valuation.recon_cost_cents = valuation.recon_cost_cents / 100
    }
    valuation.computed_cr_score = computedCRScore

    let computedTransportCostDistance = null
    if (valuation.transportation_cost_cents === null || valuation.transportation_cost_cents === undefined) {
      if (rooftop?.vehicle_transportation_cost_cents && rooftop?.latitude && rooftop?.longitude && auctionListingLatitude && auctionListingLongitude) {
        const distance = this.distanceInMilesBetweenCoordinates(rooftop.latitude, rooftop.longitude, auctionListingLatitude, auctionListingLongitude)

        valuation.transportation_cost_cents = distance * (rooftop?.vehicle_transportation_cost_cents / 100)
        valuation.transportation_cost_cents = parseInt(valuation.transportation_cost_cents)
        computedTransportCostDistance = parseInt(distance)
      } else {
        valuation.transportation_cost_cents = 0
      }
    } else {
      valuation.transportation_cost_cents = valuation.transportation_cost_cents / 100
    }
    valuation.computed_transport_cost_distance = computedTransportCostDistance


    if (valuation.auction_fees_cost_cents === null || valuation.auction_fees_cost_cents === undefined) {
      if (rooftop?.vehicle_auction_fees_cost_cents && valuation.runlist_listing) {
        valuation.auction_fees_cost_cents = rooftop?.vehicle_auction_fees_cost_cents / 100
      } else {
        valuation.auction_fees_cost_cents = 0
      }

    } else {
      valuation.auction_fees_cost_cents = valuation.auction_fees_cost_cents / 100
    }

    valuation.misc_fees_cost_cents = (valuation.misc_fees_cost_cents || rooftop?.vehicle_misc_fees_cost_cents || 0) / 100

    valuation.locked_calculator_field = valuation.locked_calculator_field || 'profit_cents'

    return valuation
  },

  calculatorOriginalValuesFromValuation: function(valuation) {
    return (
      {
        purchase_price_cents: valuation.purchase_price_cents,
        sale_price_cents: valuation.sale_price_cents,
        profit_cents: valuation.profit_cents,
        recon_cost_cents: valuation.recon_cost_cents,
        transportation_cost_cents: valuation.transportation_cost_cents,
        auction_fees_cost_cents: valuation.auction_fees_cost_cents,
        misc_fees_cost_cents: valuation.misc_fees_cost_cents,
        locked_calculator_field: valuation.locked_calculator_field,
        uuid: valuation.uuid
      }
    )
  },

  calculatorServerParamsForValuation: function(valuation) {
    const serverKeys = ['purchase_price_cents', 'sale_price_cents', 'profit_cents', 'recon_cost_cents', 'transportation_cost_cents', 'auction_fees_cost_cents', 'misc_fees_cost_cents']

    let serverParams = Object.keys(valuation)
    .filter(i => serverKeys.includes(i))
    .reduce((acc, key) => {
      acc[key] = (parseInt(valuation[key]) * 100);
      return acc;
    }, {})

    serverParams['locked_calculator_field'] = valuation.locked_calculator_field
    return serverParams
  },

  startOfDayUTC: function() {
    return moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
  }

}

function isVINValid(rawVIN) {
  // FIXME: implement check digit for isvalid?
  // all callers would need to do this in the right order:
  // 1. Check length
  // 2. Sanitize
  // 3. THen check validity

  const vin = rawVIN.toUpperCase()
  return (vin.length === AppConstants.VINLength && vin.indexOf('I') === -1 && vin.indexOf('O') === -1)
}


function numberIsInThousands(srcNumber) {
  if (srcNumber.indexOf('.') === -1 && srcNumber.length > 3) {
    return false
  }
  return true
}


var transliterationKey = {
  'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8, 'J': 1, 'K': 2, 'L': 3, 'M': 4, 'N': 5, 'P': 7, 'R': 9, 'S': 2, 'T': 3, 'U': 4, 'V': 5, 'W': 6, 'X': 7, 'Y': 8, 'Z': 9
}

var weightFactors = [8,7,6,5,4,3,2,10,0,9,8,7,6,5,4,3,2]

function isVINCheckDigitCorrect(vin) {
  var suppliedCheckDigit = vin[8]
  var newVIN = vin.split('')
  var products = []

  for (var i = 0; i < newVIN.length; i++) {
    if (i !== 8) {
      if (newVIN[i].charCodeAt(0) >= 65 && newVIN[i].charCodeAt(0) <= 90){
        newVIN[i] = transliterationKey[newVIN[i]]
      }
      products.push(parseInt(newVIN[i]) * weightFactors[i])
    }
  }

  var sumOfResult = 0
  var computedCheckDigit = 0

  sumOfResult = products.reduce((total, num) => {
    return total + num
  })

  computedCheckDigit = (sumOfResult % 11).toString()
  if (computedCheckDigit === '10') {
    computedCheckDigit = 'X'
  }

  if (suppliedCheckDigit === computedCheckDigit) {
    return true
  } else {
    console.log("Check digit failed")
    return false
  }
}
