import React from 'react'
import Slider from "react-slick"
import Lightbox from 'react-image-lightbox'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import AuctionStore from '../../api/stores/AuctionStore'
import Valuation from '../valuations/Valuation'
import AppConstants from '../../api/constants/AppConstants'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import ActivitiesInfiniteScroll from '../activities/ActivitiesInfiniteScroll'
import Spinner from '../common/Spinner'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'
import TargetIcon from '../common/icons/TargetIcon'
import ContractIcon from '../common/icons/ContractIcon'
import ExpandIcon from '../common/icons/ExpandIcon'
import AuctionLocationIcon from '../common/icons/AuctionLocationIcon'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import WatchlistIcon from '../common/icons/WatchlistIcon'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import SessionStore from '../../api/stores/SessionStore'
import AuctionEdgeBidding from '../bidding/AuctionEdgeBidding'
import AccountNeedsAction from '../user/AccountNeedsAction'
import pluralize from 'pluralize'
import ValuationStore from '../../api/stores/ValuationStore'
import CalendarIcon from '../common/icons/CalendarIcon'
import Avatar from '../user/Avatar'
import CheckIcon from '../common/icons/CheckIcon'
import BooksUpsellList from '../valuations/BooksUpsellList'

export default class AuctionListing extends React.Component {
  state = {
    isShowingLightbox: false,
    currentLightboxIndex: 0,
    isShowingFullVehicleOptions: false,
    isShowingFullComments: false,
    arePhotosLoaded: false,
    isShowingEdgeCR: false,
    shouldBounceWatchlist: false,
    shouldShowAccountNeedsAction: !SessionStore.hasBookOfferings() || SessionStore.shouldShowAccountReactivation(),
    isShowingSmartAuctionConditionReport: SessionStore.showAuctionConditionReport,
    carfaxSnapshotStatus: null,
    isShowingAdesaDamages: false,
    isShowingTires: false
  }

  componentDidMount() {
    this.clientID = this.props.clientID
    const { listing } = this.props

    // Preload images so that the carousel doesn't jump around
    if (listing && listing.images && listing.images.length > 0) {
      this.loadedImages = [...listing.images]

      for (let img of this.loadedImages) {
        var my_image = new Image()
        my_image.onload = (() => {
          this.photoLoaded(img)
        })
        my_image.src = img
      }

      // Fallback, just show whatever images we have loaded at this point
      setTimeout(() => {
        if (this.refs.mainRef) {
          this.setState({ arePhotosLoaded: true })
        }
      }, 5000)
    }
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`carfax_summary_changed_${this.props.vehicleID}`, this.carfaxSummaryDidChange)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.valuation?.uuid !== prevProps.valuation?.uuid) {
      if (prevProps.valuation?.uuid) {
        ValuationStore.removeListener(`carfax_summary_changed_${prevProps.valuation?.uuid}`, this.carfaxSummaryDidChange)
      }
      if (this.props.valuation?.uuid) {
        console.log("SUBBING:::::", `carfax_summary_changed_${this.props.valuation.uuid}`);
        ValuationStore.on(`carfax_summary_changed_${this.props.valuation.uuid}`, this.carfaxSummaryDidChange)
      }
    }
  }

  carfaxSummaryDidChange = () => {
    const vehicleID = this.props.valuation?.uuid
    const carfaxSummary = ValuationStore.carfaxSummaryWithID(vehicleID)
    this.setState({
      carfaxSnapshotStatus: ValuationFunctions.carfaxSnapshotStatus(carfaxSummary)
    })
  }

  photoLoaded = (imgSource) => {
    // We may have timeout out already, and are showing
    if (this.state.arePhotosLoaded === true) { return }
    const { listing } = this.props
    const images = listing.images
    this.loadedImages = this.loadedImages.filter((im) => im !== imgSource)
    if (images.length > 3) {
      if (!this.loadedImages.includes(images[0]) && !this.loadedImages.includes(images[1]) && !this.loadedImages.includes(images[2])) {
        this.setState({
          arePhotosLoaded: true
        })
        return
      }
    }

    if (this.loadedImages.length === 0) {
      this.setState({
        arePhotosLoaded: true
      })
    }
  }

  renderBuyNow = (listing) => {
    return (
      <div>
        <div>Buy now price: <span style={{fontWeight: 'bold'}}>{Formatters.formatCurrency(listing.buy_now_price)}</span></div>
        {
          listing.buy_now_until &&
          <div>Until: {Formatters.formatVerboseDate(listing.buy_now_until)}</div>
        }
      </div>
    )
  }

  renderTires = (tires) => {
    return (
      <div style={{marginTop: '10px', marginBottom: '10px'}}>
        <table style={{width: '100%'}} className='auction-tires-table'>
          <thead>
          <tr>
            <th>Wheel</th>
            <th>Brand</th>
            <th>Size</th>
            <th>Tread</th>
            <th>Type</th>
          </tr>
          </thead>
          <tbody>
          {
            tires.map((tire, index) => (
              <tr key={`tire-${index}`}>
                <td>{tire.position}</td>
                <td>{tire.brand}</td>
                <td>{tire.size}</td>
                <td>{tire.tread_depth}</td>
                <td>{tire.type}</td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    )
  }

  toggleWatchlist = () => {
    const listing = this.props.listing
    AuctionStore.toggleListingWatchlist(listing)

    var newListing = Object.assign(listing)
    newListing.watchlist = !listing.watchlist
    this.setState({
      shouldBounceWatchlist: listing.watchlist === true,
      listing: newListing
    })
    platformFunctions.logAnalyticsEvent("Auctions", "Toggled Watchlist - Listing")
  }

  render() {
    const { isShowingLightbox, currentLightboxIndex, isShowingFullVehicleOptions, isShowingFullComments, arePhotosLoaded, isShowingEdgeCR, shouldBounceWatchlist, isShowingSmartAuctionConditionReport, carfaxSnapshotStatus } = this.state
    const { listing, isLoadingValuation, valuation } = this.props

    var drivetrainArray = []
    if (listing) {
      if (listing.engine) { drivetrainArray.push(listing.engine)}
      if (listing.drive_train) { drivetrainArray.push(listing.drive_train)}
      if (listing.transmission) { drivetrainArray.push(listing.transmission)}
    }

    const drivetrainString = drivetrainArray.length > 0 ? drivetrainArray.join(', ') : null
    const isOnlineAuction = listing && listing.auction && listing.auction.type === 'digital'
    const showValuation = this.props.showValuation === false ? false : true
    const isWatchlist = listing && listing.watchlist === true
    const isEDGE = listing?.auction?.provider === 'edge'
    const isSMARTAUCTION = listing?.auction?.provider === 'smart_auction'
    const isAdesa = listing?.auction?.provider === 'adesa'
    const exteriorColor = ValuationFunctions.normalizedColor(listing?.exterior_color)
    const interiorColor = ValuationFunctions.normalizedColor(listing?.interior_color)

    let dragging = false;
    var photosSettings = {
      arrows: isShowingLightbox ? false : true,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      swipeToSlide: true,
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false
    }

    const allowLightbox = this.props.allowLightbox !== false
    const isEmbedded = this.props.isEmbedded === true

    return (
      <div className={`body-content${isEmbedded ? ' auction-listing-body-content-embedded' : ''}`} style={{position: 'relative'}} ref='mainRef'>
        {
          arePhotosLoaded === false &&
          <div className="auction-listing-photos-placeholder-c"></div>
        }
        {
          listing && listing.images && listing.images.length > 0 && arePhotosLoaded === true &&
          <div className="auction-listing-photos-c">
            <Slider {...photosSettings}>
              {
                listing.images.map((p, index) => {
                  if (allowLightbox === true) {
                    return (
                      <a href="#zoom" key={`al-phot-${index}`} onClick={(e) => {
                        e.preventDefault()
                        if (!dragging) {
                          this.setState({
                            isShowingLightbox: true,
                            currentLightboxIndex: index
                          })
                        }
                      }}>
                        <img src={p} alt='Listing' className="auction-listing-photo"/>
                      </a>
                    )
                  } else {
                    return (
                      <img src={p} alt='Listing' key={`al-phot-${index}`} className="auction-listing-photo"/>
                    )
                  }
                })
              }
            </Slider>
          </div>
        }

        {
          listing && (!listing.images || listing.images.length === 0) &&
          <div style={{color: '#aaa'}}>No photos are available for this vehicle</div>

        }

        {
          listing &&
          <div className="auction-listing-show">
            <div className={`split-row content-header`} style={{alignItems: 'flex-start'}}>
              <div>
                <h1>{listing.year} {listing.make} {listing.model}</h1>
                {
                  Boolean(listing.trim || listing.body_style) &&
                  <div className='split-row split-row-left' style={{alignItems: 'center'}}>
                    <h2>{listing.trim} {listing.body_style}</h2>
                    {
                      drivetrainString !== null &&
                      <div style={{marginLeft: '20px', marginBottom: '5px', color: '#aaa'}}>{drivetrainString}</div>
                    }
                  </div>
                }
              </div>

              <div>
                <div className='split-row split-row-right' style={{alignItems: 'center'}}>
                  {
                    listing.vehicle?.structured_data?.target_bid_price &&
                    <div className="target-price">
                      <TargetIcon width={24} height={24} style={{marginTop: '-2px', marginRight: '3px'}} /> Target {Formatters.formatCurrency(listing.vehicle.structured_data.target_bid_price)}
                    </div>
                  }

                  <div>
                    {
                      listing.auction_in_past !== true && listing.vin &&
                      <a href="#toggle-watchlist" className={`watchlist${isWatchlist ? ' watchlist-active' : ''}`} style={{whiteSpace: 'nowrap'}} onClick={(e) => {
                        e.preventDefault()
                        this.toggleWatchlist()
                      }}>
                        <WatchlistIcon color='currentColor' width={20} height={20} className={shouldBounceWatchlist ? 'watchlist-bounce' : ''} />
                        {isWatchlist ? 'On' : 'Off'}&nbsp;watchlist
                      </a>
                    }
                  </div>
                </div>

                {
                      listing.team_watches && listing.team_watches.length > 0 &&
                      <div className='team-watchers'>
                        <div className='secondary-text'>On watchlist: </div>
                        {
                          listing.team_watches.map((user, index) => {
                            return (
                              <Avatar user={user} size={AppConstants.avatarSizes.xsmall} key={`watcher-${index}`} />
                            )
                          })
                        }
                      </div>
                    }
              </div>
            </div>

            {
              this.props.hasLinkedAuctionEdge && listing.auction.code === 'EDGE_DOT_AUCTION' &&
              <div style={{}}>
                <AuctionEdgeBidding listingId={listing.source_id} />
              </div>
            }

            <div className="auction-listing-body">

              <div className='auction-body-vehicle auction-essentials card'>
                {
                  listing.mileage !== null && listing.mileage > 0 &&
                  <div style={{fontSize: '1.6rem', fontWeight: 'bold'}}>
                    {Formatters.formatThousands(listing.mileage)} <span style={{color: '#aaa'}}>{pluralize('mile', listing.mileage, false)}</span>
                  </div>
                }
                {
                  listing.vin &&
                  <div className="vin" style={{marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #ddd'}}>{listing.vin}
                    <CopyToClipboard
                      text={listing.vin}
                      onCopy={() => {
                        toast.success("VIN Copied to Clipboard")
                      }}
                      >
                      <img src="/images/clipboard-copy-icon.png" style={{width: '16px', height: '16px', cursor: 'pointer', marginLeft: '4px'}} alt="Copy VIN to clipboard" title="Copy VIN to clipboard" />
                    </CopyToClipboard>
                  </div>
                }

                {
                  listing.condition_grade !== null && listing.condition_grade !== undefined &&
                  <div className='auction-essentials-row'>
                    <div className='essentials-indicator'>{listing.condition_grade}</div>
                    <div className='essentials-label'>CR Score</div>
                  </div>
                }

                {
                  listing.as_is === true &&
                  <div className='auction-essentials-row'>
                    <div className='essentials-indicator essentials-indicator-warn'>!</div>
                    <div className='essentials-label'>As is</div>
                  </div>
                }

                {
                  listing.salvage === true &&
                  <div className='auction-essentials-row'>
                    <div className='essentials-indicator essentials-indicator-warn'>!</div>
                    <div className='essentials-label'>Salvage</div>
                  </div>
                }

                {
                  listing.drivable === false &&
                  <div className='auction-essentials-row'>
                    <div className='essentials-indicator essentials-indicator-warn'>!</div>
                    <div className='essentials-label'>Not drivable</div>
                  </div>
                }

                {
                  (listing.frame_damage !== null && listing.frame_damage !== undefined && listing.frame_damage === true) &&
                  <div className='auction-essentials-row'>
                    <div className='essentials-indicator essentials-indicator-warn'>!</div>
                    <div className='essentials-label'>Frame damage</div>
                  </div>
                }
                {
                  carfaxSnapshotStatus !== null &&
                  <div className='auction-essentials-row'>
                    <div className={`essentials-indicator ${Boolean(carfaxSnapshotStatus.type === 'error' || carfaxSnapshotStatus.type === 'warn') ? 'essentials-indicator-warn' : ''} ${carfaxSnapshotStatus.type === 'good' ? 'essentials-indicator-good' : ''}`}>
                      {Boolean(carfaxSnapshotStatus.type === 'error' || carfaxSnapshotStatus.type === 'warn') ? '!' : <CheckIcon color='#fff' width={22} height={22} />}
                    </div>
                    <div className='essentials-label'>{carfaxSnapshotStatus.message}</div>
                  </div>
                }

                {
                  Boolean(listing.vehicle_history?.auto_check) &&
                  <div style={{marginTop: '10px'}}>
                    {listing.vehicle_history.auto_check}
                  </div>
                }


                {
                  Boolean(exteriorColor || interiorColor) &&
                  <div style={{marginTop: '20px 0'}}>
                    {
                      Boolean(exteriorColor) &&
                      <div className='split-row split-row-left' style={{alignItems: 'center', marginRight: '20px'}}>
                        <div className='color-indicator' style={{backgroundColor: exteriorColor.colorClass}} />
                        {listing.exterior_color} Exterior
                      </div>
                    }

                    {
                      Boolean(interiorColor) &&
                      <div className='split-row split-row-left' style={{alignItems: 'center', marginRight: '20px'}}>
                        <div className='color-indicator' style={{backgroundColor: interiorColor.colorClass}} />
                        {listing.interior_color} interior
                      </div>
                    }
                  </div>
                }

                {
                  Boolean(SessionStore.hasAuctionsPlus() && (listing.wholesale_value || listing.retail_value)) &&
                  <div style={{marginTop: '20px'}}>
                    <div style={{fontWeight: 'bold', borderBottom: '1px solid #ddd'}}>{AppConstants.providerNames.auctions_plus} Estimates</div>
                    {
                      Boolean(listing.wholesale_value) &&
                      <div>{Formatters.formatCurrency(listing.wholesale_value)} est. wholesale</div>
                    }
                    {
                      Boolean(listing.retail_value) &&
                      <div>{Formatters.formatCurrency(listing.retail_value)} est. retail</div>
                    }
                  </div>
                }
                
                {
                  Boolean(this.refs.upsellRef && (!SessionStore.hasAccountOfferings() || SessionStore.isCanceled())) &&
                  <div style={{marginTop: '20px', marginBottom: '10px'}}>
                    <a href="#values"
                      style={{backgroundColor: '#e95656', color: '#fff', borderRadius: '4px', padding: '2px 6px', marginBottom: '-7px', cursor: 'pointer'}}
                      onClick={(e) => {
                        e.preventDefault()
                        this.refs.upsellRef.scrollIntoView()
                    }}>What's it worth?</a>
                  </div>
                }
              </div>





              <div className='card auction-body-vehicle-details'>
                {
                  listing.comments?.length > 0 &&
                  <section>
                    <div className='section-title'>Announcements</div>
                    <div>
                      {
                        listing.comments.map((comment, index) => (
                          <div key={`comment-${index}`}>
                            <div>{comment}</div>
                          </div>
                        ))
                      }
                    </div>
                  </section>
                }

                {
                  listing.declarations?.length > 0 &&
                  <div style={{marginTop: '10px', marginBottom: '20px'}}>
                    <div style={{fontWeight: 'bold'}}>Declarations</div>
                    { listing.declarations.map((l, index) => (
                      <div key={`ld-${index}`}>{l}</div>
                    )) }
                  </div>
                }


                {
                  Boolean(isEDGE && listing.condition_grade && listing.auction.type == 'physical') &&
                  <div style={{flexGrow: 0, flexShrink: 0, width: '50%'}}>
                    <div style={{fontWeight: 'bold'}}>Condition Report</div>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                      {
                        listing.drivable !== null && listing.drivable !== undefined &&
                        <div style={{marginRight: '20px'}}>Drivable: {listing.drivable === true ? 'Yes' : 'No'}</div>
                      }
                      {
                        listing.condition_grade &&
                        <div style={{marginRight: '20px'}}>Grade: {listing.condition_grade}</div>
                      }
                      {
                        listing.auto_grade &&
                        <div style={{marginRight: '20px'}}>AutoGrade: {listing.auto_grade}</div>
                      }
                      {
                        listing.original_paint &&
                        <div style={{marginRight: '20px'}}>Original Paint: {listing.original_paint}</div>
                      }
                      {
                        listing.frame_damage === true &&
                        <div style={{marginRight: '20px'}}>Frame Damage</div>
                      }
                      {
                        ((listing.condition_report_items && listing.condition_report_items.length > 0) || (listing.tires && listing.tires.length > 0)) && !isShowingEdgeCR &&
                        <a href="#full-report" onClick={(e) => {e.preventDefault(); this.setState({ isShowingEdgeCR: true })}}>Show full report</a>
                      }
                    </div>
                    {
                      isShowingEdgeCR &&
                      <div>

                        {
                          listing.condition_report_items && listing.condition_report_items.length > 0 &&
                          <ul style={{marginLeft: 0, paddingLeft: '20px', marginTop: '10px'}}>
                          {
                            listing.condition_report_items.map((item, index) => {
                              return (
                                <li key={`cr-item-${index}`}>{item.description}</li>
                              )
                            })
                          }
                          </ul>
                        }
                      </div>
                    }
                  </div>
                }


                {
                  isSMARTAUCTION && listing?.condition_report_items &&
                  <div style={{marginTop: '20px', border: '2px solid #ddd', borderRadius: '10px', backgroundColor: '#fff', padding: '10px'}}>

                    <a href="#toggle" onClick={(e) => {
                        e.preventDefault()
                        this.setState({ isShowingSmartAuctionConditionReport: !isShowingSmartAuctionConditionReport })
                        SessionStore.setShowAuctionConditionReport(!isShowingSmartAuctionConditionReport)
                      }} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} className='auction-listing-smart-auction-cr-toggle'>
                      <h2 style={{fontWeight: 'bold', marginBottom: '5px'}}>Condition Report</h2>
                      {
                        isShowingSmartAuctionConditionReport ? <ContractIcon color='#aaa' /> : <ExpandIcon color='#aaa' />
                      }
                    </a>

                    {
                      isShowingSmartAuctionConditionReport &&
                      <div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                          {
                            Boolean(listing.drivable === true || listing.drivable === false) &&
                            <div style={{marginRight: '20px'}}>Drivable: {listing.drivable === true ? "Yes" : "No"}</div>
                          }
                          {
                            Boolean(listing.condition_report_items?.commercialVehicle === true || listing.condition_report_items?.commercialVehicle === false) &&
                            <div style={{marginRight: '20px'}}>Commercial Vehicle: {listing.condition_report_items.commercialVehicle === true ? "Yes" : "No"}</div>
                          }
                          {
                            Boolean(listing.condition_report_items?.inspection?.smoker === false || listing.condition_report_items?.inspection?.smoker === true) &&
                            <div>Smoker: {listing.condition_report_items.inspection.smoker === true ? 'Yes' : 'No'}</div>
                          }
                        </div>

                        {
                          listing.condition_report_items?.disclosures && listing.condition_report_items.disclosures.length > 0 &&
                          <div style={{marginTop: '20px'}}>
                            <div style={{fontWeight: 'bold'}}>Disclosures</div>
                            <ul>
                              {
                                listing.condition_report_items.disclosures.map((disclosure, index) => {
                                  return (
                                    <li>{disclosure}</li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        }

                        {
                          listing.condition_report_items?.disclosureComment &&
                          <div style={{marginTop: '20px'}}>Disclosure comment: {listing.condition_report_items.disclosureComment}</div>
                        }

                        {
                          listing.condition_report_items.inspection.damages && listing.condition_report_items.inspection.damages.length > 0 &&
                          <div style={{marginTop: '10px'}}>
                            <div style={{fontWeight: 'bold'}}>Damages</div>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px', borderRadius: '6px'}}>
                              {
                                listing.condition_report_items.inspection.damages.map((damage, index) => {
                                  return (
                                    <div key={`damage-${index}`} style={{marginBottom: '10px', width: '220px', border: '1px solid #ddd', padding: '10px', borderRadius: '6px'}}>
                                      {
                                        damage.images && damage.images.length > 0 &&
                                        <a href={damage.images[0].url} target="_blank" rel="noopener noreferrer"><img src={damage.images[0].thumbnail} alt="" style={{width: '100%'}} /></a>
                                      }
                                      {
                                        damage.images && damage.images.length > 1 &&
                                        <div>
                                          <div className="secondary-text">More:&nbsp;
                                          {
                                            damage.images.map((addImage, index) => {
                                              if (index > 0) {
                                                return (<a href={addImage.url} target="_blank" rel="noopener noreferrer" key={`add-photo-${index}`} style={{paddingRight: '4px'}}>{index+1}</a>)
                                              }
                                            })
                                          }
                                          </div>
                                        </div>
                                      }
                                      {
                                        Boolean(!damage.images || damage.images.length === 0) &&
                                        <div style={{with: '100%', height: '130px', backgroundColor: '#eee', display: 'flex', justifyContent: 'cener', alignItems: 'center', textAlign: 'center', alignContent: 'center'}}>
                                          <div style={{textAlign: 'center', width: '100%', color: '#ccc'}}>No photo</div>
                                        </div>
                                      }
                                      <div>
                                        <div style={{overflowWrap: 'break-word'}}>{damage.description}</div>
                                        <div>{damage.location}</div>
                                        {
                                          Boolean(damage.chargeable === true || damage.chargeable === false) &&
                                          <div>Chargeable: {damage.chargeable === true ? 'Yes' : 'No'}</div>
                                        }

                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        }

                        {
                          listing.condition_report_items?.inspection &&
                          <div style={{marginTop: '20px'}}>
                            <div style={{fontWeight: 'bold'}}>Inspection</div>
                            <div style={{}}>

                              <div>Company: {listing.condition_report_items.inspection.company}</div>
                              <div>Data provider: {listing.condition_report_items.inspection.dataProvider}</div>
                              <div>Inspection date: {moment(listing.condition_report_items.inspection.inspectionDate).format('MMM Do YYYY')}</div>
                              <div>Inspection comment: {listing.condition_report_items.inspection.inspectionComment}</div>
                              {
                                listing.condition_report_items.inspection.normalWearPrice > 0 &&
                                <div>Normal wear price: {Formatters.formatCurrency(listing.condition_report_items.inspection.normalWearPrice)}</div>
                              }
                              {
                                listing.condition_report_items.inspection.excessWearPrice > 0 &&
                                <div>Excess wear price: {Formatters.formatCurrency(listing.condition_report_items.inspection.excessWearPrice)}</div>
                              }
                              {
                                listing.condition_report_items.inspection.potentialRepairCost > 0 &&
                                <div>Potential repair cost: {Formatters.formatCurrency(listing.condition_report_items.inspection.potentialRepairCost)}</div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }

                {
                  isAdesa && listing?.condition_report_items &&
                  <div style={{marginTop: '10px', marginBottom: '20px', border: '2px solid #ddd', borderRadius: '10px', backgroundColor: '#fff', padding: '10px'}}>
                    <div style={{fontWeight: 'bold'}}>Condition Report</div>
                    {
                      listing.condition_report_items.inspection?.company && listing.condition_report_items.inspection?.date &&
                      <div className='secondary-text' style={{marginBottom: '10px'}}>
                        Inspection {moment(listing.condition_report_items.inspection.date).format('MMM Do YYYY')} by {listing.condition_report_items.inspection.company}
                      </div>
                    }

                    {
                      Boolean(listing.condition_report_items.structural_damage) &&
                      <div>Structural Damage: {listing.condition_report_items.structural_damage}</div>
                    }

                    {
                      Boolean(listing.condition_report_items.keys !== null || listing.condition_report_items.manuals !== null || listing.condition_report_items.odors  !== null || listing.condition_report_items.remotes  !== null) &&
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        {
                          listing.condition_report_items.keys !== null &&
                          <div>Keys: {listing.condition_report_items.keys}</div>
                        }
                        {
                          listing.condition_report_items.manuals !== null &&
                          <div>Manuals: {listing.condition_report_items.manuals}</div>
                        }
                        {
                          listing.condition_report_items.odors !== null &&
                          <div>Odors: {listing.condition_report_items.odors}</div>
                        }
                        {
                          listing.condition_report_items.remotes !== null &&
                          <div>Remotes: {listing.condition_report_items.remotes}</div>
                        }
                      </div>
                    }

                    {
                      this.state.isShowingAdesaDamages &&
                      <div style={{marginTop: '20px'}}>
                        {
                          listing.condition_report_items.damages.map((damage, index) => {
                            return (
                              <div key={`adesa-damage-${index}`} style={{display: 'flex', flexDirection: 'row', borderBottom: '1px solid #ddd', marginBottom: '5px', paddingBottom: '5px'}}>
                                {
                                  damage.image &&
                                  <a href={`${damage.image}`} target="_blank" rel="noopener noreferrer" style={{marginRight: '10px', width: '120px'}}>
                                    <img src={`${damage.image}`} alt='Damage photo' style={{maxWidth: '120px'}} />
                                  </a>
                                }
                                <div>
                                  <div>{ damage.area }</div>
                                  <div>{ damage.description }</div>
                                  <div>{ damage.type }</div>
                                  {
                                    damage.estimated_repair_cost > 0 &&
                                    <div>Estimated repair cost: {Formatters.formatCurrency(damage.estimated_repair_cost)}</div>
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }

                    {
                      listing.condition_report_items.damages?.length > 0 &&
                      <a href="#show-damages" onClick={(e) => {
                        e.preventDefault()
                        this.setState({ isShowingAdesaDamages: !this.state.isShowingAdesaDamages })
                      }}>
                        {this.state.isShowingAdesaDamages ? 'Hide' : 'Show'} damages
                      </a>
                    }
                  </div>

                }

                {
                  listing?.tires?.length > 0 &&
                  <div style={{marginBottom: '10px'}}>
                    <div style={{fontWeight: 'bold'}}>Tires</div>
                    {
                      this.state.isShowingTires &&
                      this.renderTires(listing.tires)
                    }

                    <a href="#tires" onClick={(e) => {
                      e.preventDefault()
                      this.setState({ isShowingTires: !this.state.isShowingTires })
                    }}>
                      {this.state.isShowingTires ? 'Hide' : 'Show'} tires
                    </a>
                  </div>
                }

                <div>
                  <table>
                    <tbody>
                      {
                        listing.certified !== null && listing.certified !== undefined &&
                        <tr>
                          <td>Certified</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.certified === true ? 'Yes' : 'No'}</td>
                        </tr>
                      }

                      {
                        listing.assured !== null && listing.assured !== undefined &&
                        <tr>
                          <td>Assured</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.assured === true ? 'Yes' : 'No'}</td>
                        </tr>
                      }

                      {
                        listing.frame_damage !== null && listing.frame_damage !== undefined &&
                        <tr>
                          <td>Frame Damage</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.frame_damage === true ? 'Yes' : 'No'}</td>
                        </tr>
                      }

                      {
                        listing.certification_type &&
                        <tr>
                          <td>Certification Type</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.certification_type}</td>
                        </tr>
                      }

                      {
                        listing.sale_light_color &&
                        <tr>
                          <td>Sale Light</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.sale_light_color}</td>
                        </tr>
                      }

                      {
                        listing.odometer_status &&
                        <tr>
                          <td>Odometer Status</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.odometer_status}</td>
                        </tr>
                      }

                      {
                        Boolean(listing.vehicle_type) &&
                        <tr>
                          <td>Vehicle Type</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.vehicle_type}</td>
                        </tr>
                      }

                      {
                        Boolean(listing.title_status) &&
                        <tr>
                          <td>Title Status</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.title_status}</td>
                        </tr>
                      }

                      {
                        Boolean(listing.title_state) &&
                        <tr>
                          <td>Title State</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.title_state}</td>
                        </tr>
                      }

                      {
                        Boolean(listing.seller_type) &&
                        <tr>
                          <td>Seller Type</td>
                          <td style={{fontWeight: 'bold', paddingLeft: '5px'}}>{listing.seller_type}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                  {
                    listing.odometer_comment &&
                    <div>Odometer Comment: {listing.odometer_comment}</div>
                  }

                  {
                    listing.vehicle_options && listing.vehicle_options.length > 0 &&
                    <div style={{marginTop: '20px'}}>
                      {
                        listing.vehicle_options.map((cat, index) => {
                          if (index === 0 || (index > 0 && isShowingFullVehicleOptions)) {
                            if (cat.options?.length > 0) {
                              let listedOptions = cat.options.join(', ')
                              return (
                                <div key={`options-${index}`} style={{marginTop: '10px'}}>
                                  <div style={{fontWeight: 'bold'}}>{cat.name} Options</div>
                                  <div>{listedOptions}</div>
                                </div>
                              )
                            }
                          }
                        })
                      }
                      {
                        listing.vehicle_options.length > 1 &&
                        <a href="#show-all" onClick={(e) => {
                          e.preventDefault()
                          this.setState({ isShowingFullVehicleOptions: !isShowingFullVehicleOptions })
                        }}>{isShowingFullVehicleOptions ? 'Hide' : 'Show'} all options</a>
                      }
                    </div>
                  }

                  {
                    listing.condition_report_url &&
                    <a href={listing.condition_report_url} target="_blank" rel="noopener noreferrer">View Condition Report <ExternalLinkIcon /></a>
                  }

                  {
                    listing.operator_messages && listing.operator_messages.length > 0 &&
                    <div style={{marginTop: '10px'}}>
                      {
                        listing.operator_messages.map((message, index) => {

                          return (
                            <div key={`omes-${index}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px'}}>
                              {
                                message.icon &&
                                <img src={`data:image/png;base64, ${message.icon}`} alt="Icon" style={{marginRight: '8px'}} />
                              }
                              {message.name}</div>
                          )
                        })
                      }
                    </div>
                  }
                </div>
              </div>








              <div className="card auction-location-details">

                <div className="auction-location-details-header">
                  <div className='split-row split-row-left' style={{alignItems: 'flex-start'}}>
                    {
                      !isOnlineAuction &&
                      <AuctionLocationIcon style={{opacity: 0.6}} />
                    }
                    <div style={{fontSize: '1.2rem', fontWeight: 'bold', marginLeft: '2px'}}>
                      {listing.auction.name}
                    </div>

                  </div>
                </div>


                <div style={{marginTop: 0, position: 'relative', overflow: 'hidden'}}>
                  <div style={{padding: '10px'}}>
                    {
                      isOnlineAuction &&
                      <div style={{}}>
                        {
                          listing.auction_in_past === true &&
                          <div style={{color: 'red', marginBottom: '10px'}}>Auction has ended</div>
                        }
                        {
                          listing.no_longer_available === true &&
                          <div style={{color: 'red', marginBottom: '10px'}}>Auction listing has been removed</div>
                        }
                        {
                          listing.pickup_location &&
                          <div>Pickup location: <span style={{fontWeight: 'bold'}}>{listing.pickup_location}</span></div>
                        }
                        {
                          // FIXME: is both location and informaion needed?
                          listing.city &&
                          <div style={{margin: '10px 0'}}>
                            <div className='secondary-text'>Pickup location</div>
                            <div>{listing.storage_name}</div>
                            <div>{listing.address}</div>
                            <div>{listing.city}, {listing.state}, {listing.zip_code}</div>
                          </div>
                        }
                        {
                          listing.originator_type &&
                          <div style={{marginTop: '10px'}}>Type: <span style={{fontWeight: 'bold'}}>{listing.originator_type}</span></div>
                        }
                        {
                          listing.buy_now_price &&
                          this.renderBuyNow(listing)
                        }
                        {
                          listing.end_time &&
                          <div style={{marginTop: '10px'}}>
                            <CalendarIcon color='#aaa' width={22} height={22} style={{marginRight: '5px'}} />
                            {listing.auction_in_past === true ? 'Ended' : 'Ends'}: <span style={{fontWeight: 'bold'}}>{Formatters.formatVerboseDate(listing.end_time)}</span>
                          </div>
                        }
                        {
                          listing?.sale_url &&
                          <div style={{marginTop: '10px', textAlign: 'right'}}>
                            <a href={listing.sale_url} target="_blank" rel="noopener noreferrer">Visit listing page <ExternalLinkIcon /></a>
                          </div>
                        }

                      </div>
                    }
                    {
                      !isOnlineAuction &&
                      <div>

                        {
                          listing.sale_amount && listing.sold_date &&
                          <div style={{marginBottom: '30px'}}>
                            <div style={{fontWeight: 'bold', fontSize: '24px', backgroundColor: '#111', color: '#fff', padding: '4px 8px', borderRadius: '8px', textAlign: 'center'}}>Sold for {Formatters.formatCurrencyCents(listing.sale_amount)}</div>
                            {
                              Boolean(listing.sold_date) &&
                              <div style={{textAlign: 'center'}}>{moment(listing.sold_date).format('M/D/YYYY')}</div>

                            }
                          </div>
                        }
                        {
                          listing.auction_in_past === true && !listing.sale_amount && !listing.sold_date &&
                          <div style={{color: 'red', marginBottom: '10px'}}>Auction has ended</div>
                        }
                        {
                          listing.no_longer_available === true &&
                          <div style={{color: 'red', marginBottom: '10px'}}>Auction listing has been removed</div>
                        }

                        {
                          listing.seller_name &&
                          <div style={{marginBottom: '10px'}}>{listing.seller_name}</div>
                        }

                        <div className='split-row' style={{alignItems: 'center', marginBottom: '10px'}}>
                          {
                            Boolean(listing.start_time) &&
                            <div className='split-row' style={{alignItems: 'center'}}>
                              <CalendarIcon color='#aaa' width={22} height={22} style={{marginRight: '5px'}} /> {moment(listing.start_time).format('ddd MM/DD h:mm a')}
                            </div>
                          }

                          <div style={{margin: '0 10px'}}><strong>Lane</strong> {listing.auction_lane_number}</div>
                          <div><strong>Run</strong> {listing.auction_run_number}</div>
                        </div>

                        {
                          Boolean(listing.buy_now_price) &&
                          this.renderBuyNow(listing)
                        }

                        {
                          listing?.sale_url &&
                          <div style={{textAlign: 'right'}}>
                            <a href={listing.sale_url} target="_blank" rel="noopener noreferrer">Visit listing page <ExternalLinkIcon /></a>
                          </div>
                        }
                      </div>
                    }

                    {
                      listing.additional_auctions?.active?.length > 0 && AuctionFunctions.shouldShowSimilarVehiclesForProvider(listing.auction?.provider) &&
                      <div>
                        <div>Also for sale on:</div>
                        {
                          listing.additional_auctions.active.map((l) => {
                            return (
                              <div key={`ac-${l.id}`} style={{marginBottom: '10px'}}>
                                {
                                  l.sale_url &&
                                  <div>
                                    <a href={l.sale_url} target="_blank" rel="noopener noreferrer">{l.auction_name} <ExternalLinkIcon /></a>
                                  </div>
                                }
                                {
                                  l.id &&
                                  <div>
                                    <a href={`/auctions/listing/${l.id}`} target="_blank" rel="noopener noreferrer">View on Carbly</a>
                                  </div>
                                }
                                {
                                  !l.sale_url &&
                                  <div>{l.auction_name}</div>
                                }
                                {
                                  l.start_time &&
                                  <div>Start time: {moment(l.start_time).format('ddd MM/DD h:mm a')}</div>
                                }
                                {
                                  l.buy_now_price &&
                                  this.renderBuyNow(l)
                                }

                              </div>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                </div>

              </div>
            </div>



            {
              listing.autocheck_report &&
              <div className="card" style={{marginTop: '-30px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', overflow: 'hidden', padding: '10px'}}>
                <img src='/images/autocheck-logo.png' alt={AppConstants.providerNames['autocheck']} style={{width: '128px', height: '36px', marginBottom: '5px'}} />
                {
                  (listing.autocheck_report.numberOfAccidents !== null && listing.autocheck_report.numberOfAccidents !== undefined) &&
                  <div>Number of accidents: {listing.autocheck_report.numberOfAccidents}</div>
                }
                {
                  (listing.autocheck_report.ownerCount !== null && listing.autocheck_report.ownerCount !== undefined) &&
                  <div>Owners: {listing.autocheck_report.ownerCount}</div>
                }
                {
                  listing.autocheck_report.score &&
                  <div>Score: {listing.autocheck_report.score}</div>
                }
                {
                  listing.autocheck_report.compareScoreRangeLow && listing.autocheck_report.compareScoreRangeHigh &&
                  <div>Comparison range: {listing.autocheck_report.compareScoreRangeLow}-{listing.autocheck_report.compareScoreRangeHigh}</div>
                }
              </div>
            }



            {
              valuation && showValuation &&
              <div style={{marginTop: '40px'}}>
                <Valuation
                  valuationData={valuation}
                  persistChoices={true}
                  onUpdateMileageAndCondition={this.props.updateValuationWithReload}
                  clientID={this.clientID}
                  hideAuctionMatch={true}
                  parentAuctionListing={listing}
                  hideShare
                  isModal={this.props.isModal}
                  isEmbedded={true}
                  onSetTargetPrice={() => {
                    if (!listing.watchlist) {
                      this.toggleWatchlist()
                    }
                  }}
                  auctionListingCRScore={listing?.condition_grade}
                  auctionListingLongitude={listing?.longitude}
                  auctionListingLatitude={listing?.latitude}
                  auctionListingProvider={listing?.auction?.provider}
                  />

                <ActivitiesInfiniteScroll
                  clientID={this.clientID}
                  scope={{ vehicle_uuid: valuation.uuid }}
                  getActivityIsDisabled={(activity) => {return activity.vehicle_is_deleted}} />

                {
                  SessionStore.hasAccountOfferings() &&
                  <BooksUpsellList />
                }
              </div>
            }

            {
              !valuation && showValuation && this.state.shouldShowAccountNeedsAction &&
              <div style={{marginTop: '60px', border: '1px solid #ddd', borderRadius: '10px', backgroundColor: '#fff', padding: '10px'}} ref='upsellRef'>
                <AccountNeedsAction showCompact={false} />
              </div>
            }

            {
              isLoadingValuation &&
              <div style={{marginTop: '80px'}}>
                <Spinner />
              </div>
            }

            {
              isShowingLightbox &&
              <Lightbox
                reactModalStyle={{overlay: {zIndex: 10000}}}
                mainSrc={listing.images[currentLightboxIndex]}
                nextSrc={listing.images[(currentLightboxIndex + 1) % listing.images.length]}
                prevSrc={listing.images[(currentLightboxIndex + listing.images.length - 1) % listing.images.length]}
                onCloseRequest={() => this.setState({ isShowingLightbox: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    currentLightboxIndex: (currentLightboxIndex + listing.images.length - 1) % listing.images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    currentLightboxIndex: (currentLightboxIndex + 1) % listing.images.length,
                  })
                }
              />
            }
          </div>
        }
      </div>

    )
  }
}
