import React from 'react'
import { Link } from 'react-router-dom'
import Reorder from 'react-reorder'
import pluralize from 'pluralize'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import SessionStore from '../../api/stores/SessionStore'
import SessionActionCreators from '../../api/actions/SessionActionCreators'
import UserActionCreators from '../../api/actions/UserActionCreators'
import ProvidersLegal from '../common/ProvidersLegal'
import AddUserModal from '../user/AddUserModal'
import SettingsLatestNews from './SettingsLatestNews'
import AutoCheckPermissionsModal from './AutoCheckPermissionsModal'
import Avatar from '../user/Avatar'
import Spinner from '../common/Spinner'
import CarfaxConnectAuth from '../common/CarfaxConnectAuth'
import AccountBug from '../user/AccountBug'
import ClipboardIcon from '../common/icons/ClipboardIcon'
import ZipCodeEntryField from '../common/ZipCodeEntryField'
import CommonFunctions from '../../api/constants/CommonFunctions'
import AppConstants from '../../api/constants/AppConstants'
import RemoteConstants from '../../api/constants/RemoteConstants'
import packageJson from '../../../package.json'
import platformFunctions from '../../platformFunctions'
import { useApolloClient } from '@apollo/client'
import AuctionEdgeAuth from '../common/AuctionEdgeAuth'
import DotAuctionLogo from '../common/icons/DotAuctionLogo'
import CalculatorBug from '../common/CalculatorBug'
import Formatters from '../../api/constants/Formatters'
import GlobalCostsEntryModal from '../auctions/GlobalCostsEntryModal'
import MobileAppInstall from '../common/MobileAppInstall'

const carfaxTitle = AppConstants.providerNames['carfax']

class UserSettings extends React.Component {
  state = {
    user: SessionStore.user,
    valuationsTrialEndingDays: SessionStore.valuationsTrialEndingDays(),
    valuationsEnabled: SessionStore.valuationsEnabled(),
    userZip: '',
    userZipValid: false,
    isLoading: false,
    autocheckID: '',
    modifiedProvidersOrder: SessionStore.user && SessionStore.user.active_cards ? [...SessionStore.user.active_cards] : null,
    hasProviderOrderChanged: false,
    isShowingEditProfileModal: false,
    isShowingAddUserModal: false,
    selectedDefaultCondition: SessionStore.getDefaultVehicleCondition(),
    news: null,
    isShowingAutoCheckPermissionsModal: false,
    edgeAuthorizeURL: null,
    isShowingGlobalCostsEntryModal: false
  }

  componentDidMount() {
    SessionStore.on("user_data_changed", this.userDataChanged)
    SessionStore.on("user_autocheck_data_changed", this.userDataChanged)
    SessionStore.on("news_received", this.newsReceived)
    SessionStore.on('edge_authorize_url_changed', this.edgeAuthorizeURLChanged)
    window.scrollTo(0, 0)

    UserActionCreators.loadEdgeAuthorizeURL(window.location.href)

    if (SessionStore.news) {
      this.setState({
        news: SessionStore.news
      })
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    SessionStore.removeListener("user_autocheck_data_changed", this.userDataChanged)
    SessionStore.removeListener("news_received", this.newsReceived)
    SessionStore.removeListener('edge_authorize_url_changed', this.edgeAuthorizeURLChanged)
  }

  userDataChanged = () => {
    this.setState({
      user: SessionStore.user,
      modifiedProvidersOrder: [...SessionStore.user.active_cards],
      valuationsTrialEndingDays: SessionStore.valuationsTrialEndingDays(),
      valuationsEnabled: SessionStore.valuationsEnabled(),
      isLoading: false,
      isShowingGlobalCostsEntryModal: false
    })
  }

  edgeAuthorizeURLChanged = () => {
    this.setState({
      edgeAuthorizeURL: SessionStore.edgeAuthorizeURL
    })
  }

  linkAutoCheck = (e) => {
    const { autocheckID } = this.state
    this.setState({ isLoading: true })

    UserActionCreators.updateAutoCheckCredentials(autocheckID)
  }

  unlinkAutoCheck = (e) => {
    e.preventDefault()
    this.setState({ isLoading: true })

    UserActionCreators.removeAutoCheckCredentials()
  }

  logoutCarfaxConnect = (e) => {
    this.setState({ isLoading: true })
    UserActionCreators.logoutCarfaxConnect()
  }

  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    var newProviders = CommonFunctions.arrayMove(Object.assign(this.state.modifiedProvidersOrder), previousIndex, nextIndex)
    this.setState({
      modifiedProvidersOrder: newProviders,
      hasProviderOrderChanged: true
    })
  }

  saveProviderOrder = () => {
    UserActionCreators.updateUser({ordered_cards: this.state.modifiedProvidersOrder})
    platformFunctions.logAnalyticsEvent("User", "Reordered Providers")
    setTimeout(() => {
      this.setState({ hasProviderOrderChanged: false })
    }, 50)
  }

  zipCodeChanged = (zipCode, isValid) => {
    this.setState({
      userZip: zipCode,
      userZipValid: isValid
    })
  }

  submitZipCode = () => {
    var userZip = this.state.userZip
    this.setState({
      isLoading: true,
      userZip: ''
    })

    UserActionCreators.updateUser({zip_code: userZip})
  }

  newsReceived = () => {
    if (SessionStore.news) {
      this.setState({
        news: SessionStore.news
      })
    }
  }

  renderCarfaxSettings = () => {
    if (SessionStore.hasAuthorizedCarfaxConnect()) {
      return (
        <div>
          {carfaxTitle} account is linked. <a href={RemoteConstants.CARFAX_CONNECT_LOGOUT_URL} target="__blank" onClick={this.logoutCarfaxConnect}>Unlink</a>
        </div>
      )
    } else {
      return (
        <div style={{marginTop: '10px'}}>
          <CarfaxConnectAuth/>
        </div>
      )
    }
  }

  render() {
    if (!this.props.navMatch) { return null }
    const { accountStatusRestricted } = this.props
    const { user, valuationsEnabled, valuationsTrialEndingDays, isLoading, autocheckID, userZip, userZipValid, modifiedProvidersOrder, hasProviderOrderChanged, isShowingAddUserModal, selectedDefaultCondition, news, isShowingAutoCheckPermissionsModal, edgeAuthorizeURL, isShowingGlobalCostsEntryModal } = this.state

    const isAccountOwner = SessionStore.isAccountOwner()
    const isAutoCheckVisible = SessionStore.isAutoCheckVisible()
    const hasLinkedAutoCheck = SessionStore.hasLinkedAutoCheck()
    const autoCheckTitle = AppConstants.providerNames['autocheck']
    const canOverrideZip = SessionStore.canOverrideZip()
    var teamUsers = user?.rooftop?.team

    if (teamUsers && teamUsers.length > 0) {
      teamUsers = teamUsers.sort((a, b) => (a.created > b.created) ? 1 : -1)
    }

    if (isLoading) {
      return <Spinner className="spinner-page"/>
    }

    const autocheckSubmitDisabled = autocheckID.length === 0
    const userZipSubmitDisabled = userZip.length !== 5 || (userZip.length === 5 && !userZipValid)
    const noAccessBookNames = CommonFunctions.formattedProviderNamesList(user.trial_cards.concat(user.inactive_cards))
    const customerReferralLink = user.customer_referral_link

    const { vehicle_auction_fees_cost_cents, vehicle_misc_fees_cost_cents, vehicle_transportation_cost_cents, vehicle_condition_zero_cost_cents, vehicle_condition_one_cost_cents, vehicle_condition_two_cost_cents, vehicle_condition_three_cost_cents, vehicle_condition_four_cost_cents, vehicle_condition_five_cost_cents, vehicle_condition_none_cost_cents } = user?.rooftop

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #aaa',
        padding: '10px'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '100%',
        marginBottom: '5px'
      })
    }

    return (
      <div className="body-content settings-content-c">
        <div className="content-header">
          <h1>Settings</h1>

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>
        {
          user.offering_list_message !== null &&
          <section style={{paddingLeft: 0, marginBottom: 0}}>
            {
              valuationsEnabled === true && valuationsTrialEndingDays != null && valuationsTrialEndingDays > 0 &&
              <div className="secondary-text">Your free trial ends in { pluralize('day', valuationsTrialEndingDays, true) }</div>
            }
            {
              user.offering_list_message?.length > 0 &&
              <div>Plan: {user.offering_list_message}</div>
            }
            {
              isAccountOwner && !SessionStore.isCanceled() &&
              <Link to='/settings/account'>Change Account and Payment Settings</Link>
            }
          </section>
        }

        {
          <section style={{padding: 0, marginTop: 0}} className="settings-news">
            <SettingsLatestNews news={news} />
          </section>
        }

        {
          Boolean(customerReferralLink) &&
          <section className="card">
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{marginRight: '30px', flexGrow: 1, flexShink: 1}}>
                <h2>Refer a Friend and Save</h2>
                <div>Each dealer you refer to use Carbly, <span style={{fontWeight: 'bold'}}>you'll get $120 in credit</span> on your Carbly account.</div>
                <div>The dealer must be in good standing and <span style={{fontWeight: 'bold'}}>pay for 3 full months of service</span>.</div>
              </div>
              <div style={{flexGrow: 0, flexShink: 0, width: '470px'}}>
                Just copy this link and send to someone, and you'll automatically get credit:
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '5px'}}>
                  <input type='text' value={customerReferralLink} readOnly />
                  <CopyToClipboard
                    text={customerReferralLink}
                    onCopy={() => {
                      toast.success("Link Copied to Clipboard")
                    }}
                    >
                    <div className="label" style={{marginLeft: '8px', color: '#2f323e', cursor: 'pointer', fontSize: 30, marginTop: '-28px'}}>
                      <ClipboardIcon />
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </section>

        }

        {
          SessionStore.teamsEnabled() &&
          <section className="card">
            <h2>Team Members</h2>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
              {
                teamUsers &&
                teamUsers.map((u) => {
                  return (
                    <div key={`tu-${u.uuid}`} style={{padding: '5px', border: '1px solid #ddd', borderRadius: '5px', marginBottom: '5px', marginRight: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      {
                        u.full_name &&
                        <div style={{marginRight: '5px'}}>
                          <Avatar
                            user={u}
                            size={AppConstants.avatarSizes.small}
                          />
                        </div>
                      }

                      <div style={{fontWeight: 'bold'}}>{u.full_name ? u.full_name : u.email}</div>
                      <div className="secondary-text" style={{marginLeft: '10px'}}>{u.auxiliary === true ? 'Non-Appraisal' : 'Full Access'}</div>
                    </div>

                  )
                })
              }
            </div>
            {
              isAccountOwner &&
              <div>
                <div style={{marginLeft: '-5px'}}>
                  {
                    !SessionStore.needsPaymentMethod() && SessionStore.hasAccountOfferings() && !accountStatusRestricted ?
                    <Button color="primary" onClick={() => this.setState({ isShowingAddUserModal: true })} style={{marginTop: '5px', minWidth: '320px'}}><img src="/images/teams-logo.png" style={{width: '44px', height: '25px', marginRight: '5px'}} alt="Teams" /> Add a user to your team</Button> :
                    <div>You must have an active Carbly subscription in order to add team members.  <Link to='/settings/account'>Get started with a Carbly subscription</Link></div>
                  }
                </div>
                {
                  teamUsers && teamUsers.length < 2 &&
                  <div style={{marginTop: '10px'}}>
                    <a href="https://getcarbly.com/teams/" target="_blank" rel="noopener noreferrer" style={{color: '#111', fontSize: '0.9rem'}}>Learn more about Carbly for Teams</a>
                  </div>
                }
              </div>
            }

          </section>
        }


        <section style={{alignItems: 'flex-start'}} className="card">
          <h2>Vehicle History Reports</h2>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px', width: '100%'}}>
            <div style={{width: isAutoCheckVisible === true ? '50%' : '100%'}}>
              <h3>{carfaxTitle}</h3>
              { this.renderCarfaxSettings() }
            </div>

            {
              isAutoCheckVisible === true &&
              <div style={{width: '50%'}}>
                <h3>{autoCheckTitle}</h3>
                {
                  isAutoCheckVisible && hasLinkedAutoCheck &&
                  <span>
                  {autoCheckTitle} is linked as {user.autocheck_sid}.  <a href="#unlink" onClick={this.unlinkAutoCheck}>Unlink account</a></span>
                }

                {
                  !hasLinkedAutoCheck &&
                  <span>Link your existing {autoCheckTitle} account
                    <form style={{display: 'flex'}} onSubmit={(e) => {e.preventDefault(); if (!autocheckSubmitDisabled) {this.linkAutoCheck()} }}>
                      <input type="text" className="field" name="autocheck_username" onChange={e => {
                          const sanitizedText = e.target.value.replace(/\s/g, '');
                          this.setState({autocheckID: sanitizedText})
                        }} value={autocheckID} placeholder={`${autoCheckTitle} ID`} maxLength="128" style={{marginBottom: 0, flexGrow: 1, flexShink: 1, marginTop: '2px', marginLeft: '0'}} />
                      <Button color="primary" disabled={autocheckSubmitDisabled} onClick={this.linkAutoCheck}>Submit</Button>
                    </form>
                  </span>
                }
                {
                  SessionStore.isAccountOwner() && teamUsers.length > 1 &&
                  <a href="#edit-persmissions" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isShowingAutoCheckPermissionsModal: true})
                  }}>Control {autoCheckTitle} purchase permissions</a>
                }

                {
                  isShowingAutoCheckPermissionsModal &&
                  <AutoCheckPermissionsModal onDone={() => this.setState({ isShowingAutoCheckPermissionsModal: false })} />
                }
              </div>
            }
          </div>
        </section>


        {
          edgeAuthorizeURL?.edge_authorize_url &&
          <section className="card" style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
            <DotAuctionLogo  width={150} height={42} style={{marginRight: '20px'}} />
            <div>
              <div>By linking your {AppConstants.edgePipelineName} account you can add the ability to bid on digital sales from {AppConstants.auctionOnlineSourceNames.EDGE_DOT_AUCTION}.</div>
              {
                SessionStore.hasLinkedAuctionEdge() ?
                <div>Your {AppConstants.edgePipelineName} account is linked. <a href="#unlink" onClick={(e) => {
                  e.preventDefault()
                  UserActionCreators.logoutAuctionEdge()
                  this.props.dotAuctionClient?.clearStore()
                }}>Unlink it?</a></div> :
                <AuctionEdgeAuth style={{marginTop: 10}} />
              }
            </div>
          </section>
        }

        <section className="card" style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{flexGrow: 1, flexShink: 1}}>
            <h2>Provider Order</h2>
            <div>Drag and drop providers to reorder how they appear on appraisal screens.</div>
          </div>
          <div style={{flexGrow: 0, flexShink: 0, width: '300px'}}>
            {
              Boolean(SessionStore.hasAccountOfferings() && !accountStatusRestricted) ?
              <Reorder
                reorderId="providers-order-list"
                placeholderClassName="placeholder"
                draggedClassName="provider-order-dragged"
                lock="horizontal"
                holdTime={0}
                onReorder={this.onReorder}
                autoScroll={true}
                disabled={false}
                disableContextMenus={true}
                >
                {
                  modifiedProvidersOrder.map((provider) => {
                    return (
                    <div key={`order-${provider}`} className="provider-order-provider">
                      {provider === 'my_lot' ? AppConstants.providerNames['regional_performance'] : AppConstants.providerNames[provider]}

                    </div>
                  )})
                }
              </Reorder> :
              <div>You must have an active Carbly subscription in order to change card orders.</div>
            }

            {
              hasProviderOrderChanged &&
              <Button color="primary" style={{width: '100%', marginTop: '10px'}} onClick={this.saveProviderOrder}>Save Order</Button>
            }

          </div>
        </section>

        <section className="card">
          <h2>Vehicle Defaults</h2>
          {
            !accountStatusRestricted ?
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
              {
                canOverrideZip === true && user.zip_code &&
                <div style={{alignItems: 'flex-start', width: '45%', flexGrow: 1, flexShink: 1, marginRight: '60px'}}>
                  <div>
                    <div>By default, valuation data will be based on regional pricing for ZIP code <span style={{fontWeight: 'bold'}}>{user.zip_code}</span>.
                    {
                      user.rooftop && user.rooftop.zip_code && user.rooftop.zip_code !== user.zip_code &&
                      <span> Your original ZIP code was {user.rooftop.zip_code}.</span>
                    }
                    </div>
                    <div></div>
                    <form style={{display: 'flex'}} onSubmit={(e) => {e.preventDefault(); if (!userZipSubmitDisabled) {this.submitZipCode()} }}>
                      <ZipCodeEntryField ref='zipRef' placeholder='Enter ZIP Code' onZipCodeChange={this.zipCodeChanged} />
                      <Button color="primary" disabled={userZipSubmitDisabled} onClick={this.submitZipCode}>Submit</Button>
                    </form>
                  </div>
                </div>
              }

              <div style={{width: '45%', flexGrow: 1, flexShink: 1}}>
                <div>When creating new vehicles, what should the default vehicle condition be?</div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div style={{flexGrow: 1, flexShink: 1}}>
                  <Select
                    styles={customStyles}
                    name="averageCondition"
                    maxMenuHeight={400}
                    value={selectedDefaultCondition}
                    isSearchable={false}
                    onChange={(cond) => {
                      var vehicleOptions = {}
                      if (user.vehicle_options) {
                        vehicleOptions = {...user.vehicles_options}
                      }
                      vehicleOptions['default_condition'] = cond['value']
                      UserActionCreators.updateUser({'vehicle_options': vehicleOptions})

                      this.setState({ selectedDefaultCondition: cond })

                    }}
                    options={
                      [
                        {value: 'rough', label: 'Rough Condition'},
                        {value: 'average', label: 'Average Condition'},
                        {value: 'clean', label: 'Clean Condition'}
                      ].map((cond) => {
                        return (
                          {value: cond['value'], label: cond['label']}
                        )
                      })
                    }
                  />
                  </div>
                </div>
              </div>
            </div> :
            <div>You must have an active Carbly account to change your vehicle defaults.</div>
          }
        </section>

        <section className="card">
          <h2>Cost Estimates</h2>
          <p>When calculating potential profit of vehicles, the following estimates will be used to determine per vehicle costs.</p>
          {
            !accountStatusRestricted ?

            <div>
              <div style={{fontWeight: 'bold'}}>Reconditioning costs</div>
              <div className='split-row' style={{marginBottom: '10px'}}>
                <div><span style={{fontWeight: 'bold'}}>CR 0:</span> {Formatters.formatCurrencyCents(vehicle_condition_zero_cost_cents)}</div>
                <div><span style={{fontWeight: 'bold'}}>CR 1:</span> {Formatters.formatCurrencyCents(vehicle_condition_one_cost_cents)}</div>
                <div><span style={{fontWeight: 'bold'}}>CR 2:</span> {Formatters.formatCurrencyCents(vehicle_condition_two_cost_cents)}</div>
                <div><span style={{fontWeight: 'bold'}}>CR 3:</span> {Formatters.formatCurrencyCents(vehicle_condition_three_cost_cents)}</div>
                <div><span style={{fontWeight: 'bold'}}>CR 4:</span> {Formatters.formatCurrencyCents(vehicle_condition_four_cost_cents)}</div>
                <div><span style={{fontWeight: 'bold'}}>CR 5:</span> {Formatters.formatCurrencyCents(vehicle_condition_five_cost_cents)}</div>
              </div>

              <div style={{marginBottom: '10px'}}><span style={{fontWeight: 'bold'}}>Recon cost for vehicles with no CR score:</span> {Formatters.formatCurrencyCents(vehicle_condition_none_cost_cents)}</div>

              <div style={{marginBottom: '10px'}}><span style={{fontWeight: 'bold'}}>Transport cost:</span> {Formatters.formatCurrencyCents(vehicle_transportation_cost_cents)} per mile</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight: 'bold'}}>Auction fees:</span> {Formatters.formatCurrencyCents(vehicle_auction_fees_cost_cents)}</div>
              <div style={{marginBottom: '10px'}}><span style={{fontWeight: 'bold'}}>Other costs:</span> {vehicle_misc_fees_cost_cents > 0 ? Formatters.formatCurrencyCents(vehicle_misc_fees_cost_cents) : 'None'}</div>
              {
                SessionStore.canEditGlobalCosts() ?
                  <a href="#edit-costs" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isShowingGlobalCostsEntryModal: true })
                  }}>Edit costs</a> :
                  <div>Contact your account owner to make changes to these costs</div>
              }
            </div> :
            <div>You must have an active Carbly account to change your cost estimates.</div>
          }

          {
            isShowingGlobalCostsEntryModal &&
            <GlobalCostsEntryModal
              onCancel={() => {this.setState({ isShowingGlobalCostsEntryModal: false })}}
              />
          }
        </section>

        <MobileAppInstall className='card' />

        {
          <section className="card">
            <h2>Browser Extension</h2>
            <div>You { user?.has_used_extension === true ? 'have' : 'have not yet'} used the Carbly Browser Extension</div>

            <div style={{marginTop: '10px'}}><Link to='/extension'>How to install the browser extension</Link></div>
          </section>
        }

        {
          noAccessBookNames !== null &&
          <div>
            <div>Your plan does not include access to {noAccessBookNames}. If you would like to add a provider give us a call at {AppConstants.phoneNumber}.</div>
          </div>
        }


        <div style={{marginTop: '60px', textAlign: 'center'}}>
          <div style={{marginBottom: '30px', paddingBottom: '30px', borderBottom: '1px solid #bbb'}}>
            <h2>Carbly Contact Info</h2>
            <div>Phone Number: {AppConstants.phoneNumber}</div>
            <div>Email: <a href={`mailto: ${AppConstants.supportEmailAddress}`} target='_blank' rel="noopener noreferrer">{AppConstants.supportEmailAddress}</a></div>
            <a href="#logout"
              style={{display: 'block', marginTop: '20px'}}
              onClick={(e) => {
              e.preventDefault()
              this.props.history.replace('/')
              SessionActionCreators.logout()
            }}>Logout of Carbly</a>
          </div>

          <div style={{marginBottom: '20px'}}><a href="/eula.html" target="_blank" rel="noopener noreferrer">View Terms of Service</a></div>


          <ProvidersLegal />


          <div style={{color: '#fff'}}>Environment: { process.env.REACT_APP_ENV_NAME }</div>
          <div>Carbly Desktop version {packageJson.version}</div>
        </div>

        {
          isShowingAddUserModal &&
          <AddUserModal onDone={() => { this.setState({ isShowingAddUserModal: false })}} />
        }
      </div>
    )
  }
}

export default (props) => {
  const dotAuctionClient = useApolloClient()
  return <UserSettings {...props} dotAuctionClient={dotAuctionClient} />
}
