import AppConstants from '../../api/constants/AppConstants'

export const reactSelectStyles = {
  option: (provided) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: '10px'
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}

export const regionalReportOptions = {
  dealerType: [
    { value: 'all', label: 'All dealers' },
    { value: 'indy', label: 'Only independent dealers' },
    { value: 'franchise', label: 'Only franchise dealers' },
  ],
  radius: AppConstants.regionRadiusOptions.map(value => ({ value, label: `${value} miles` }))
}
