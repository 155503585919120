import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from 'reactstrap'

const RequestWebsiteUrlChangeModal = ({ isSaving, onCancel, initialValue, onSubmit: propsOnSubmit }) => {
  const [value, setValue] = useState(initialValue || '')
  const inputRef = useRef(null)

  const onCancelClick = useCallback(e => {
    e.preventDefault()
    onCancel()
  }, [onCancel])

  const onUrlChange = useCallback(e => {
    setValue(e.target.value)
  }, [])

  const urlIsValid = useMemo(() => {
    return Boolean(value)
    // FIXME: Validate url format?
    // && value.match(/^https?:\/\/.+\..+/)
  }, [value])

  const onSubmit = useCallback(e => {
    e.preventDefault()
    propsOnSubmit(value)
  }, [propsOnSubmit, value])

  useEffect(() => {
    requestAnimationFrame(() => inputRef.current?.focus())
  }, [])

  return (
    <Modal isOpen centered toggle={onCancel} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '500px'}}>
      <form onSubmit={onSubmit}>
        <ModalHeader className='modal-header'>
          Link Your Website
        </ModalHeader>
        <ModalBody>
          <div style={{position: 'relative'}}>
            <div style={{opacity: isSaving ? 0 : 1}}>
              <p>Enter your website below and we will start the process of reviewing it for compatibility with Carbly.</p>
              <Input
                innerRef={inputRef}
                type='text'
                disabled={isSaving}
                value={value}
                placeholder='Enter website (i.e. https://www.example.com)'
                onChange={onUrlChange}
                style={{width: '100%'}}
              />

              <div style={{textAlign: 'center'}}>
                <Button type='submit' color='primary' disabled={!urlIsValid || isSaving}>Submit Website</Button>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onCancelClick}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default RequestWebsiteUrlChangeModal
