import React from 'react'
import { Button } from 'reactstrap'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'


export default class ValuationProviderChoicesCard extends React.Component {
  state = {
    isLoading: false
  }

  render() {
    const providerKey = this.props.providerKey
    const title = this.props.title
    const providerResults = this.props.providerResults
    const showAsLoading = this.props.showAsLoading === true
    const { isLoading } = this.state

    return (
      <section className="card valuation-section">
        <h2>{title || AppConstants.providerNames[providerKey]}</h2>

        <div>
          {
            (isLoading || showAsLoading) &&
            <Spinner />
          }
          {
            !isLoading && !showAsLoading &&
            <div>
              <div style={{align: 'center'}}>Select the vehicle configuration</div>
              <div style={{padding: '10px 0'}}>
                {
                  providerResults.map((choice) => {
                    return (
                      <Button color="secondary" style={{margin: '5px'}} onClick={(e) => {
                        this.setState({isLoading: true})
                        this.props.onChoice(providerKey, choice['description']['uid'])
                      }} key={`${providerKey}-choice-${choice['description']['uid']}`} >
                          {choice['description']['display_choice']}{choice?.description?.total_transaction_count ? ` (${choice.description.total_transaction_count})` : ''}
                      </Button>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>

      </section>
    )
  }
}
