import { Link } from 'react-router-dom'
import AppConstants from '../../api/constants/AppConstants'
import ValuationStore from '../../api/stores/ValuationStore'

const PreviewMessage = () => {
  if (global.isExtensionMode || ValuationStore.isSharedValuation) {
    return null
  }
  return (
    <div style={{border: '1px solid #ddd', padding: '4px 8px', marginBottom: '10px'}}>This is a free preview. <Link to='/account'>Add {AppConstants.providerNames['my_lot']} to your account.</Link></div>
  )
}

export default PreviewMessage