import { useState, useCallback } from "react"
import { debounce } from 'lodash'
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import VehiclesListContainer from "../../pages/myLot/VehiclesListContainer"

const INPUT_DEBOUNCE_MS = 400

const LotVehiclesSearchModal = ({onClose}) => {
  const [stockNumber, setStockNumber] = useState('')
  const [vinNumber, setVinNumber] = useState('')
  const [query, setQuery] = useState()

  const hasStockNumber = stockNumber.length > 0
  const hasVINNumber = vinNumber.length > 0

  const handleDebounceFn = useCallback((query) => { setQuery(query) }, [])

  const debouncedQuery = useCallback(debounce(handleDebounceFn, INPUT_DEBOUNCE_MS), [handleDebounceFn]);

  const onVINInputChange = useCallback((e) => {
    const value = e.target.value.toUpperCase()
    setVinNumber(value)
    
    debouncedQuery(value.length ? {vin: value} : null)
  }, [debouncedQuery])

  const onStockNumberInputChange = useCallback((e) => {
    const value = e.target.value
    setStockNumber(value)
    
    debouncedQuery(value.length ? {stock_number: value} : null)
  }, [debouncedQuery])

  return (
    <Modal size="lg" centered={true} isOpen={true} contentClassName='modal-c my-lot-search-modal-c' style={{minWidth: '1180px'}}>
      <ModalHeader className='modal-header'>
        Search Current and Past Inventory
      </ModalHeader>
      <ModalBody>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <div style={{flexGrow: 0, flexShrink: 0, height: '50px'}}>
            <div className='split-row' style={{alignItems: 'center'}}>
              <div className='split-row' style={{alignItems: 'center', flexShrink: 1, flexGrow: 1}}>
                <div style={{width: '80px', flexGrow: 0, flexShrink: 0}}>Stock #</div>
                <input type='text' readOnly={hasVINNumber} className='field' style={{marginBottom: '0', opacity: hasVINNumber ? 0.4 : 1}} value={stockNumber} onChange={onStockNumberInputChange} />
              </div>

              <div className='secondary-text' style={{textAlign: 'center', flexShrink: 0, flexGrow: 0, width: '80px'}}>OR</div>

              <div className='split-row' style={{alignItems: 'center', flexShrink: 1, flexGrow: 1}}>
                <div style={{width: '40px', flexGrow: 0, flexShrink: 0}}>VIN</div>
                <input type='text' readOnly={hasStockNumber} className='field' maxLength={17} style={{marginBottom: '0', opacity: hasStockNumber ? 0.4 : 1}} placeholder='Any portion of the VIN' value={vinNumber} onChange={onVINInputChange} />
              </div>
            </div>
          </div>
          
          <div style={{flexGrow: 1, flexShrink: 1}}>
            {
              query &&
              <div style={{maxHeight: '450px', overflowY: 'auto', marginTop: '10px'}}>
                <VehiclesListContainer
                  query={query}
                  containerStyle={{minHeight: 'inherit'}}
                  rowOptions={{target: '_blank'}}
                />
              </div>
            }
          </div>
            
          <div style={{textAlign: 'center', flexGrow: 0, flexShrink: 0}}>
            <Button style={{marginTop: '10px'}} onClick={onClose}>Close</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default LotVehiclesSearchModal