import CalculatorBug from "../../components/common/CalculatorBug"
import AccountBug from "../../components/user/AccountBug"
import OnboardingPrompts from './OnboardingPrompts'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import CheckCircleIcon from '../../components/common/icons/CheckCircleIcon'
import AppConstants from "../../api/constants/AppConstants"
import ExternalLinkIcon from "../../components/common/icons/ExternalLinkIcon"

const FullOnboarding = () => {
  const [
    {
      urlReviewStateMessage,
      lotInventoryStatusMessage,
    },
  ] = useMyLotSettings()

  return (
    <div className="page-body-c body-content" style={{ marginLeft: '100px' }}>

      <div className="content-header">
        <h1>Let's Get Started</h1>

        <div className="global-tools">
          <AccountBug />
          <CalculatorBug />
        </div>
      </div>

      <div style={{marginTop: '20px'}}>
        <div style={{textAlign: 'center'}}><img src="/images/lot-sense-logo-large.png" alt="LotSense Logo" style={{width: '300px', height: '156px'}} /></div>
        <p>Manage the lifecycle of your inventory, from initial acquisition decisions to optimal retail pricing, by leveraging Carbly’s comprehensive data and advanced analytics. Lot Sense provides precise, actionable insights, enabling you to maximize profits, optimize inventory turn, and stay ahead of the competition in your market.</p>
      </div>


      {
        urlReviewStateMessage &&
        <div style={{textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#eee', borderRadius: '6px', padding: '20px'}}>
          <div><CheckCircleIcon color='green' checked /> We have received your website link submission and are in the process of reviewing and activating it.  We will notify you when you are ready to go.</div>
          <div className='secondary-text'>{urlReviewStateMessage}</div>
        </div>
      }

      {
        lotInventoryStatusMessage &&
        <div style={{textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#eee', borderRadius: '6px', padding: '20px'}}>
          <div><CheckCircleIcon color='green' checked /> We have received your request to connect your inventory management system.  We will notify you when you are ready to go.</div>
          <div className='secondary-text'>{lotInventoryStatusMessage}</div>
        </div>
      }

      {
        !lotInventoryStatusMessage && !urlReviewStateMessage &&
        <h2 style={{textAlign: 'center', marginTop: '80px'}}>Choose how you would like to get your inventory into Carbly</h2>
      }

      <div className='my-lot-full-onboarding-widgets-c'>
        <OnboardingPrompts showDismissAction={false} showManualVINEntry={!urlReviewStateMessage && !lotInventoryStatusMessage} />
      </div>

      <div style={{textAlign: 'center', marginTop: '40px'}}>
        <a href='https://getcarbly.com/lot-sense' target='_blank' rel='noopener noreferrer'><ExternalLinkIcon /> Learn more about {AppConstants.providerNames.my_lot}</a>
      </div>
    </div>
  )
}

export default FullOnboarding
