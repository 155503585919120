import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import pluralize from 'pluralize'
import Select from 'react-select'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import AuctionStore from '../../api/stores/AuctionStore'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import VINEntryField from '../common/VINEntryField'
import ValuationChoices from './ValuationChoices'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import ChoicesFunctions from '../../api/constants/ChoicesFunctions'
import MakeModelPicker from './MakeModelPicker'
import MileageEntryField from '../common/MileageEntryField'
import ZipCodeEntryField from '../common/ZipCodeEntryField'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Formatters from '../../api/constants/Formatters'
import PlusIcon from '../common/icons/PlusIcon'
import OpenIcon from '../common/icons/OpenIcon'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import ConnectivityStore from '../../api/stores/ConnectivityStore'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import UserActionCreators from '../../api/actions/UserActionCreators'
import MyLotFunctions from '../../api/constants/MyLotFunctions'


export default class NewValuation extends React.Component {
  state = {
    vin: '',
    defaultMileage: this.props.defaultMileage,
    hasValidVIN: false,
    vinFailedChecksum: false,
    mileage: '',
    zipCode: '',
    zipCodeIsValid: false,
    overallCondition: this.props.defaultOverallCondition ? this.props.defaultOverallCondition : (SessionStore.getDefaultVehicleCondition()['value'] ? SessionStore.getDefaultVehicleCondition()['value'] : AppConstants.defaultOverallCondition),
    auctionFoundConditionDecimal: null,
    targetFolder: null,
    isLoading: false,

    isShowingDrilldown: false,
    selectedDrilldownValues: null,

    valuation: null,
    valuationPreview: null,
    valuationPreviewChoices: null,
    choicesComplete: true,
    vehicleNotFound: false,
    existingVehicleFound: false,
    mostRecentRetailListing: null,

    newValuationIsSubmittable: false,
    modalTitle: 'Add a Vehicle',
    lotVehicleState: this.props.defaultLotVehicleState ? this.props.defaultLotVehicleState : null
  }

  // FIXME: RM?
  static contextTypes = { router: PropTypes.object }

  componentDidMount() {
    if (ValuationStore.newValuationTargetFolder) {
      this.setState({ targetFolder: ValuationStore.newValuationTargetFolder })
      ValuationStore.newValuationTargetFolder = null
    }

    this.clientID = 'new-valuation'
    this.hasSubmitted = false

    setTimeout(() => {
      if (this.refs.vinRef) {
        this.refs.vinRef.focus()
      }
    }, 50)

    const defaultVIN = this.props.defaultVIN
    if (defaultVIN) {
      setTimeout(() => {
        if (this.refs.vinRef) {
          this.refs.vinRef.vinChanged(defaultVIN)
        }
      }, 50)
    }

    this.additionalNewVehicleParams = this.props.additionalNewVehicleParams

    if (this.willAutoSubmit()) {
      setTimeout(() => {
        this.onSubmit()
      }, 100)
    }

    AuctionStore.on(`auctions_for_vin_change_${this.clientID}`, this.checkAuctionsForVINChange)
    ValuationStore.on('most_recent_retail_listing_change', this.mostRecentRetailListingChange)
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`valuation_preview_change_${this.clientID}`, this.getValuationPreview)
    ValuationStore.removeListener(`valuation_change_${this.clientID}`, this.valuationCreated)
    AuctionStore.removeListener(`auctions_for_vin_change_${this.clientID}`, this.checkAuctionsForVINChange)
    ValuationStore.removeListener('most_recent_retail_listing_change', this.mostRecentRetailListingChange)
  }

  willAutoSubmit = () => {
    // The extension can autosubmit with just vin and mileage
    const { submitIfMinimallyValid, defaultVIN, defaultMileage } = this.props
    return Boolean(submitIfMinimallyValid && defaultVIN && CommonFunctions.isVINValid(defaultVIN) && defaultMileage && CommonFunctions.sanitizedMileage(defaultMileage).length > 0)
  }

  formattedDefaultMileageString = (mileage) => {
    let formattedMileage = mileage
    if (formattedMileage < 100) {
      formattedMileage = `00${mileage}`
    } else if (formattedMileage < 1000) {
      formattedMileage = `0${mileage}`
    }
    return formattedMileage
  }

  checkAuctionsForVINChange = () => {
    const returnedAuction = AuctionStore.auctionsForVINWithClientID(this.clientID)
    const mileage = this.state.mileage

    if (!this.refs.mileageRef) { return }

    if (returnedAuction) {
      if (!mileage && returnedAuction.mileage && returnedAuction.mileage > 0) {
        if (this.refs.mileageRef) {
          this.foundValidAuctionListing = true
          const formattedMileage = this.formattedDefaultMileageString(returnedAuction.mileage)
          this.refs.mileageRef.mileageChanged(`${formattedMileage}`)
        }
      }

      var enteredOverallCondition = this.state.overallCondition
      var auctionFoundConditionDecimal = this.state.auctionFoundConditionDecimal

      if (returnedAuction.overall_condition) {
        enteredOverallCondition = returnedAuction.overall_condition
      }

      if (returnedAuction.condition_grade !== null && returnedAuction.condition_grade !== undefined) {
        auctionFoundConditionDecimal = returnedAuction.condition_grade
      } else {
        auctionFoundConditionDecimal = null
      }

      this.setState({
        overallCondition: enteredOverallCondition,
        auctionFoundConditionDecimal: auctionFoundConditionDecimal
      })

      if (returnedAuction.mileage && returnedAuction.mileage > 0) {
        setTimeout(() => {
          this.onSubmit()
        }, 75)
      }
    }
  }

  mostRecentRetailListingChange = () => {
    const mostRecentRetailListing = ValuationStore.mostRecentRetailListing
    if (!this.foundValidAuctionListing && mostRecentRetailListing?.mileage && this.refs.mileageRef) {
      setTimeout(() => {
        if (this.state.mileage.length === 0) {
          const formattedMileage = this.formattedDefaultMileageString(mostRecentRetailListing.mileage)
          this.refs.mileageRef.mileageChanged(`${formattedMileage}`)
        }
      }, 50);
    }

    this.setState({
      mostRecentRetailListing
    })
  }

  vinChanged = (vin, isVINValid, vinFailedChecksum) => {
    this.setState({
      vin: vin,
      hasValidVIN: isVINValid,
      vinFailedChecksum: vinFailedChecksum
    })

    if (isVINValid && !vinFailedChecksum && this.refs.mileageRef) {
      AuctionsActionCreators.checkAuctionsForVIN(vin, this.clientID)
      if (SessionStore.hasLLMAccess()) {
        ValuationActionCreators.loadMostRecentRetailListing(vin)
      }
      this.refs.mileageRef.focus()
    }
  }


  mileageChanged = (mileage) => {
    this.setState({ mileage: mileage })
  }

  onSubmit = (e) => {
    if (e) { e.preventDefault() }
    if (this.hasSubmitted === true) { return }

    this.hasSubmitted = true

    const { vin, mileage, zipCode, selectedDrilldownValues, isShowingDrilldown } = this.state
    const trueMileage = CommonFunctions.trueMileageFromInput(mileage)
    const serverZipCode = zipCode && zipCode.length === 5 ? zipCode : null

    this.setState({ isLoading: true })

    if (!isShowingDrilldown) {
      this.clientID = ValuationFunctions.generateClientID(`valuation-${vin}`)
      setTimeout(() => {
        ValuationActionCreators.loadVINValuationPreview(vin, trueMileage, serverZipCode, this.clientID)
      }, 100)
    } else {
      const requestParams = {
        year: selectedDrilldownValues.selectedYearId,
        make: selectedDrilldownValues.selectedMakeId,
        model: selectedDrilldownValues.selectedModelId,
        series: selectedDrilldownValues.selectedSeriesId,
        style: selectedDrilldownValues.selectedStyleId,
        mileage: trueMileage,
        zip_code: serverZipCode
      }

      this.clientID = ValuationFunctions.generateClientID(`drilldown-${selectedDrilldownValues.selectedYearId}-${selectedDrilldownValues.selectedMakeId}-${selectedDrilldownValues.selectedModelId}`)

      setTimeout(() => {
        ValuationActionCreators.loadParamsValuationPreview(requestParams, this.clientID)
      }, 100)
    }

    ValuationStore.on(`valuation_change_${this.clientID}`, this.valuationCreated)
    ValuationStore.on(`valuation_preview_change_${this.clientID}`, this.getValuationPreview)
  }

  getValuationPreview = () => {
    const preview = ValuationStore.valuationPreviewWithClientID(this.clientID)
    const errors = ValuationStore.errors
    const valuationProvidersEnabled = SessionStore.valuationProvidersEnabled() === true
    const shouldCheckForChoices = valuationProvidersEnabled || SessionStore.featureAccess().length > 0

    if (errors) {
      this.setState({
        serverErrorMessage: ValuationStore.errors,
        isLoading: false
      })
      ValuationStore.errors = null
    } else if ((preview.preview_valuations_premapped === null || preview.preview_valuations_premapped === undefined) && valuationProvidersEnabled) {
      this.setState({
        vehicleNotFound: true,
        isLoading: false,
        valuationPreview: preview
      })
    } else {
      const choices = ChoicesFunctions.initializeChoices(preview)
      const choicesComplete = shouldCheckForChoices ? ChoicesFunctions.areChoicesComplete(choices) : true
      const existingVehicle = preview.existing_vehicle
      const isLoading = existingVehicle ? false : choicesComplete

      if (existingVehicle && (global.isIFrameMode || global.isPartnerPreview)) {
        // For iFrame, force it through to server
        this.setState({
          valuationPreview: preview,
          valuationPreviewChoices: choices,
          choicesComplete: true,
          serverErrorMessage: ValuationStore.errors,
          isLoading: true,
          existingVehicleFound: true,
          modalTitle: this.state.modalTitle
        })
        setTimeout(() => {
          this.createValuation()
        }, 50)
      } else if (existingVehicle && global.isExtensionMode) {
        this.props.onExistingVehicleFound(existingVehicle)
      } else {
        this.setState({
          valuationPreview: preview,
          valuationPreviewChoices: choices,
          choicesComplete: choicesComplete,
          serverErrorMessage: ValuationStore.errors,
          isLoading: isLoading,
          existingVehicleFound: existingVehicle ? true : false,
          modalTitle: choicesComplete !== true ? 'Select Vehicle Configuration' : this.state.modalTitle
        })

        if (choicesComplete === true && !existingVehicle) {
          setTimeout(() => {
            this.createValuation()
          }, 50)
        }
      }
    }

  }

  valuationCreated = () => {
    const newValuation = ValuationStore.valuationWithClientID(this.clientID)
    this.props.onDone(newValuation)
  }

  isSubmittable = () => {
    const { mileage, hasValidVIN, vinFailedChecksum, selectedDrilldownValues, isShowingDrilldown, zipCode, zipCodeIsValid } = this.state
    if (mileage === '') { return false }
    if (zipCode?.length > 0 && !zipCodeIsValid) { return false }
    if (!isShowingDrilldown && vinFailedChecksum === true) {return false}
    if (!isShowingDrilldown && hasValidVIN === true) { return true }
    if (isShowingDrilldown && selectedDrilldownValues !== null) { return true }


    return false
  }

  onDrilldownComplete = (drilldownSelections) => {
    this.setState({
      selectedDrilldownValues: drilldownSelections
    })
  }

  onChoicesComplete = (choices) => {
    this.setState({
        valuationPreviewChoices: choices,
        choicesComplete: true,
        isLoading: true
    })
    setTimeout(() => {
      this.createValuation()
    }, 50)
  }

  createValuation = () => {
    const isShowingDrilldown = this.state.isShowingDrilldown
    const trueMileage = CommonFunctions.trueMileageFromInput(this.state.mileage)
    const zipCode = this.state.zipCode
    const serverZipCode = zipCode && zipCode.length === 5 ? zipCode : null
    const shouldCheckForChoices = SessionStore.valuationProvidersEnabled() === true || SessionStore.featureAccess().length > 0


    const { valuationPreviewChoices, lotVehicleState } = this.state
    const formattedChoices = shouldCheckForChoices ? ChoicesFunctions.formattedChoicesForServer(valuationPreviewChoices) : null

    if (!isShowingDrilldown) {
      const { vin, overallCondition, targetFolder } = this.state

      var vinEntryType = null
      if (global.isExtensionMode) {
        vinEntryType = AppConstants.vinEntryTypes['extension']
      } else if (global.isIFrameMode) {
        vinEntryType = AppConstants.vinEntryTypes['iframe']
      } else {
        vinEntryType = AppConstants.vinEntryTypes['manual']
      }

      var additionalNewVehicleParams = {...this.additionalNewVehicleParams}
      if (this.state.auctionFoundConditionDecimal !== null) {
        additionalNewVehicleParams['overall_condition_decimal'] = this.state.auctionFoundConditionDecimal
      }

      if (lotVehicleState?.length > 0) {
        additionalNewVehicleParams['lot_vehicle_attributes'] = {state: lotVehicleState}
      }

      setTimeout(() => {
        if (targetFolder) {
          UserActionCreators.createMetric(AppConstants.metricNames['vehicle_assign_folder'])
        }
        ValuationActionCreators.createVINValuation(vin, trueMileage, overallCondition, serverZipCode, formattedChoices, this.clientID, vinEntryType, additionalNewVehicleParams, targetFolder ? targetFolder.id : null)
      })
    } else if (isShowingDrilldown) {
      const { selectedDrilldownValues, overallCondition, targetFolder } = this.state

      const requestParams = {
        year: selectedDrilldownValues.selectedYearId,
        make: selectedDrilldownValues.selectedMakeId,
        model: selectedDrilldownValues.selectedModelId,
        series: selectedDrilldownValues.selectedSeriesId,
        style: selectedDrilldownValues.selectedStyleId,
        overallCondition: overallCondition,
        zipCode: serverZipCode,
        mileage: trueMileage
      }

      if (targetFolder) {
        UserActionCreators.createMetric(AppConstants.metricNames['vehicle_assign_folder'])
        requestParams['vehicle_folder_id'] = targetFolder.id
      }

      setTimeout(() => {
        ValuationActionCreators.createParamsValuation(requestParams, formattedChoices, this.clientID)
      })
    }

    this.setState({ modalTitle: "Loading Vehicle" })
  }

  zipCodeChanged = (zipCode, isValid) => {
    this.setState({
      zipCode: zipCode,
      zipCodeIsValid: isValid
    })
  }

  renderConditionButtons = () => {
    const conditions = AppConstants.overallConditions
    const overallCondition = this.state.overallCondition
    var buttons = []
    for (let key of Object.keys(conditions)) {
      let condition = conditions[key]
      buttons.push(
        <a href="#select-condition" className={overallCondition && overallCondition === key ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.setState({overallCondition: key})}} key={`new-cond-button-${key}`}>
          <div style={{fontWeight: 'bold'}}>{condition.title}</div>
        </a>
      )
    }
    return buttons
  }


  renderBody = () => {
    if (this.state.isLoading) {
      return ( <Spinner /> )
    }

    const { vin, overallCondition, isShowingDrilldown, choicesComplete, valuationPreviewChoices, valuationPreview, vehicleNotFound, serverErrorMessage, targetFolder, existingVehicleFound, mostRecentRetailListing, lotVehicleState } = this.state
    const submitDisabled = !this.isSubmittable()
    const user = SessionStore.user

    const canOverrideZip = SessionStore.canOverrideZip()
    const existingVehicle = valuationPreview?.existing_vehicle

    if (vehicleNotFound === true) {
      return (
        <div>
          {
            vin !== null &&
            <div style={{textAlign: 'center', fontSize: '1.2rem'}}>
              {
                valuationPreview !== null && valuationPreview.make !== null &&
                <div>
                  <p>While we were able to partially decode this VIN, there is no valuation data available for it.</p>
                  <p style={{fontWeight: 'bold'}}>{valuationPreview.year !== null ? valuationPreview.year : ''} {valuationPreview.make} {valuationPreview.model !== null ? valuationPreview.model : ''}</p>
                </div>

              }
              {
                (valuationPreview === null || valuationPreview.make === null) &&
                <div>
                  <p>We were unable to retrieve data for this VIN.</p>
                  <p>The VIN could be incorrect or the vehicle could be too old or too new.</p>
                  <p>{vin}</p>
                </div>
              }
            </div>
          }
          {
            !vin &&
            <div style={{textAlign: 'center', fontSize: '1.2rem'}}>Unable to find vehicle</div>
          }

          <div className="modal-controls">
            <Button color="secondary" onClick={this.props.onCancel}>Close</Button>
          </div>
        </div>
      )
    }

    if (serverErrorMessage) {
      return (
        <div>
          <div style={{textAlign: 'center', fontSize: '1.2rem'}}>{serverErrorMessage}</div>
          {
            global.isExtensionMode ?
            <div style={{textAlign: 'center', marginTop: '20px'}}>
              <a href='/' target="_blank" rel="noopener noreferrer">Visit Carbly Desktop to manage your account <ExternalLinkIcon /></a>
            </div> :
            <div className="modal-controls">
              <Button color="secondary" onClick={this.props.onCancel}>Close</Button>
            </div>
          }
        </div>
      )
    }

    return (
      <div>
        <div className="new-valuation" style={{opacity: choicesComplete === true && this.willAutoSubmit() ? 0 : 1}}>

          {
            existingVehicle && !global.isPartnerPreview &&
            <div className="vehicle-exists">
              <h3>A vehicle with this VIN already exists in Carbly{ existingVehicle.mileage ? ` with ${Formatters.formatThousands(existingVehicle.mileage)} ${pluralize('mile', existingVehicle.mileage, false)}` : ''}.</h3>
              {
                existingVehicle.user_uuid && user?.uuid && existingVehicle.user_uuid === user.uuid &&
                <div className="created">You created it on {Formatters.formatVerboseDate(existingVehicle.created)}</div>
              }
              {
                existingVehicle.user_uuid && user?.uuid && existingVehicle.user_uuid !== user.uuid &&
                <div className="created">{existingVehicle.user_name} created it on {Formatters.formatVerboseDate(existingVehicle.created)}</div>
              }
              <div>
                {
                  choicesComplete === true &&
                  <div className="modal-controls vehicle-exists-buttons">
                    <Button color="primary" onClick={() => {
                      let url = null
                      if (existingVehicle.recreatable !== false) {
                        url = CommonFunctions.valuationURL(existingVehicle)
                      } else {
                        url = MyLotFunctions.myLotVehicleURL(existingVehicle)
                      }
                      
                      this.props.history.push(url)
                      this.props.onCancel()
                      }
                    }><OpenIcon /> Open the vehicle</Button>

                    {
                      existingVehicle.recreatable !== false &&
                      <Button color="primary" onClick={() => {
                        this.setState({ isLoading: true })
                        this.createValuation()
                        }}><PlusIcon /> Create a new vehicle</Button>
                    }
                  </div>
                }

                {
                  choicesComplete === false &&
                  <div style={{marginBottom: '10px', paddingBottom: '10px', marginTop: '10px', borderBottom: '1px solid #ddd'}}>
                    <a href="#open" onClick={(e) => {
                      e.preventDefault()
                      this.props.history.push(CommonFunctions.valuationURL(existingVehicle))
                      this.props.onCancel()
                    }}>Open the vehicle</a> or make a trim selection below to create a new vehicle.
                  </div>
                }
              </div>
              {
                existingVehicle.recreatable === false &&
                <div style={{marginTop: '10px', textAlign: 'center'}}>
                  A vehicle with this VIN is already on your lot.
                </div>
              }
            </div>
          }

          <form onSubmit={() => {
            if (!submitDisabled) { this.onSubmit() }
          }}>
            {
              choicesComplete === false &&
              <ValuationChoices valuationPreviewChoices={valuationPreviewChoices} valuationPreview={valuationPreview} onChoicesComplete={this.onChoicesComplete} />
            }
            {
              choicesComplete === true && !existingVehicleFound &&
              <div className="new-valuation-form">
                <section>
                  {
                    targetFolder &&
                    <div className="secondary-text" style={{marginBottom: '10px'}}>Adding to "{targetFolder.name}" folder</div>
                  }
                  {
                    !isShowingDrilldown &&
                    <div style={{marginTop: '-10px'}}>
                      <label id="vin-instructions">Type or paste in a VIN number</label>

                      <VINEntryField ref='vinRef' onVinChanged={this.vinChanged} autoFocus showDrilldown={this.props.showDrilldown !== false} defaultVIN={vin} onDrilldown={() => {
                        this.setState({ isShowingDrilldown: true })
                      }}/>
                    </div>
                  }


                  {
                    isShowingDrilldown &&
                    <div>
                      <div className="drilldown-header">
                        <label>Browse Vehicles</label>
                        <a href="#browse" onClick={(e) => {e.preventDefault(); this.setState({ isShowingDrilldown: false })}}>Switch to VIN entry</a>
                      </div>

                      <div>
                        <MakeModelPicker onDrilldownComplete={this.onDrilldownComplete} />
                      </div>
                    </div>
                  }

                </section>

                <section>
                  <div style={{display: 'flex', flexDirectin: 'row', width: '100%'}}>
                    <div style={{flexGrow: 1, flexShink: 1}}>
                      <label>Mileage</label>
                      <MileageEntryField ref='mileageRef' onMileageChange={this.mileageChanged} defaultMileage={this.state.defaultMileage}/>
                    </div>
                    {
                      canOverrideZip === true &&
                      <div style={{flexGrow: 0, flexShink: 0, width: '130px', marginLeft: '10px'}}>
                        <label>In ZIP Code</label>
                        <ZipCodeEntryField placeholder={SessionStore.userZipCode() ? SessionStore.userZipCode() : 'ZIP code'} onZipCodeChange={this.zipCodeChanged} defaultZipCode={this.props.defaultZipCode} />
                      </div>
                    }
                  </div>
                  {
                  Boolean(mostRecentRetailListing?.dealer_name) &&
                  <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {
                      Boolean(mostRecentRetailListing?.primary_photo_url) &&
                      <div style={{width: '50px', height: '50px', borderRadius: '6px', marginRight: '10px', flexGrow: 0, flexShrink: 0, backgroundSize: 'cover', backgroundImage: `url('${ConnectivityStore.apolloApiRoot}${mostRecentRetailListing.primary_photo_url}')`, backgroundPosition: '50% 50%'}} />
                    }
                    <div>
                      <div style={{fontWeight: 'bold', fontSize: '0.9rem', marginBottom: '-2px'}}>Vehicle found by {AppConstants.providerNames.universe}</div>
                      <div>{mostRecentRetailListing.dealer_name} {mostRecentRetailListing?.dealer_city && mostRecentRetailListing?.dealer_state ? ` - ${mostRecentRetailListing?.dealer_city}, ${mostRecentRetailListing?.dealer_state}` : ''}</div>
                    </div>
                  </div>
                }
                </section>

                <section>
                  <label>Condition</label>
                  <div className="condition-c">
                    {
                      this.renderConditionButtons()
                    }
                  </div>
                  <div className="condition-desc secondary-text">{AppConstants.overallConditions[overallCondition]['description']}</div>
                </section>

                {
                  Boolean(SessionStore.hasMyLotAccess() || !SessionStore.hasViewedMyLot()) &&
                  <section>
                    <label>On my lot?</label>
                    <Select
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          borderBottom: '1px solid #aaa',
                          padding: '6px'
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          width: '100%',
                          marginBottom: '5px',
                        }),
                      }}
                      name="vehicleStatus"
                      value={lotVehicleState?.length > 0 ? {value: lotVehicleState, label: AppConstants.myLotStateNames[lotVehicleState]} : null}
                      isSearchable={false}
                      onChange={(selected) => {
                        this.setState({
                          lotVehicleState: selected.value
                        })
                      }}
                      options={
                        AppConstants.myLotStateValues.map((state, i) => (
                          {
                            value: state,
                            label: AppConstants.myLotStateNames[state]
                          }
                        ))
                      }
                    />
                  </section>
                }

              </div>
            }
            <div className="modal-controls" style={{marginTop: 0}}>
              {
                !global.isExtensionMode && !global.isIFrameMode && !existingVehicleFound &&
                <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
              }

              {
                choicesComplete === true && !existingVehicleFound &&
                <Button type="submit" color="primary" disabled={submitDisabled} onClick={this.onSubmit}>{global.isExtensionMode || global.isIFrameMode ? 'Submit' : 'Add Vehicle'}</Button>
              }

            </div>
          </form>
        </div>
      </div>
    )
  }

  render() {
    const { modalTitle } = this.state
    const {missingUserFields, missingRooftopFields} = SessionStore.missingRequiredUserFields()
    const needsUserVerification = missingUserFields?.length > 0 || missingRooftopFields?.length > 0


    // Don't show new vehicle modal if we need more user data
    if (this.props.useModal && !needsUserVerification) {
      return (
        <Modal isOpen={true} centered={true} autoFocus={true} keyboard={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
          <ModalHeader className='modal-header'>{modalTitle}</ModalHeader>
          <ModalBody>
            {this.renderBody()}
          </ModalBody>
        </Modal>
      )
    }

    return this.renderBody()
  }
}
