import TFDispatcher from './TFDispatcher'
import WebAPIUtils from './WebAPIUtils'
import RemoteConstants from '../constants/RemoteConstants'
import platformFunctions from '../../platformFunctions'
var ActionTypes = RemoteConstants.ActionTypes

export default {
  loadExistingVehicle: function(vehicleUUID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VALUATION, () => {WebAPIUtils.loadExistingVehicle(vehicleUUID, clientID)} )
  },

  createVINValuation: function(vin, mileage, overallCondition, zipCode, selectedValuations, clientID, vinEntryType, additionalParams, folderID = null) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_VALUATION, () => {WebAPIUtils.createVINValuation(vin, mileage, overallCondition, zipCode, selectedValuations, clientID, vinEntryType, additionalParams, folderID)} )
  },

  publicVINValuation: function(vin, mileage, zipCode, choices) {
    TFDispatcher.dispatchAction(ActionTypes.PUBLIC_VALUATION, () => {WebAPIUtils.publicVINValuation(vin, mileage, zipCode, choices)} )
  },

  loadVINValuationPreview: function(vin, mileage, zipCode, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VALUATION_PREVIEW, () => {WebAPIUtils.loadVINValuationPreview(vin, mileage, zipCode, clientID)} )
  },

  createParamsValuation: function(params, selectedValuations, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_VALUATION, () => {WebAPIUtils.createParamsValuation(params, selectedValuations, clientID)} )
  },

  loadParamsValuationPreview: function(params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VALUATION_PREVIEW, () => {WebAPIUtils.loadParamsValuationPreview(params, clientID)} )
  },

  loadPreviewForVehicle: function(vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VALUATION_PREVIEW, () => {WebAPIUtils.loadPreviewForVehicle(vehicleUUID)} )
  },

  loadLimitedProvidersValuation: function(vehicleUUID, params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_LIMITED_PROVIDERS_VALUATION, () => {WebAPIUtils.loadLimitedProvidersValuation(vehicleUUID, params, clientID)} )
  },

  loadVehiclesHome: function(scope) {
    // Vehicles home for web needs to pass true to get vehicles back with folders
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VEHICLES_HOME, () => {WebAPIUtils.loadVehiclesHome(platformFunctions.platform && platformFunctions.platform === 'web', scope, RemoteConstants.orderByViewed)} )
  },

  loadPMRSampleDetail: function(uuid, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_PMR_SAMPLE, () => {WebAPIUtils.loadPMRSampleDetail(uuid, clientID)} )
  },

  loadAllPMRSamples: function(uuid, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_ALL_PMR_SAMPLES, () => {WebAPIUtils.loadAllPMRSamples(uuid, clientID)} )
  },

  searchValuations: function(cutoff, params, scope) {
    TFDispatcher.dispatchAction(ActionTypes.VALUATIONS_SEARCH, () => {WebAPIUtils.searchValuations(cutoff, params, scope, RemoteConstants.orderByCreated)} )
  },

  createFolder: function(name) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_FOLDER, () => {WebAPIUtils.createFolder(name)} )
  },

  updateFolder: function(folderID, name) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_FOLDER, () => {WebAPIUtils.updateFolder(folderID, name)} )
  },

  updateFoldersOrder: function(foldersOrder) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_FOLDERS_ORDER, () => {WebAPIUtils.updateFoldersOrder(foldersOrder)} )
  },

  updateValuation: function(vehicleUUID, params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_VALUATION, () => {WebAPIUtils.updateValuation(vehicleUUID, params, clientID)} )
  },

  updateValuationProviderChoice: function(vehicleUUID, providerKey, choiceUID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_VALUATION_CHOICE, () => {WebAPIUtils.updateValuationChoice(vehicleUUID, providerKey, choiceUID, clientID)} )
  },

  linkValuationOnPartner: function(vehicleUUID, partnerCode, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LINK_VALUATION_PARTNER, () => {WebAPIUtils.linkValuationOnPartner(vehicleUUID, partnerCode, clientID)} )
  },

  deleteValuation: function(vehicleUUID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.DELETE_VALUATION, () => {WebAPIUtils.deleteValuation(vehicleUUID, clientID)} )
  },

  deleteFolder: function(folderID) {
    TFDispatcher.dispatchAction(ActionTypes.DELETE_FOLDER, () => {WebAPIUtils.deleteFolder(folderID)} )
  },

  loadFolderInfo: function(folderID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_FOLDER_INFO, () => {WebAPIUtils.loadFolderInfo(folderID, clientID)} )
  },

  loadFolders: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_FOLDERS, () => {WebAPIUtils.loadFolders()} )
  },

  loadRecentValuations: function(cutoff, folderID, clientID, scope, order = RemoteConstants.orderByViewed) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_RECENT_VALUATIONS, () => {WebAPIUtils.loadRecentValuations(cutoff, folderID, clientID, scope, order)} )
  },

  loadValuationsCorpus: function(scope) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VALUATIONS_CORPUS, () => {WebAPIUtils.loadValuationsCorpus(scope)} )
  },

  updateValuationChoices: function(vehicleUUID, choices) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_VALUATION_CHOICES, () => {WebAPIUtils.updateValuationChoices(vehicleUUID, choices)} )
  },

  loadCarfaxSummary: function(vehicleUUID, purchase = false) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_CARFAX_SUMMARY, () => {WebAPIUtils.loadCarfaxSummary(vehicleUUID, purchase)}, { vehicleUUID } )
  },

  loadVintelReport: function(vehicleUUID, reportDetail = 'standard') {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VINTEL_REPORT, () => {WebAPIUtils.loadVintelReport(vehicleUUID, reportDetail)}, { vehicleUUID } )
  },

  loadAutoCheckSummary: function(vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUTOCHECK_SUMMARY, () => {WebAPIUtils.loadAutoCheckReport(vehicleUUID, 'summary')}, { vehicleUUID } )
  },

  loadAutoCheckReport: function(vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_AUTOCHECK_REPORT, () => {WebAPIUtils.loadAutoCheckReport(vehicleUUID, 'standard')} )
  },

  loadMileageFamily: function(vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_MILEAGE_FAMILY, () => {WebAPIUtils.loadMileageFamily(vehicleUUID)} )
  },

  createValuationNote: function(vehicleUUID, noteText, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_VALUATION_NOTE, () => {WebAPIUtils.createValuationNote(vehicleUUID, noteText, clientID)} )
  },

  loadValuationNotes: function(vehicleUUID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VALUATION_NOTES, () => {WebAPIUtils.loadValuationNotes(vehicleUUID, clientID)} )
  },

  createValuationShare: function(vehicleUUID, params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.CREATE_VALUATION_SHARE, () => {WebAPIUtils.createValuationShare(vehicleUUID, params, clientID)} )
  },

  loadSharedValuation: function(vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_SHARED_VALUATION, () => {WebAPIUtils.loadSharedValuation(vehicleUUID)} )
  },

  updatePhoto: function(photoID, params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_PHOTO, () => {WebAPIUtils.updatePhoto(photoID, params, clientID)} )
  },

  deletePhoto: function(photoID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.DELETE_PHOTO, () => {WebAPIUtils.deletePhoto(photoID, clientID)} )
  },

  loadVehicleActivities: function(cutoff, scope, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VEHICLE_ACTIVITIES, () => {WebAPIUtils.loadVehicleActivities(cutoff, scope, clientID, RemoteConstants.orderByCreated)} )
  },

  loadLLMSampleDetail: function(sampleID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_LLM_SAMPLE_DETAIL, () => {WebAPIUtils.loadLLMSampleDetail(sampleID, clientID)} )
  },

  loadLLMTrimSamples: function(trimID, distance, vehicleUUID, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_LLM_TRIM_SAMPLES, () => {WebAPIUtils.loadLLMTrimSamples(trimID, distance, vehicleUUID, clientID)} )
  },

  loadMyLotVehicles: function({ pageStart, pageEnd, order, limit, query, clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_MY_LOT_VEHICLES, () => {WebAPIUtils.loadMyLotVehicles({ pageStart, pageEnd, order, query, limit, clientID })} )
  },

  loadMyLotVehiclesDetails: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_MY_LOT_VEHICLES_DETAILS, () => {WebAPIUtils.loadMyLotVehiclesDetails()} )
  },

  loadMyLotReport: function() {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_MY_LOT_REPORT, () => {WebAPIUtils.loadMyLotReport()} )
  },

  loadMyLotCompetitorsReport: function({ radius, dealerType, zip, clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_MY_LOT_COMPETITORS_REPORT, () => {WebAPIUtils.loadMyLotCompetitorsReport({ radius, dealerType, zip, clientID })}, { clientID })
  },

  updateLotVehicle: function(params, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_LOT_VEHICLE, () => {WebAPIUtils.updateLotVehicle(params, clientID)} )
  },

  loadRegionalReport: function(zip, radius) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_REGIONAL_REPORT, () => {WebAPIUtils.loadRegionalReport(zip, radius)} )
  },

  loadRegionalListings: function({ year, make, model, trim, bodyStyle, zip, radius, clientID, page = 1, order }) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_REGIONAL_LISTINGS, () => WebAPIUtils.loadRegionalListings({ year, make, model, trim, bodyStyle, zip, radius, clientID, page, order }))
  },

  loadRegionalModelStats: function({ make, model, zip, radius, endDate, clientID, includeUngrouped = false, includeGrouped = true }) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_REGIONAL_MODEL_STATS, () => WebAPIUtils.loadRegionalModelStats({ make, model, zip, radius, endDate, clientID, includeUngrouped, includeGrouped }), { clientID } )
  },

  loadRegionalModelScorecard: function({ year, make, model, trim, zip, radius, endDate, clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_REGIONAL_MODEL_SCORECARD, () => WebAPIUtils.loadRegionalModelScorecard({ year, make, model, trim, zip, radius, endDate, clientID }), { clientID } )
  },

  loadMostRecentRetailListing: function(vin) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_MOST_RECENT_RETAIL_LISTING, () => {WebAPIUtils.loadMostRecentRetailListing(vin)} )
  },

  loadVehicleReport: function(vehicleUUID, reportType, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.LOAD_VEHICLE_REPORT, () => {WebAPIUtils.loadVehicleReport(vehicleUUID, reportType, clientID)} )
  },

  purchaseVehicleReport: function(vehicleUUID, reportType, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.PURCHASE_VEHICLE_REPORT, () => {WebAPIUtils.purchaseVehicleReport(vehicleUUID, reportType, clientID)} )
  }
}
