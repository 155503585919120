import { useMemo, useState, useEffect, useCallback } from 'react'
import Lightbox from 'react-image-lightbox'
import pluralize from 'pluralize'
import moment from 'moment'
import Formatters from '../../api/constants/Formatters'
import PriceMileageScatterChart from '../myLot/PriceMileageScatterChart'
import PriceChangesModal from '../myLot/PriceChangesModal'
import ListingImage from '../common/ListingImage'
import VehicleTransactionDataFormModal from '../myLot/VehicleTransactionDataFormModal'
import LineChart from '../myLot/LineChart'
import ValuationStore from '../../api/stores/ValuationStore'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import Alert from '../myLot/Alert'
import LotStateButton from '../myLot/LotStateButton'
import AppConstants from '../../api/constants/AppConstants'
import LotHistoryModal from '../myLot/LotHistoryModal'
import Scorecard from '../retail/Scorecard'
import DateFunctions from '../../api/constants/DateFunctions'
import StrategyWidget from '../retail/StrategyWidget'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import SnoozeAlertsToggle from '../myLot/SnoozeAlertsToggle'

const LotVehicle = ({ valuation }) => {
  const {
    lot_report: {
      model_stats,
      region,
    },
    lot_vehicle: vehicle,
  } = valuation

  const [
    {
      regionSettings: {
        radius,
      },
    },
  ] = useMyLotSettings()

  const { unit_history } = vehicle

  const [isShowingPriceChangesModal, setIsShowingPriceChangesModal] = useState(false)
  const [isShowingTransactionDataModal, setIsShowingTransactionDataModal] = useState(false)
  const [isShowingTrimOnlyLocalComps, setIsShowingTrimOnlyLocalComps] = useState(false)
  const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0)
  const [isShowingLightbox, setIsShowingLightbox] = useState(false)
  const [isShowingLotHistoryModal, setIsShowingLotHistoryModal] = useState(false)
  const [lotVehicle, setLotVehicle] = useState(vehicle)
  const isAlertSnoozed = lotVehicle.is_alert_snoozed

  const now = moment()
  const clientID = lotVehicle.uuid

  useEffect(() => {
    const lotVehicleEv = `lot_vehicle_updated_${clientID}`
    const lotVehicleDeletedEv = `valuation_deleted_${clientID}`
    ValuationStore.on(lotVehicleEv, onDataChange)
    ValuationStore.on(lotVehicleDeletedEv, onLotVehicleDeleted)

    return () => {
      ValuationStore.removeListener(lotVehicleEv, onDataChange)
      ValuationStore.removeListener(lotVehicleDeletedEv, onLotVehicleDeleted)
    }
  }, [onDataChange, clientID, lotVehicle])

  const onDataChange = useCallback(() => {
    const data = ValuationStore.myLotVehiclesUpdated.dataWithID(clientID)
    setLotVehicle({...lotVehicle, ...data})
  }, [clientID])

  const onLotVehicleDeleted = useCallback(() => {
    setLotVehicle({...lotVehicle, isDeleted: true})
    setTimeout(() => {
      window.location.replace('/lot-sense')
    }, 1000)
  }, [clientID])

  const unitHistoryChartProps = useMemo(() => {
    const yFormatFunction = Formatters.formatCurrency
    const data = unit_history

    const hasData = Boolean(data.filter(v => Number(v.retail) || Number(v.wholesale)).length)
    const dataForSuggestedMin = data.reduce((acc, v) => {
      if (v.retail != null) acc.push(v.retail)
      if (v.wholesale != null) acc.push(v.wholesale)
      if (v.acv != null) acc.push(v.acv)
      if (v.asking != null) acc.push(v.asking)
      return acc
    }, [])

    const colors = {
      retail: '#02b1fc',
      wholesale: '#505d62',
      askingPrice: 'rgb(255, 109, 1)',
      acv: 'rgb(206, 200, 32)',
    }

    return {
      height: 266,
      hasData,
      options: {
        parsing: {
          xAxisKey: 'date',
        },
        plugins: {
          datalabels: {
            display: false,
          },
          htmlLegend: {
            containerID: 'unit-history-chart-legend',
          },
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: true,
            callbacks: {
              title: ctx => {
                return moment(ctx[0].label).format('M/DD')
              },
              label: ctx => {
                const text = `${ctx.dataset.label}: ${yFormatFunction(ctx.parsed.y)}`
                if (ctx.dataset.id === 'retail') {
                  return [
                    text,
                    ctx.raw.portion_of_market == null ? null : `${Formatters.formatNumber(ctx.raw.portion_of_market * 100, {maximumFractionDigits: 1})}% of market`,
                  ].filter(Boolean)
                } else {
                  return text
                }
              },
            },
          },
        },
        scales: {
          y: {
            display: true,
            suggestedMin: Math.min(...dataForSuggestedMin) * 0.95,
            ticks: {
              callback: yFormatFunction,
            },
          },
          x: {
            ticks: {
              callback: function(value) {
                return moment(this.getLabelForValue(value)).format('M/DD')
              },
            },
          },
        },
        elements: {
          line: {
            borderWidth: 3,
          },
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      data: {
        datasets: [
          {
            label: 'Retail',
            id: 'retail',
            data,
            borderColor: colors.retail,
            backgroundColor: colors.retail,
            hoverBackgroundColor: colors.retail,
            fill: false,
            parsing: {
              yAxisKey: 'retail',
            },
          },
          {
            label: 'Wholesale',
            data,
            id: 'wholesale',
            borderColor: colors.wholesale,
            backgroundColor: colors.wholesale,
            hoverBackgroundColor: colors.wholesale,
            fill: false,
            parsing: {
              yAxisKey: 'wholesale',
            },
          },
          {
            label: 'Asking Price',
            data,
            id: 'asking',
            borderColor: colors.askingPrice,
            backgroundColor: colors.askingPrice,
            hoverBackgroundColor: colors.askingPrice,
            fill: false,
            parsing: {
              yAxisKey: 'asking',

            },
          },
          {
            label: 'ACV',
            id: 'acv',
            data,
            borderColor: colors.acv,
            backgroundColor: colors.acv,
            hoverBackgroundColor: colors.acv,
            borderDash: [5, 5],
            pointStyle: 'line',
            pointBorderWidth: 2,
            fill: false,
            parsing: {
              yAxisKey: 'acv',

            },
          },
        ],
      },
      yFormatFunction,
    }
  }, [unit_history])

  const { vin: valuationVin, series: valuationTrim } = valuation

  const localComps = useMemo(() => {
    return valuation.lot_report.region.listings
  }, [valuation])

  const filteredLocalComps = useMemo(() => {
    return isShowingTrimOnlyLocalComps ? localComps.filter(l => l.trim === valuationTrim) : localComps
  }, [localComps, valuationTrim, isShowingTrimOnlyLocalComps])

  const priceMileageScatterProps = useMemo(() => {
    return {
      data: filteredLocalComps,
      highlightedData: filteredLocalComps.filter(l => l.vin === valuationVin),
    }
  }, [valuationVin, filteredLocalComps])

  const photoUrls = lotVehicle.photo_urls

  const photoUrlsPreview = useMemo(() => {
    return photoUrls.slice(0, 4)
  }, [photoUrls])

  if (lotVehicle.isDeleted) {
    return null
  }

  return (
    <>
      <section className="lot-vehicle-vdp">
        <div className='split-row status-alerts-c'>
          <div className='split-row split-row-left status-alerts' style={{columnGap: '5px', marginBottom: '10px'}}>
            <LotStateButton lotVehicleState={lotVehicle.state} onClick={() => setIsShowingTransactionDataModal(true)} className='lot-vehicle-state-button-large' />
            {
              !isAlertSnoozed &&
              lotVehicle.alerts.map((alert, i) => <Alert key={i} alert={alert} classname='my-lot-alert-capsule-large' year={valuation.year} make={valuation.make} model={valuation.model} trim={valuationTrim} />)
            }

            <SnoozeAlertsToggle clientID={clientID} lotVehicle={lotVehicle} />
          </div>
          {
            lotVehicle.stock_number &&
            <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>Stock #: {lotVehicle.stock_number}</div>
          }
        </div>

        <div className='split-row split-row-left headlines-c' style={{}}>

          <div className='headlines'>
            <div className='transactions-c'>
              <div className='split-row'>
                <div className='headline'>
                  <div className='headline-title'>Listed Price</div>
                  <div className='headline-feature'>
                    {lotVehicle.price_cents == null ? 'N/A' : Formatters.formatCurrencyCents(lotVehicle.price_cents)}
                  </div>
                  {
                    lotVehicle.portion_of_market != null &&
                    <div className='headline-secondary'>{Formatters.formatNumber(lotVehicle.portion_of_market * 100, {maximumFractionDigits: 1})}% of market</div>
                  }
                </div>

                <div className='headline'>
                  <div className='headline-title'>ACV</div>
                  <div className='headline-feature'>{lotVehicle.acv == null ? 'N/A' : Formatters.formatCurrency(lotVehicle.acv)} </div>
                </div>
              </div>

              <div className='split-row'>
                <div className='headline'>
                  <div className='headline-title'>Costs</div>
                  <div className='headline-feature'>{lotVehicle.costs == null ? 'N/A' : Formatters.formatCurrency(lotVehicle.costs)} </div>
                </div>

                <div className='headline'>
                  <div className='headline-title'>{`Profit ${lotVehicle.sale_price_cents ? '' : 'Potential'}`}</div>
                  <div className='headline-feature'>{lotVehicle.profit == null ? 'N/A' : Formatters.formatCurrency(lotVehicle.profit)} </div>
                </div>
              </div>

              <button className='edit-button' onClick={(e) => {
                  e.preventDefault()
                  setIsShowingTransactionDataModal(true)
                }}>Edit Vehicle</button>
            </div>

            <div style={{flexGrow: 1, flexShrink: 1, height: '100%', display: 'flex', flexDirection: 'column'}}>
              <div className='stats-c'>
                <div className='headline'>
                  <div className='headline-title'>Days on lot</div>
                  <div className='headline-feature'>
                    <span style={{color: MyLotFunctions.colorForDaysOnLot(lotVehicle.days_on_lot)}}>{lotVehicle.days_on_lot === null ? '-' : Formatters.formatThousands(lotVehicle.days_on_lot)}</span>
                  </div>

                  {
                    region.model_stats?.avg_days_on_lot != null &&
                    <div className='headline-secondary'>
                      Region average is {pluralize('day', Formatters.formatNumber(region.model_stats.avg_days_on_lot, {maximumFractionDigits: 0}), true)} for this model
                    </div>
                  }
                  {
                    model_stats.num_sold > 1 &&
                    <div className='headline-secondary' style={{marginTop: region.model_stats?.avg_days_on_lot != null ? '10px' : 'inherit'}}>
                      You average {pluralize('day', Formatters.formatNumber(model_stats.avg_days_on_lot_sold, {maximumFractionDigits: 0}), true)} with this model
                    </div>
                  }
                </div>


                <div className='headline'>
                  <div className='headline-body'>
                    <div className='headline-title'>Price Changes</div>
                    <div className='headline-feature'>
                      { lotVehicle.num_price_changes }
                    </div>

                    {
                      lotVehicle.num_price_changes > 0 &&
                      <div>
                        <div className='headline-secondary'>
                          Total change {lotVehicle.total_price_change_amount_cents == null ? '-' : Formatters.formatCurrencyCents(lotVehicle.total_price_change_amount_cents)} (
                            {((lotVehicle.total_price_change_amount_cents / lotVehicle.price_cents)*100).toFixed(0)}%)
                        </div>

                        <div className='headline-secondary' style={{marginTop: '10px'}}>
                          Last change {lotVehicle.last_price_change_date == null ? '-' : `${pluralize('day', now.diff(lotVehicle.last_price_change_date, 'days'), true)} ago`}
                          {
                            lotVehicle.last_price_change_amount_cents != null && lotVehicle.num_price_changes > 1 &&
                            <span> ({Formatters.formatCurrencyCents(lotVehicle.last_price_change_amount_cents)})</span>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  {
                    lotVehicle.num_price_changes > 0 &&
                    <div className='headline-footer'>
                      <a href="#price-changes" style={{display: 'block', lineHeight: '1rem', marginTop: '10px'}} onClick={(e) => {
                        e.preventDefault()
                        setIsShowingPriceChangesModal(true)}
                        }>View price history</a>
                    </div>
                  }
                </div>


                <div className='headline'>
                  <div className='headline-body'>
                    <div className='headline-title'>SOLD PREVIOUSLY</div>
                    <div className='headline-feature'>
                      { Formatters.formatThousands(model_stats.num_sold) }
                    </div>

                    <div>
                      {/* FIXME: Filter for num_sold > 1? Doing so doesn't mean more than 1 had non-null profit though. */}
                      {
                        model_stats.avg_profit_sold != null &&
                        <div className='headline-secondary'>
                          Average profit {Formatters.formatCurrency(model_stats.avg_profit_sold)}
                        </div>
                      }

                      {
                        model_stats.num_sold > 1 &&
                        <div className='headline-secondary' style={{marginTop: '10px'}}>
                          Days on lot average {Formatters.formatNumber(model_stats.avg_days_on_lot_sold, {maximumFractionDigits: 0})}
                        </div>
                      }

                      {/* FIXME: Filter for num_sold > 1? Doing so doesn't mean more than 1 had non-null avg_original_portion_of_market_sold though. */}
                      {
                        model_stats.avg_original_portion_of_market_sold != null &&
                        <div className='headline-secondary' style={{marginTop: '10px'}}>
                          Average original % of market {`${Formatters.formatNumber(model_stats.avg_original_portion_of_market_sold * 100, {maximumFractionDigits: 1})}%`}
                        </div>
                      }
                    </div>
                  </div>

                  {
                    model_stats.num_sold > 0 &&
                    <div className='headline-footer'>
                      <a href="#lot-history" style={{display: 'block', lineHeight: '1rem', marginTop: '10px'}} onClick={(e) => {
                          e.preventDefault()
                          setIsShowingLotHistoryModal(true)
                        }}>View lot history</a>
                    </div>
                  }
                </div>


                <div className='headline'>
                  <div className='headline-body'>
                    <div className='headline-title'>Vehicle Photos</div>
                    {
                      photoUrls.length === 0 &&
                      <div className='secondary-text' style={{marginTop: '10px'}}>No photos available</div>
                    }
                    <div className='thumbnails'>

                      {
                        photoUrlsPreview.map((url, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              setCurrentLightboxIndex(i)
                              setIsShowingLightbox(true)
                            }}
                          >
                            <ListingImage src={url} style={{ width: '100%', height: 'auto' }}/>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  {
                    photoUrls.length > photoUrlsPreview.length &&
                    <div className='headline-footer'>
                      <a href="#view-all-photos" style={{display: 'block', lineHeight: '1rem', marginTop: '10px'}} onClick={e => {
                        e.preventDefault()
                        setIsShowingLightbox(true)
                      }}>View all photos</a>
                    </div>
                  }
                </div>
              </div>

              <div className='headline' style={{marginTop: '10px', flexGrow: 0, flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '2px 10px'}}>

                {
                  !Boolean(lotVehicle.purchased_at) && !Boolean(lotVehicle.sold_at) &&
                  <div className='secondary-text'>No purchase or sale data</div>
                }

                {
                  Boolean(lotVehicle.purchased_at) &&
                  <div className='split-row' style={{justifyContent: 'center', columnGap: '20px'}}>
                    <div><strong>Purchased</strong> {moment(DateFunctions.dateOnlyStringToISODate(lotVehicle.purchased_at)).format('MMM D, YYYY')}</div>
                    {
                      Boolean(lotVehicle.purchase_price_cents) &&
                      <div>{Formatters.formatCurrencyCents(lotVehicle.purchase_price_cents)}</div>
                    }
                    <div>{AppConstants.myLotPurchaseTypes[lotVehicle.purchase_type]}</div>
                  </div>
                }

                {
                  Boolean(lotVehicle.sold_at) &&
                  <div className='split-row' style={{justifyContent: 'center', columnGap: '20px'}}>
                    <div><strong>Sold</strong> {moment(DateFunctions.dateOnlyStringToISODate(lotVehicle.sold_at)).format('MMM D, YYYY')}</div>
                    {
                      Boolean(lotVehicle.sale_price_cents) &&
                      <div>{Formatters.formatCurrencyCents(lotVehicle.sale_price_cents)}</div>
                    }
                    <div>Retail</div>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>

        {
          lotVehicle?.state !== 'sold' &&
          <StrategyWidget valuation={valuation} clientID={clientID} />
        }

        {
          lotVehicle?.state !== 'sold' &&
          <>
            <div className='section-header' style={{marginTop: '40px', marginBottom: 0}}>
              <h3 style={{fontSize: '1.5rem'}}>{AppConstants.providerNames.regional_performance} - {valuation.year} {valuation.make} {valuation.model} {valuation.series}</h3>
            </div>

            <Scorecard valuation={valuation} />
          </>
        }

        <div className='split-row footer-graphs' style={{alignItems: 'flex-start', columnGap: '20px', flex: 1}}>
          {
            lotVehicle?.state !== 'sold' &&
            <div style={{width: '50%'}}>
              <div className='section-header' style={{ display: 'flex', justifyContent: 'space-between', gap: 20, alignItems: 'center' }}>
                <h3 style={{fontSize: '1.5rem'}}>{Formatters.formatThousands(filteredLocalComps.length)} for Sale Within {pluralize('mile', radius, true)}</h3>
                {
                  valuationTrim && !(!isShowingTrimOnlyLocalComps && filteredLocalComps.length === 0) &&
                  <button style={{marginRight: '10px'}} className='link' onClick={(() => setIsShowingTrimOnlyLocalComps(!isShowingTrimOnlyLocalComps) )}>
                    {isShowingTrimOnlyLocalComps ? 'Show all trims' : `Show only ${valuationTrim} trim`}
                  </button>
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div>
                  <PriceMileageScatterChart
                    {...priceMileageScatterProps}
                    containerStyle={{height: '266px'}}
                  />
                  <div className='secondary-text' style={{textAlign: 'right', marginRight: '20px', marginTop: '10px'}}>This vehicle is highlighted</div>
                </div>
              </div>
            </div>
          }
          <div style={{width: lotVehicle?.state === 'sold' ? '100%' : '50%'}}>
            <div className='section-header' style={{marginTop: '40px'}}>
              <h3 style={{fontSize: '1.5rem'}}>Unit History</h3>
            </div>

            <div style={{width: '100%' }}>
              {
                unitHistoryChartProps.hasData ?
                  <LineChart {...unitHistoryChartProps} />
                  :
                  <div className='secondary-text' style={{textAlign: 'center', marginTop: '10px'}}>No data available</div>
              }
            </div>
          </div>
        </div>
      </section>

      {
        isShowingPriceChangesModal &&
        <PriceChangesModal
          onCancel={() => setIsShowingPriceChangesModal(false)}
          data={lotVehicle.price_changes}
        />
      }
      {
        isShowingTransactionDataModal &&
        <VehicleTransactionDataFormModal onCancel={() => setIsShowingTransactionDataModal(false)} vehicle={{ uuid: lotVehicle.uuid || valuation.uuid, year: valuation.year, make: valuation.make, model: valuation.model, lot_vehicle: {...lotVehicle, uuid: valuation.uuid} }} onDelete={() => setIsShowingTransactionDataModal(false)} clientID={clientID} />
      }

      {
        isShowingLightbox &&
        <Lightbox
          reactModalStyle={{overlay: {zIndex: 10000}}}
          mainSrc={photoUrls[currentLightboxIndex]}
          nextSrc={photoUrls[(currentLightboxIndex + 1) % photoUrls.length]}
          prevSrc={photoUrls[(currentLightboxIndex + photoUrls.length - 1) % photoUrls.length]}
          onCloseRequest={() => {
            setCurrentLightboxIndex(0)
            setIsShowingLightbox(false)
          }}
          onMovePrevRequest={() => setCurrentLightboxIndex((currentLightboxIndex + photoUrls.length - 1) % photoUrls.length)}
          onMoveNextRequest={() => setCurrentLightboxIndex((currentLightboxIndex + 1) % photoUrls.length)}
        />
      }

      {
        isShowingLotHistoryModal &&
        <LotHistoryModal
          onClose={() => setIsShowingLotHistoryModal(false)}
          year={valuation.year}
          make={valuation.make}
          model={valuation.model}
          trim={valuationTrim}
          modelStats={model_stats}
        />
      }
    </>
  )
}

export default LotVehicle
