import React from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Valuation from './Valuation'
import Spinner from '../common/Spinner'
import ActivitiesInfiniteScroll from '../activities/ActivitiesInfiniteScroll'
import ContractIcon from '../common/icons/ContractIcon'
import BooksUpsellList from './BooksUpsellList'
import ExtensionPrompt from '../common/ExtensionPrompt'
import platformFunctions from '../../platformFunctions'
import AppConstants from '../../api/constants/AppConstants'
import AccountNeedsAction from '../user/AccountNeedsAction'

// FIXME: critical prev / next in browser loads from server each time.  What's the hook / lifecycle here?

export default class ValuationShow extends React.Component {

  constructor() {
    super()
    this.clientID = null

    this.state = {
      isLoading: true,
      valuation: null,
      serverErrorMessage: null,
      is404: false
    }
  }

  componentDidMount() {
    if (!CommonFunctions.hasValidIDMatchProp(this.props)) {
      console.log("No valid UUID in ValuationShow componentDidMount");
      return
    }

    const uuid = this.props.navMatch.params.id
    this.clientID = this.composeClientID(uuid)
    this.user = SessionStore.user


    SessionStore.on('user_updated', this.userUpdated)
    ValuationStore.on(`valuation_change_${this.clientID}`, this.getValuation)
    this.loadCacheOrFetchValuation(uuid)

    if (!SessionStore.hasAccountOfferings()) {
      platformFunctions.logAnalyticsEvent("User", "Free User Show Vehicle")
    }
  }

  componentWillUnmount() {
    ValuationStore.removeListener(`valuation_change_${this.clientID}`, this.getValuation)
    SessionStore.removeListener('user_updated', this.userUpdated)
  }

  userUpdated = () => {
    // We have a strange edge case whereby we can have a user create a vehicle, but they don't have a zip code
    // Free users such as login with Edge can get in this state.  Reload page once we have a zip
    const user = SessionStore.user
    if (!this.user?.zip_code && user?.zip_code) {
      this.user = user
      window.location.reload()
    }
  }

  loadCacheOrFetchValuation = (valuationUUID) => {
    window.scrollTo(0, 0)

    const cachedValuation = ValuationStore.valuationWithClientID(`new-valuation-${valuationUUID}`)
    if (cachedValuation) {
      setTimeout(() => {
        // If this is a result of a new valuation, the created valuation was previously
        // stored with the key 'new-valuation-{uuid}'.  Fetch it here and cleanup.
        ValuationStore.valuations.addToCollection(this.clientID, cachedValuation)
        ValuationStore.valuations.removeItem(`new-valuation-${valuationUUID}`)
        this.setState({
          valuation: cachedValuation,
          isLoading: false,
          is404: false,
          serverErrorMessage: null
        })
      }, 0)
    } else {
      setTimeout(() => {
        ValuationActionCreators.loadExistingVehicle(valuationUUID, this.clientID)
      }, 100)
    }
  }

  composeClientID = (id) => {
    return `valuation-${id}`
  }

  getValuation = () => {
    const newValuation = ValuationStore.valuationWithClientID(this.clientID)
    const errors = ValuationStore.errors
    if (newValuation?.uuid && newValuation.uuid !== this.state.valuation?.uuid) {
      this.clientID = this.composeClientID(newValuation.uuid)
    }

    if (errors) {
      this.setState({
        valuation: null,
        serverErrorMessage: ValuationStore.errors,
        isLoading: false,
        is404: false
      })
      ValuationStore.errors = null
    } else if (newValuation) {
      this.setState({
        valuation: newValuation,
        serverErrorMessage: ValuationStore.errors,
        isLoading: false,
        is404: false
      })
    } else {
      // Valuation not found
      this.setState({
        isLoading: false,
        is404: true
      })
    }
  }

  updateValuationWithReload = (params) => {
    const { valuation } = this.state

    ValuationActionCreators.updateValuation(valuation.uuid, params, this.clientID)

    this.setState({
      isLoading: true,
      valuation: null
    })
  }

  getActivityIsDisabled = activity => {
    return activity.vehicle_is_deleted
  }

  render() {
    if (!CommonFunctions.hasValidIDMatchProp(this.props)) {return null}
    const { isLoading, valuation, is404, serverErrorMessage } = this.state

    if (isLoading) {
      return ( <Spinner className="spinner-page" /> )
    }

    if (is404) {
      return (<div style={{textAlign: 'center', marginTop: '5rem', fontSize: '2rem', letterSpacing: '-1px'}}>Vehicle not found</div>)
    }

    if (serverErrorMessage || valuation?.errors) {

      // Hacky string matching
      if (serverErrorMessage && serverErrorMessage === AppConstants.server403Message) {
        return (
          <AccountNeedsAction className='account-action-page-header' />
        )

      } else if (serverErrorMessage || !valuation || valuation.errors) {
        var message = null
        if (serverErrorMessage) {
          message = serverErrorMessage
        } else if (valuation?.errors) {
          message = valuation.errors.join(". ")
        }
        return (<div style={{textAlign: 'center', margin: '2rem 20px'}}>{message}</div>)
      }
    }

    if (valuation) {
      return (
        <>
          <Valuation valuationData={valuation} persistChoices={true} onUpdateMileageAndCondition={this.updateValuationWithReload} clientID={this.clientID} />

          <div className="activity-c" style={{ padding: 20, paddingLeft: 40 }}>
            <h4 style={{ marginBottom: 20 }}>Vehicle Activity</h4>
            <ActivitiesInfiniteScroll
              clientID={this.clientID}
              scope={{ vehicle_uuid: valuation.uuid }}
              getActivityIsDisabled={this.getActivityIsDisabled}
            />
          </div>

          {
            SessionStore.hasAccountOfferings() &&
            <BooksUpsellList />
          }

          <ExtensionPrompt />
        
          <div className="back-to-top-footer">
            <a href="#top" onClick={(e) => {
            e.preventDefault()
            window.scrollTo(0,0)
            }}>
              <ContractIcon color="#aaa" width={24} height={24} style={{marginRight: '10px'}} />
              Back to the top
              <ContractIcon color="#aaa" width={24} height={24} style={{marginLeft: '10px'}} />
              </a>
          </div>
        </>
      )
    }

    return null

  }
}
