import React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import pluralize from 'pluralize'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import Spinner from '../common/Spinner'
import AppConstants from '../../api/constants/AppConstants'
import SessionActionCreators from '../../api/actions/SessionActionCreators'
import PaymentFormModal from '../settings/PaymentFormModal'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'
import OfferingsPicker from '../common/OfferingsPicker'


export default class AccountReactivation extends React.Component {
  state = {
    isLoadingAccountDetails: true,
    accountDetails: null,
    isLoadingOfferingsSlate: true,
    offeringsSlate: null,
    isAccountOwner: SessionStore.isAccountOwner(),
    isShowingPlanPickerModal: false,
    isLoadingCostForSelected: true,
    modifiedSelectedOfferings: null,
    pricingForModifiedSelectedOfferings: null,
    newlySelectedOfferings: null,
    pricingForNewlySelectedOfferings: null,
    pricingForNewlySelectedOfferings: null,
    isShowingChangePaymentMethodModal: false,
    wasPaymentMethodUpdated: false,
    overriddenBillingInterval: null,
    isReactivating: false,
    accountReactivationErrorMessage: null
  }

  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("offerings_slate_changed", this.offeringsSlateReceived)
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("user_updated_externally", this.paymentMethodUpdated)
    SessionStore.on("account_reactivation_received", this.accountReactivationReceived)
    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadOfferingsSlate()
    UserActionCreators.loadPricingForOfferings()
    platformFunctions.logAnalyticsEvent("User", "Opened Account Reactivation")
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("offerings_slate_changed", this.offeringsSlateReceived)
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("user_updated_externally", this.paymentMethodUpdated)
    SessionStore.removeListener("account_reactivation_received", this.accountReactivationReceived)
  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    this.setState({
      isLoadingCostForSelected: false,
      pricingForModifiedSelectedOfferings: pricingForOfferings
    })
  }  

  offeringsSlateReceived = () => {
    this.setState({
      isLoadingOfferingsSlate: false,
      offeringsSlate: SessionStore.offeringsSlate
    })
  }

  modifiedSelectedOfferingsDidChange = (modifiedSelectedOfferings, pricingForModifiedSelectedOfferings) => {
    this.setState({
      modifiedSelectedOfferings: modifiedSelectedOfferings,
      pricingForModifiedSelectedOfferings: pricingForModifiedSelectedOfferings
    })
  }

  saveSelectedOfferings = (selectedOfferings, priceForSelectedOfferings) => {
    this.setState({
      newlySelectedOfferings: selectedOfferings,
      pricingForNewlySelectedOfferings: priceForSelectedOfferings,
      isShowingPlanPickerModal: false
    })
    platformFunctions.logAnalyticsEvent("User", "Reactivation - Changed Offerings")
  }

  paymentMethodUpdated = () => {
    this.setState({
      wasPaymentMethodUpdated: true,
      isShowingChangePaymentMethodModal: false,
      accountReactivationErrorMessage: null
    })
    platformFunctions.logAnalyticsEvent("User", "Reactivation - Updated Payment Method")
  }

  reactivateAccount = () => {
    this.setState({ isReactivating: true })
    const { newlySelectedOfferings, overriddenBillingInterval } = this.state
    var offeringSKUs = null
    if (newlySelectedOfferings && newlySelectedOfferings.length > 0) {
      offeringSKUs = newlySelectedOfferings.map((offering) => offering.sku)
    }
    platformFunctions.logAnalyticsEvent("User", "Reactivated Account Attempt")
    UserActionCreators.reactivateAccount(offeringSKUs, overriddenBillingInterval)
  }

  accountReactivationReceived = () => {
    // FIXME: RM?
    setTimeout(() => {
      if (SessionStore.accountReactivationErrorMessage) {
        this.setState({
          accountReactivationErrorMessage: SessionStore.accountReactivationErrorMessage,
          isReactivating: false,
          isLoading: false
        }) 
      } else {
        window.location.reload()
      }
    }, 6000);
  }

  changeBillingInterval = () => {
    const { accountDetails, overriddenBillingInterval, modifiedSelectedOfferings } = this.state
    const currentBillingInterval = overriddenBillingInterval ? overriddenBillingInterval : accountDetails?.billing_interval

    const newBillingInterval = currentBillingInterval === 'year' ? 'month' : 'year'
    this.setState({
      overriddenBillingInterval: newBillingInterval
    })

    platformFunctions.logAnalyticsEvent("User", "Reactivation - Changed Billing Interval")

    UserActionCreators.loadPricingForOfferings(modifiedSelectedOfferings && modifiedSelectedOfferings.length > 0 ? modifiedSelectedOfferings.map((offering) => offering.sku) : null, newBillingInterval)
  }

  renderContactUs = () => {
    return (<div>If you need further assistance, please give us a call at { AppConstants.phoneNumber }</div>)
  }

  renderLogout = () => {
    if (this.props.showLogout) {
      return (
      <a href="#logout" onClick={(e) => {e.preventDefault(); SessionActionCreators.logout()}}>Logout of Carbly</a>
      )
    }
  }

  render() {
    const { isLoadingAccountDetails, isAccountOwner, accountDetails, isLoadingOfferingsSlate, offeringsSlate, isShowingPlanPickerModal, newlySelectedOfferings, isShowingChangePaymentMethodModal, wasPaymentMethodUpdated, isLoadingCostForSelected, overriddenBillingInterval, pricingForModifiedSelectedOfferings, modifiedSelectedOfferings, isReactivating, accountReactivationErrorMessage } = this.state

    const canBeReactivated = accountDetails?.can_be_reactivated === true
    const isLoading = isLoadingAccountDetails || isLoadingOfferingsSlate || isLoadingCostForSelected

    const currentBillingInterval = overriddenBillingInterval ? overriddenBillingInterval : accountDetails?.billing_interval
    const isBillingIntervalYearly = currentBillingInterval === 'year'


    if (isLoading || isReactivating) {
      return (
        <div>
          <Spinner className='spinner-medium' />
        </div>
      )
    }

    const planOfferings = newlySelectedOfferings ? newlySelectedOfferings : accountDetails?.account_offerings
    var usersCount = 0
    if (accountDetails?.user_details) {
      usersCount = accountDetails?.user_details?.length
    }

    var existingAccountOfferingsNamesArray = null
    if (planOfferings) {
      existingAccountOfferingsNamesArray = planOfferings.map((offering) => offering.name)
    }

    return (
      <div>
        
        {
          !isAccountOwner &&
          <div>
            <div style={{marginBottom: '20px'}}>Please contact your account owner to have your account reactivated.</div>
            { this.renderContactUs() }
            { this.renderLogout() }
          </div>
        }

        {
          isAccountOwner &&
          <div>
            {
              canBeReactivated &&
              <div>
                <div>
                  <div>Please continue below if you would like to reactivate your account.</div>

                  <section style={{marginTop: '20px', display: 'flex', flexDirection: 'row', borderBottom: '1px solid #ddd', width: '100%', paddingBottom: '10px'}}>
                    <div style={{fontSize: '3rem', lineHeight: '3rem', fontWeight: 'bold', color: '#ddd', width: '70px', flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>1</div>
                    {
                      planOfferings && planOfferings.length > 0 &&
                      <div>
                        <div style={{fontWeight: 'bold', fontSize: '1.3rem', letterSpacing: '-0.4px'}}>Your Plan for {pluralize('User', usersCount, true)}</div>
                        {
                          existingAccountOfferingsNamesArray && existingAccountOfferingsNamesArray.length > 0 &&
                          <div>{existingAccountOfferingsNamesArray.join(', ')}</div>
                        }
                        
                        <a href="#change" onClick={(e) => {
                          e.preventDefault()
                          this.setState({ isShowingPlanPickerModal: true })
                          platformFunctions.logAnalyticsEvent("User", "Reactivation - Showed Change Plan")
                        }}>{existingAccountOfferingsNamesArray && existingAccountOfferingsNamesArray.length > 0 ? 'Change' : 'Select'} plan</a>

                        <div style={{marginTop: '10px'}}>
                          <strong>You will be charged {Formatters.formatCurrencyCents(pricingForModifiedSelectedOfferings?.total_price)} per {currentBillingInterval}{isBillingIntervalYearly ? '. No refunds allowed.' : ''}</strong>
                        </div>
                        <div>
                          <a href="#change-billing" onClick={(e) => {
                            e.preventDefault()
                            this.changeBillingInterval()
                          }}>Switch to {isBillingIntervalYearly ? 'monthly' : 'annual'} billing</a>
                        </div>
                      </div>
                    }

                    {
                      Boolean(!planOfferings || planOfferings.length === 0) &&
                      <div>
                        <div style={{fontWeight: 'bold'}}>You must first specify the plan you would like to activate</div>
                        <a href="#change" onClick={(e) => {
                          e.preventDefault()
                          this.setState({ isShowingPlanPickerModal: true })
                          platformFunctions.logAnalyticsEvent("User", "Reactivation - Showed Change Plan")
                          }}>Choose a plan</a>
                      </div>
                    }
                  </section>

                  <section style={{marginTop: '20px', display: 'flex', flexDirection: 'row'}}>
                    <div style={{fontSize: '3rem', lineHeight: '3rem', fontWeight: 'bold', color: '#ddd', width: '70px', flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>2</div>
                    <div>
                    <div style={{fontWeight: 'bold', fontSize: '1.3rem', letterSpacing: '-0.4px'}}>Payment method</div>
                      {
                        wasPaymentMethodUpdated?
                        <div>Card updated</div> :
                        <div>Upon reactivation, we will attempt to charge the card last used.</div>
                      }
                      <a href="#change" onClick={(e) => { e.preventDefault(); this.setState({ isShowingChangePaymentMethodModal: true })}}>Change payment method</a>
                    </div>
                  </section>

                  {
                    Boolean(accountReactivationErrorMessage) &&
                    <section style={{marginTop: '40px', marginLeft: '50px'}}>
                      <div style={{color: 'red', fontWeight: 'bold', fontSize: '1.2rem'}}>{accountReactivationErrorMessage}</div>
                    </section>  
                  }

                  <section style={{marginTop: '40px', marginLeft: '50px'}}>
                    <Button color="primary" onClick={this.reactivateAccount} disabled={Boolean(isReactivating || !planOfferings || planOfferings.length === 0)}>Reactivate Account</Button>
                  </section>
                </div>
              </div>
            }


          </div>
        }


        {
          isShowingChangePaymentMethodModal &&
          <PaymentFormModal isAccountOwner={true} onCancel={() => this.setState({ isShowingChangePaymentMethodModal: false })} />
        }

        {
          isShowingPlanPickerModal && offeringsSlate?.offerings &&
          <Modal size="lg" centered={true} isOpen={true} contentClassName='modal-c'>
            <ModalHeader className='modal-header'>Change Plan</ModalHeader>
            <ModalBody>
              <OfferingsPicker
                offerings={offeringsSlate?.offerings}
                accountDetails={accountDetails}
                onSelectedOfferingsChange={this.modifiedSelectedOfferingsDidChange} 
                onCostForSelectedOfferingsLoadingStateChange={this.onCostForSelectedOfferingsLoadingStateChange}
                billingIntervalOverride={overriddenBillingInterval}
                onAddOfferings={this.saveSelectedOfferings}
                submitTitle='Done'
                submitStyle={{width: '100%'}}
                onCancel={() => {
                  this.setState({
                    modifiedSelectedOfferings: null,
                    isShowingPlanPickerModal: false
                  })
                }}
                useTotalPrice
                />
            </ModalBody>
          </Modal>
        }
        
      </div>
    )
  }
}