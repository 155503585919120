import { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'

const LotInfoModal = ({ lotName: initialLotName, onClose, onSave }) => {
  const [lotName, setLotName] = useState(initialLotName ?? '')
  const inputRef = useRef(null)

  const onSaveClick = useCallback(() => {
    onSave({
      lotName,
    })
  }, [onSave, lotName])

  useEffect(() => {
    requestAnimationFrame(() => inputRef.current?.focus())
  }, [])

  const isValid = useMemo(() => {
    return lotName.length > 0
  }, [lotName])

  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (isValid) onSaveClick()
      }}
      >
        <ModalHeader className='modal-header'>
          Lot Info
        </ModalHeader>
        <ModalBody>
          <div className="split-row split-row-left">
            <div style={{ marginRight: 10 }}>Lot name</div>
            <Input
              type='text'
              innerRef={inputRef}
              autoComplete='off'
              autoCorrect='off'
              spellCheck='false'
              className='field'
              name='lotName'
              style={{ margin: 0, width: 400 }}
              onChange={e => setLotName(e.target.value)}
              value={lotName}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' disabled={!isValid} onClick={onSaveClick}>Save</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default LotInfoModal
