import React from 'react'
import CalculatorBug from "../../components/common/CalculatorBug"
import AccountBug from "../../components/user/AccountBug"
import AppConstants from "../../api/constants/AppConstants"
import SessionStore from "../../api/stores/SessionStore"
import PlusIcon from "../../components/common/icons/PlusIcon"
import { Button } from "reactstrap"
import UserActionCreators from "../../api/actions/UserActionCreators"
import platformFunctions from '../../platformFunctions'
import Formatters from '../../api/constants/Formatters'
import OfferingUpsellModal from '../../components/common/OfferingUpsellModal'

export default class Upsell extends React.Component {
  state = {
    costForSelected: null,
    accountDetails: null,
    pricingForOfferingsErrors: null,
    isShowingUpsellModal: false
  }


  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("account_reactivation_received", this.accountReactivationReceived)


    platformFunctions.logAnalyticsEvent("User", `Opened ${AppConstants.providerNames.my_lot} Upsell Page`)
    UserActionCreators.createMetric(AppConstants.metricNames['my_lot_upsell'], this.props.metricsValue)

    UserActionCreators.loadAccountDetails()
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("account_reactivation_received", this.accountReactivationReceived)
  }

  accountDetailsReceived = () => {
    const accountDetails = SessionStore.accountDetails
    this.setState({
      accountDetails: accountDetails
    })

    const isDivergent = accountDetails?.divergent_offerings === true
    if (!isDivergent && !SessionStore.isCanceled()) {
      UserActionCreators.loadPricingForOfferings([AppConstants.skuMappings.my_lot])
    }
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    const pricingForOfferingsErrors = SessionStore.pricingForOfferingsErrors

    this.setState({
      costForSelected: pricingForOfferings,
      pricingForOfferingsErrors: pricingForOfferingsErrors
    })
  }

  accountReactivationReceived = () => {
    this.setState({
      costForSelected: null,
      accountDetails: null,
    })
    UserActionCreators.loadAccountDetails()
  }

  render () {
    const { costForSelected, accountDetails, isShowingUpsellModal } = this.state
    const priceString = costForSelected ? ` for ${Formatters.formatCurrencyCents(costForSelected?.incremental_price)} per month` : ''
    const isDivergent = accountDetails?.divergent_offerings === true
    return (
      <div className="page-body-c body-content" style={{ marginLeft: '100px' }}>

        <div className="content-header">
          

          <div className="global-tools">
            <AccountBug />
            <CalculatorBug />
          </div>
        </div>


        <div style={{marginTop: '20px'}}>
          <div style={{textAlign: 'center'}}><img src="/images/lot-sense-logo-large.png" alt="LotSense Logo" style={{width: '300px', height: '156px'}} /></div>
          <p>Manage the lifecycle of your inventory, from initial acquisition decisions to optimal retail pricing, by leveraging Carbly’s comprehensive data and advanced analytics. Lot Sense provides precise, actionable insights, enabling you to maximize profits, optimize inventory turn, and stay ahead of the competition in your market.</p>
        </div>

        {
          SessionStore.isAccountOwner() &&
          <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
          <Button style={{display: 'flex', alignItems: 'center'}} className="highlight-button"
            onClick={() => {
              this.setState({ isShowingUpsellModal: true })
            }}>
              <div>
                <PlusIcon style={{marginRight: '5px'}} /> Add {AppConstants.providerNames['my_lot']} to your Account
              </div>
            </Button>
          </div>
        }

        {
          !isDivergent && priceString &&
          <div style={{textAlign: 'center'}}>{ priceString }</div>
        }

        {
          isDivergent &&
          <div style={{color: 'red', textAlign: 'center'}}>Your account has users with varying options.  If you'd like to add {AppConstants.providerNames['my_lot']}, please give us a call at {AppConstants.phoneNumber} and we'd be happy to help.</div>
        }



        <div style={{marginTop: '20px', marginBottom: '20px'}}>
          <div className="lot-sense-feature-section" style={{marginTop: '50px'}}>
            <div className="section-header">
              <img src="/images/lot-sense-oversight.png" alt="Optimizer icon" />
              <h2>Lot Sense Turn Optimizer</h2>
            </div>
            <div className="section-body">
              
              <div className="section-body-content">
                <h3>Data-Driven Pricing Decisions</h3>
                <p>Eliminate guesswork by making data-driven retail pricing decisions and continually adjusting your strategy as the market moves around you.</p>

                <h3>Forecast Every Outcome</h3>
                <p>Ensure an optimal balance between profit and turn by leveraging Lot Sense's predictive analytics to accurately forecast outcomes of your pricing strategies.</p>

                <h3>Limit Downside Risk</h3>
                <p>Identify strategic exit points to preserve profit margins and reduce losses by continuously measuring vehicle performance against projections in your region.</p>

                <h3>Profit Before the Purchase</h3>
                <p>Use Lot Sense market projections to make informed purchases by understanding the profit potential for a vehicle before it's ever on your lot.</p>
              </div>
              <video width="889" height="500" autoPlay playsInline muted loop>
                <source src="/videos/lot-sense-turn-optimizer.mp4" type="video/mp4" />
                <source src="/videos/lot-sense-turn-optimizer.webm" type="video/webm" />
              </video>
            </div>
          </div>


          <div className="lot-sense-feature-section">
            <div className="section-header">
              <img src="/images/lot-sense-competitors.png" alt="Regional Icon" />
              <h2>Regional Unit Comparisons</h2>
            </div>

            <div className="section-body">
              <div className="section-body-content">
                <h3>Hold vs Fold Decisions</h3>
                <p>Decide when to hold and when to fold by understanding regional supply and demand dynamics for retail and wholesale units in your region.</p>

                <h3>Detailed Unit Comparisons</h3>
                <p>Benchmark against similar units with real-time data on daily sales rates, days-to-turn, market day supply and strategic pricing adjustments.</p>

                <h3>Monitor Unit Performance</h3>
                <p>See a comprehensive overview of vehicle performance and price changes versus the retail and wholesale market throughout the lifecycle of a unit.</p>
              </div>
              <img src="/images/lot-sense-regional-screenshot.png" alt="Regional Unit Comparisons" />
            </div>
          </div>

          

          <div className="lot-sense-feature-section">
            <div className="section-header">
              <img src="/images/lot-sense-find.png" alt="Find Icon" />
              <h2>Automated Vehicle Management</h2>
            </div>
            <div className="section-body">
              
              <div className="section-body-content">
                <h3>Seamless Integration</h3>
                <p>Seamlessly integrate with your Dealership Management System (DMS), Inventory Management System (IMS) or website to automatically sync your inventory feed.</p>

                <h3>Unified Vehicle Overview</h3>
                <p>View all critical vehicle information in one place, including vehicle descriptions, statuses, alerts, pricing, market comparisons, ACV, costs, profit potential and price change history.</p>

                <h3>Centralized Price Management</h3>
                <p>Effortlessly monitor, assess, update and syndicate price changes across your dealership ecosystem to ensure maximum performance and minimum redundancy.</p>

                <h3>Vehicle Lifecycle Status</h3>
                <p>Use Lot Sense vehicle statuses to ensure you never lose track of a unit or waste time getting units front-line ready.</p>
              </div>
              <img src="/images/lot-sense-dashboard-screenshot.png" alt="Automated Vehicle Management" />
            </div>
          </div>


          <div className="lot-sense-feature-section">
            <div className="section-header">
              <img src="/images/lot-sense-alerts.png" alt="Alerts Icon" />
              <h2>Virtual Lot Walk</h2>
            </div>
            <div className="section-body">
              
              <div className="section-body-content">
                <h3>Proactive Notifications</h3>
                <p>Lot Sense provides proactive notifications to assess vehicles that need attention and recommends changes as the market fluctuates.</p>

                <h3>Customized Alerts</h3>
                <p>Define custom alert parameters to ensure that you're always staying ahead of the competition and keeping an eye on your aging inventory.</p>

                <h3>Cross-Platform Insights</h3>
                <p>With high-level performance metrics, vehicle alerts and quick access to all your inventory, Lot Sense ensures that you see all your dealership's critical metrics in one place - whether that's on desktop, mobile, or in the Chrome extension.</p>
              </div>
              <img src="/images/lot-sense-attention-screenshot.png" alt="Virtual Lot Walk" />
            </div>
          </div>



          <div className="lot-sense-feature-section" style={{borderBottom: 'none', marginBottom: '20px'}}>
            <div className="section-header">
              <img src="/images/lot-sense-pricing.png" alt="Analytics Icon" />
              <h2>Dealership Performance Analytics</h2>
            </div>
            <div className="section-body">
              
              <div className="section-body-content">
                <h3>Dealership Health Check</h3>
                <p>Monitor your dealership's health by measuring vehicle counts, total lot value, average days on lot, sales metrics and more in a single performance dashboard.</p>

                <h3>Custom Dashboards</h3>
                <p>Customize your dashboards to prioritize your dealership's key performance metrics and historical inventory trends.</p>

                <h3>Actionable Insights</h3>
                <p>Access actionable insights to enhance strategic decision-making and improve dealership performance.</p>

                <h3>Regional Benchmarks</h3>
                <p>Compare the performance of your dealership to other dealerships in your region to understand how you can stay ahead of your competition.</p>
              </div>
              <img src="/images/lot-sense-analytics-screenshot.png" alt="Analytics" />
            </div>
          </div>
        </div>

        {
          SessionStore.isAccountOwner() &&
          <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
          <Button style={{display: 'flex', alignItems: 'center'}} className="highlight-button"
            onClick={() => {
              this.setState({ isShowingUpsellModal: true })
            }}>
              <div>
                <PlusIcon style={{marginRight: '5px'}} /> Add {AppConstants.providerNames['my_lot']} to your Account
              </div>
            </Button>
          </div>
        }

        {
          !isDivergent && priceString &&
          <div style={{textAlign: 'center', marginBottom: '80px'}}>{ priceString }</div>
        }

        {
          isShowingUpsellModal &&
          <OfferingUpsellModal
            logoWidth={200}
            logoHeight={33}
            offering={{sku: AppConstants.skuMappings['my_lot'], provider_key: 'my_lot'}}
            onDone={() => {
                this.setState({ isShowingUpsellModal: false})
                if (this.props.onDone) {
                  this.props.onDone()
                }
              }
            }
            onPurchase={() => {
              this.setState({ isShowingUpsellModal: false })
              window.location.reload()
            }} />
        }

      </div>
    )
  }
}
