import React from 'react'

export default class TrendFlatIcon extends React.PureComponent {
  render() {
    const width = this.props.width || '18px'
    const height = this.props.height || '18px'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 16 16" fill={color} className={this.props.className} style={{...this.props.style}} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
      </svg>
    )
  }
}