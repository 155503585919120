import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import Formatters from '../../api/constants/Formatters'
import Spinner from './Spinner'
import platformFunctions from '../../platformFunctions'

export default class ChangeBillingIntervalModal extends React.PureComponent {
  state = {
    isChangingBillingInterval: false,
    isLoadingPriceForOfferings: true,
    isdoneUpdating: false,
    pricingForOfferings: null,
    yearlySavings: null,
    changeBillingIntervalError: null
  }

  componentDidMount() {
    SessionStore.on('billing_interval_changed', this.billingIntervalChanged)
    SessionStore.on("pricing_for_billing_interval_change_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("billing_interval_updated", this.billingIntervalChanged)
    
    UserActionCreators.loadPricingForBillingIntervalChange(null, 'year')
    platformFunctions.logAnalyticsEvent("User", "Opened Change Billing Interval Modal")
  }

  componentWillUnmount() {
    SessionStore.removeListener('billing_interval_changed', this.billingIntervalChanged)
    SessionStore.removeListener("pricing_for_billing_interval_change_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("billing_interval_updated", this.billingIntervalChanged)
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    this.setState({
      isLoadingPriceForOfferings: false,
      pricingForOfferings: pricingForOfferings,
      yearlySavings: pricingForOfferings?.yearly_discount
    })
  }  

  changeBillingInterval = () => {
    this.setState({
      isChangingBillingInterval: true
    })
    UserActionCreators.updateBillingInterval('year')
  }


  billingIntervalChanged = () => {
    const errors = SessionStore.yearlyBillingSwitchErrorMessage
    this.setState({
      isdoneUpdating: true,
      changeBillingIntervalError: errors
    })


    if (!errors) {
      setTimeout(() => {
        if (this.props.onComplete) {
          this.props.onComplete()
        }
      }, 3000)
    }
  }


  render() {
    const { isChangingBillingInterval, isLoadingPriceForOfferings, pricingForOfferings, isdoneUpdating, yearlySavings, changeBillingIntervalError } = this.state
    const { accountDetails } = this.props
    const isBillingIntervalYearly = accountDetails?.billing_interval === 'year'
    const isLoading = isLoadingPriceForOfferings || isChangingBillingInterval
    const isTrialing = SessionStore.isTrialing()

    return (
      <Modal size="lg" centered={true} isOpen={true} contentClassName='modal-c'>
        <ModalHeader className='modal-header'>Change Billing Period</ModalHeader>
        <ModalBody>
          {
            isLoading && !isdoneUpdating &&
            <Spinner />
          }

          {
            !isLoading && !isdoneUpdating &&
            <div>
              {
                yearlySavings &&
                <div>Save {Formatters.formatCurrencyCents(yearlySavings)} per year by changing to yearly billing</div>
              }
              <h2>
                Yearly price: {Formatters.formatCurrencyRoundedCents(pricingForOfferings.total_price)}
              </h2>
              <div className='secondary-text' style={{marginBottom: '10px'}}>Sales tax may apply</div>

              {
                !isTrialing &&
                <div>
                  {
                    Boolean(pricingForOfferings?.proration_amount) &&
                    <div style={{fontWeight: 'bold', color: 'red', marginTop: '20px'}}>You will be immediately charged {Formatters.formatCurrencyCents(pricingForOfferings?.proration_amount)} for 1 year of service, which includes a credit for your remaining monthly plan. We are not able to give refunds on yearly plans.</div>
                  }
                </div>
              }

              {
                isTrialing &&
                <div style={{fontWeight: 'bold', marginTop: '20px'}}>You will not be billed until your current free trial ends.</div>
              }

              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '20px 0 10px 0'}}>
                <Button color='primary' onClick={this.changeBillingInterval}>Switch to {isBillingIntervalYearly ? 'Monthly' : 'Yearly'}</Button>
              </div>
              
            </div>
          }

          {
            changeBillingIntervalError &&
            <h2 style={{color: 'red', margin: '20px 0'}}>{changeBillingIntervalError}</h2>
          }

          {
            isdoneUpdating && !changeBillingIntervalError &&
            <h2 style={{margin: '20px 0'}}>Switch to yearly billing successful</h2>
          }
        </ModalBody>

        <ModalFooter>
          <a href="#cancel" onClick={(e) => {
            e.preventDefault()
            this.props.onCancel()
          }}>Cancel</a>
        </ModalFooter>
      </Modal>
    )
  }

}