import React, { useEffect, useState, useCallback } from 'react'
import SessionStore from '../../api/stores/SessionStore'
import { Route, NavLink, Switch } from 'react-router-dom'
import HomeIcon from '../../components/common/icons/HomeIcon'
import GearIcon from '../../components/common/icons/GearIcon'
import AppConstants from '../../api/constants/AppConstants'
import ValuationShow from '../../components/valuations/ValuationShow'
import VehicleStateIcon from './VehicleStateIcon'
import useMyLotVehiclesDetails from '../../api/hooks/useMyLotVehiclesDetails'
import GraphIcon from '../../components/common/icons/GraphIcon'
import PlusIcon from '../../components/common/icons/PlusIcon'
import NewValuation from '../../components/valuations/NewValuation'
import ValuationStore from '../../api/stores/ValuationStore'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import AlarmIcon from '../../components/common/icons/AlarmIcon'
import usePolling from '../../api/hooks/usePolling'
import { alertVehiclesPollingInterval } from './util/constants'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import FullOnboarding from './FullOnboarding'
import Upsell from './Upsell'
import withScrollRestoration from '../../hoc/withScrollRestoration'
import DashboardPage from './Dashboard'
import AnalysisPage from './Analysis'
import CompetitorsPage from './Competitors'
import VehiclesByStatusPage from './VehiclesByStatus'
import SettingsPage from './Settings'
import MagnifyingGlassIcon from '../../components/common/icons/MagnifyingGlassIcon'
import LotVehiclesSearchModal from '../../components/myLot/LotVehiclesSearchModal'
import AccountNeedsAction from '../../components/user/AccountNeedsAction'

const Dashboard = withScrollRestoration(DashboardPage)
const Analysis = withScrollRestoration(AnalysisPage)
const Competitors = withScrollRestoration(CompetitorsPage)
const VehiclesByStatus = withScrollRestoration(VehiclesByStatusPage)
const Settings = withScrollRestoration(SettingsPage)

const MyLot = ({history}) => {
  const [isShowingNewValuationModal, setIsShowingNewValuationModal] = useState(false)
  const [isShowingSearchModal, setIsShowingSearchModal] = useState(false)
  const [{ alertSettings, onboarding }] = useMyLotSettings()

  const {
    getCountForState,
    load: loadMyLotVehiclesDetails,
  } = useMyLotVehiclesDetails()

  const valuationCreated = useCallback((newValuation) => {
    setIsShowingNewValuationModal(false)
    if (newValuation?.uuid) {
      ValuationStore.valuations.addToCollection(`new-valuation-${newValuation.uuid}`, newValuation)
      history.push(MyLotFunctions.myLotVehicleURL(newValuation))
    }
  }, [])

  const onVehicleChanged = useCallback(() => {
    loadMyLotVehiclesDetails()
  }, [loadMyLotVehiclesDetails])

  const onVehicleSnoozeChanged = useCallback(() => {
    loadMyLotVehiclesDetails()
  }, [loadMyLotVehiclesDetails])

  useEffect(() => {
    loadMyLotVehiclesDetails()
  }, [alertSettings])

  usePolling(loadMyLotVehiclesDetails, alertVehiclesPollingInterval)

  useEffect(() => {
    const vehicleChangedEv = 'lot_vehicle_updated'
    const vehicleSnoozedEv = 'lot_vehicle_snooze_updated'
    ValuationStore.on(vehicleChangedEv, onVehicleChanged)
    ValuationStore.on(vehicleSnoozedEv, onVehicleSnoozeChanged)

    return () => {
      ValuationStore.removeListener(vehicleChangedEv, onVehicleChanged)
      ValuationStore.removeListener(vehicleSnoozedEv, onVehicleSnoozeChanged)
    }
  }, [onVehicleChanged, onVehicleSnoozeChanged])

  if (!onboarding.apolloLinked && !onboarding.preferredLinked && !onboarding.lotVehiclesExist) {
    return ( <FullOnboarding /> )
  }

  return (
    <>
      <div style={{height: '100%'}}>

        <nav className="secondary-nav">
          <div className="secondary-nav-links">
            <section>
              <NavLink to="/lot-sense" exact activeClassName="active">
                <HomeIcon className="icon" color="currentColor" />
                Dashboard
              </NavLink>
              <NavLink to="/lot-sense/alerts" exact activeClassName="active">
                <AlarmIcon className="icon" color="currentColor" />
                Needing Attention
                <div style={{ marginLeft: 'auto' }}>
                  {getCountForState('having_alerts')}
                </div>
              </NavLink>
              <NavLink to="/lot-sense/analysis" exact activeClassName="active">
                <GraphIcon className="icon" color="currentColor" />
                Lot Analysis
              </NavLink>
              {/* <NavLink to="/lot-sense/competitors" exact activeClassName="active">
                <CompetitorsIcon className="icon" color="currentColor" />
                Competitors
              </NavLink> */}
              <NavLink to="/lot-sense/settings" exact activeClassName="active">
                <GearIcon className="icon" color="currentColor" />
                Settings
              </NavLink>
              <NavLink to="/lot-sense/new" exact activeClassName="active" onClick={(e) => {
                e.preventDefault()
                setIsShowingNewValuationModal(true)
              }}>
                <PlusIcon className="icon" color="currentColor" />
                Add vehicle to lot
              </NavLink>
            </section>

            <section style={{marginTop: '40px'}}>
              <div className="section-label">Vehicles by Status</div>
              {
                AppConstants.myLotStateValues.map((state, i) => (
                  <NavLink key={i} to={`/lot-sense/${state}`} exact activeClassName="active">
                    <VehicleStateIcon className="my-lot-nav-icon" vehicleState={state} />
                    {AppConstants.myLotStateNames[state]}
                    <div style={{ marginLeft: 'auto' }}>
                      {getCountForState(`state_${state}`)}
                    </div>
                  </NavLink>
                ))
              }
            </section>

            <section style={{marginTop: '40px'}}>
              <div className="section-label">Search</div>
              <a href="#search" id={`search-popover`} onClick={(e) => {
                e.preventDefault()
                setIsShowingSearchModal(true)
              }}>
                <MagnifyingGlassIcon className="icon" color="currentColor" />
                Search Inventory</a>
            </section>
          </div>
        </nav>


        <div className="page-body-c my-lot-body-c body-with-nav" style={{minHeight: '100%'}}>
          <Route
            exact
            path='/lot-sense'
            children={({ match }) => <Dashboard currentInventoryCount={getCountForState('state_on_lot')} navMatch={match} />}
          />
          <Route
            exact
            path='/lot-sense/alerts'
            children={() =>
              <VehiclesByStatus
                title='Needing Attention'
                havingAlerts
                defaultOrder={'last_alerted_at DESC'}
                count={getCountForState('having_alerts')}
                excludedSortOptions={['sold_at']}
              />
            }
          />
          <Route
            exact
            path='/lot-sense/analysis'
            children={() => <Analysis />}
          />
          <Route
            exact
            path='/lot-sense/competitors'
            children={() => <Competitors />}
          />
          <Route
            exact
            path='/lot-sense/settings'
            children={() => <Settings />}
          />
          {
            AppConstants.myLotStateValues.map((state, i) => (
              <Route
                key={i}
                exact
                path={`/lot-sense/${state}`}
                children={() =>
                  <VehiclesByStatus
                    title={AppConstants.myLotStateNames[state]}
                    status={state}
                    count={getCountForState(`state_${state}`)}
                    defaultOrder={state === 'sold' ? 'sold_at DESC' : undefined}
                    excludedSortOptions={state !== 'sold' ? ['sold_at', 'last_alerted_at'] : ['last_alerted_at']}
                  />
                }
              />
            ))
          }
          <Switch>
            <Route
              exact
              path='/lot-sense/vehicles/show/:id'
              children={({ match }) => (
                <div style={{margin: '-20px'}}>
                  <ValuationShow navMatch={match} />
                </div>
              )} />
          </Switch>
        </div>
      </div>

      {
        isShowingNewValuationModal &&
        <NewValuation
          onDone={valuationCreated}
          onCancel={() => setIsShowingNewValuationModal(false)}
          history={history}
          showDrilldown={false}
          defaultLotVehicleState='on_lot'
          useModal />
      }

      {
        isShowingSearchModal &&
        <LotVehiclesSearchModal onClose={() => setIsShowingSearchModal(false)} />
      }
    </>
  )
}

const Page = ({history}) => {
  return SessionStore.hasMyLotAccess() || !SessionStore.hasViewedMyLot() ?
    <MyLot history={history} /> :
    (
      SessionStore.isCanceled() ? <div className="page-body-c body-content" style={{ marginLeft: '80px' }}><AccountNeedsAction className='account-action-page-header' style={{marginBottom: '20px'}} /></div> : <Upsell />
    )
}

export default Page
