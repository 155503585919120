import { useEffect, useState, useCallback } from 'react'
import ValuationStore from '../stores/ValuationStore'
import ValuationActionCreators from '../actions/ValuationActionCreators'

const useMyLotReport = () => {
  const [
    {
      isLoading,
      data,
    },
    setState
  ] = useState({
    data: ValuationStore.myLotReport,
    isLoading: ValuationStore.isLoadingMyLotReport,
  })

  const onReportChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.myLotReport,
    }))
  }, [])

  const onIsLoadingChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoading: ValuationStore.isLoadingMyLotReport,
    }))
  }, [])

  const load = useCallback(() => {
    ValuationActionCreators.loadMyLotReport()
  }, [])

  const onVehicleChanged = useCallback(() => {
    load()
  }, [load])

  useEffect(() => {
    const ev = 'my_lot_report_change'
    const loadingEv = 'is_loading_my_lot_report_change'
    const vehicleChangedEv = 'lot_vehicle_updated'
    const vehicleDeletedEv = 'valuation_deleted'
    const vehicleCreatedEv = 'lot_vehicle_created'

    ValuationStore.on(ev, onReportChange)
    ValuationStore.on(loadingEv, onIsLoadingChange)
    ValuationStore.on(vehicleChangedEv, onVehicleChanged)
    ValuationStore.on(vehicleDeletedEv, onVehicleChanged)
    ValuationStore.on(vehicleCreatedEv, onVehicleChanged)

    return () => {
      ValuationStore.removeListener(ev, onReportChange)
      ValuationStore.removeListener(loadingEv, onIsLoadingChange)
      ValuationStore.removeListener(vehicleChangedEv, onVehicleChanged)
      ValuationStore.removeListener(vehicleDeletedEv, onVehicleChanged)
      ValuationStore.removeListener(vehicleCreatedEv, onVehicleChanged)
    }
  }, [onReportChange, onIsLoadingChange, onVehicleChanged])

  useEffect(() => {
    load()
  }, [load])

  return {
    isLoading,
    data,
  }
}

export default useMyLotReport
