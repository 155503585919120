import React from 'react'
import {  Button, Modal, ModalHeader, ModalBody, Input, Tooltip } from 'reactstrap'
import MagnifyingGlassIcon from '../common/icons/MagnifyingGlassIcon'
import PlusIcon from '../common/icons/PlusIcon'
import OfferingUpsellModal from '../common/OfferingUpsellModal'
import AppConstants from '../../api/constants/AppConstants'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import platformFunctions from '../../platformFunctions'
import Formatters from '../../api/constants/Formatters'
import AccountReactivation from '../user/AccountReactivation'


export default class AuctionsPlusUpsell extends React.Component {
  state = {
    isShowingUpsellModal: false,
    costForSelected: null,
    accountDetails: null,
    pricingForOfferingsErrors: null
  }

  componentDidMount() {
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("account_reactivation_received", this.accountReactivationReceived)


    platformFunctions.logAnalyticsEvent("User", `Opened ${AppConstants.providerNames.auctions_plus} Upsell Page`)
    UserActionCreators.createMetric(AppConstants.metricNames['auctions_plus_upsell'], this.props.metricsValue)

    UserActionCreators.loadAccountDetails()
  }

  componentWillUnmount() {
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("account_reactivation_received", this.accountReactivationReceived)
  }

  accountDetailsReceived = () => {
    const accountDetails = SessionStore.accountDetails
    this.setState({
      accountDetails: accountDetails
    })

    const isDivergent = accountDetails?.divergent_offerings === true
    if (!isDivergent && !SessionStore.isCanceled()) {
      UserActionCreators.loadPricingForOfferings([AppConstants.skuMappings.auctions_plus])
    }
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    const pricingForOfferingsErrors = SessionStore.pricingForOfferingsErrors

    this.setState({
      costForSelected: pricingForOfferings,
      pricingForOfferingsErrors: pricingForOfferingsErrors
    })
  }

  accountReactivationReceived = () => {
    this.setState({
      costForSelected: null,
      accountDetails: null,
    })
    UserActionCreators.loadAccountDetails()
  }

  render() {
    const { isShowingUpsellModal, costForSelected, accountDetails } = this.state
    const isDivergent = accountDetails?.divergent_offerings === true
    const isMonthly = accountDetails?.billing_interval === 'month'
    const priceString = costForSelected ? `${Formatters.formatCurrencyCents(costForSelected?.incremental_price)} per ${isMonthly ? 'month' : 'year'}` : ''

    let nonAuxUsersCount = 0
    if (accountDetails?.user_details) {
      nonAuxUsersCount = accountDetails?.user_details.filter((item) => item.auxiliary === false).length
    }

    if (SessionStore.isCanceled()) {
      return (
        <div>
          <AccountReactivation />
          {
            this.props.onCancel &&
            <div style={{textAlign: 'right', marginTop: '20px'}}>
              <a href="#cancel"
                onClick={(e) => {
                e.preventDefault()
                this.props.onCancel()
              }}>Cancel</a>
            </div>
          }
        </div>
        
      )
    }

    return (
      <>
        <div className='split-row'>
          {
            SessionStore.isAccountOwner() ?
            <Button style={{display: 'flex', alignItems: 'center'}} className="highlight-button"
              onClick={() => {
                this.setState({ isShowingUpsellModal: true })
              }}>
                <PlusIcon style={{marginRight: '5px'}} /> Add Auctions Plus to your Account</Button>:
              <div />
          }
          <img src="/images/auctions-plus-logo.png" alt="Auctions Plus" style={{width: '160px', height: '27px'}} />
        </div>
        
        <h2 style={{marginBottom: '20px'}}>Auctions Plus gives you</h2>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', border: '1px solid #ddd', borderRadius: '10px', padding: '10px', backgroundColor: '#fff'}}>
          <div style={{width: '70px', flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#aaa" viewBox="0 0 16 16">
              <path d="M5.68 5.792 7.345 7.75 5.681 9.708a2.75 2.75 0 1 1 0-3.916ZM8 6.978 6.416 5.113l-.014-.015a3.75 3.75 0 1 0 0 5.304l.014-.015L8 8.522l1.584 1.865.014.015a3.75 3.75 0 1 0 0-5.304l-.014.015L8 6.978Zm.656.772 1.663-1.958a2.75 2.75 0 1 1 0 3.916L8.656 7.75Z"/>
            </svg>
          </div>
          <div style={{marginLeft: '20px'}}>
            <h2 style={{marginTop: 0}}>Unlimited Saved Searches</h2>
            <div>Create as many auction saved searches as you wish. Save valuable time by setting up searches for specific types of vehicles, price ranges, ages, mileages, and so much more...</div>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', border: '1px solid #ddd', borderRadius: '10px', padding: '10px', backgroundColor: '#fff'}}>
          <div style={{width: '70px', flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#aaa" viewBox="0 0 16 16">
              <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
            </svg>
          </div>
          <div style={{marginLeft: '20px'}}>
            <h2 style={{marginTop: 0}}>Search by Profit and Price</h2>
            <div>Quickly find vehicles that are priced right for your lot or try to maximize profit by limiting vehicles to certain price ranges.  Improve your profit margins by looking for auction listings near you that have the highest spread between wholesale and retail price estimates.</div>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', border: '1px solid #ddd', borderRadius: '10px', padding: '10px', backgroundColor: '#fff'}}>
          <div style={{width: '70px', flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>
            <MagnifyingGlassIcon width={44} height={44} color="#aaa" />
          </div>
          <div style={{marginLeft: '20px'}}>
            <h2 style={{marginTop: 0}}>Find the Perfect Vehicles for Your Lot</h2>
            <div>Year, make, and model are just the start.  Narrow down your search by trim, mileage, CR score, interior and exterior color, drivetrain, fuel type, buy it now price, and much more.</div>

            <div style={{marginTop: '10px'}}>Look beyond your usual auctions by searching by distance instead of location.  Great for finding gems you might otherwise miss.</div>

            <div style={{marginTop: '10px'}}>Manheim, ADESA, EDGE Pipeline, OVE, SmartAuction, eBlock, Auction Central, Manheim Express, and EDGE.Auction run lists.</div>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px', border: '1px solid #ddd', borderRadius: '10px', padding: '10px', backgroundColor: '#fff'}}>
          <div style={{width: '70px', flexGrow: 0, flexShrink: 0, textAlign: 'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="#aaa" viewBox="0 0 16 16">
              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
            </svg>
          </div>
          <div style={{marginLeft: '20px'}}>
            <h2 style={{marginTop: 0}}>Team Searches</h2>
            <div>Have other users on your Carbly account?  With Auctions Plus, you can view saved searches from everyone on the team. Work together to find the most profitable vehicles for your dealership.</div>
          </div>
        </div>


        <div style={{height: '60px'}}>
          {
            SessionStore.isAccountOwner() && accountDetails && !isDivergent && priceString &&
            <div style={{textAlign: 'center'}}>
            <div style={{fontSize: '1.3rem', textAlign: 'center', borderBottom: '2px solid purple', margin: '0 auto', display: 'inline-block'}}>Only <span style={{fontWeight: 'bold'}}>{priceString}</span></div>
            </div>
          }
          {
            nonAuxUsersCount > 1 &&
            <div style={{textAlign: 'center'}}>Includes access for {nonAuxUsersCount} users on your account</div>
          }
        </div>

        {
          isDivergent &&
          <div style={{color: 'red', textAlign: 'center'}}>Your account has users with varying options.  If you'd like to add Auctions Plus, please give us a call at {AppConstants.phoneNumber} and we'd be happy to help.</div>
        }

        {
          !isDivergent &&
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '40px'}}>
            {
              !SessionStore.isAccountOwner() &&
              <div style={{fontWeight: 'bold', backgroundColor: '#eee', padding: '5px 10px', borderRadius: '4px'}}>Contact your account owner to add {AppConstants.providerNames.auctions_plus}</div>
            }

            {
              SessionStore.isAccountOwner() &&
              <Button style={{display: 'flex', alignItems: 'center'}} className="highlight-button"
                onClick={() => {
                  this.setState({ isShowingUpsellModal: true })
                }}>
                  <PlusIcon style={{marginRight: '5px'}} /> Add Auctions Plus to your Account</Button>
            }
            
            {
              this.props.onCancel &&
              <a href="#cancel" onClick={(e) => {
                e.preventDefault()
                this.props.onCancel()
              }}>Cancel</a>
            }
          </div>
        }

        {
          isDivergent && this.props.onCancel &&
          <div style={{margin: '20px auto', textAlign: 'center'}}>
            <a href="#cancel" onClick={(e) => {
              e.preventDefault()
              this.props.onCancel()
            }}>Cancel</a>
          </div>
        }

        {
          isShowingUpsellModal &&
          <OfferingUpsellModal
            logoWidth={200}
            logoHeight={33}
            offering={{sku: AppConstants.skuMappings['auctions_plus'], provider_key: 'auctions_plus'}}
            onDone={() => {
                this.setState({ isShowingUpsellModal: false})
                if (this.props.onDone) {
                  this.props.onDone()
                }
              }
            }
            onPurchase={() => {
              this.setState({ isShowingUpsellModal: false })
              window.location.reload()
            }} />
        }
      </>
    )
  }
}







