import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'
import pluralize from 'pluralize'
import TrendUpIcon from '../common/icons/TrendUpIcon'
import TrendFlatIcon from '../common/icons/TrendFlatIcon'
import TrendDownIcon from '../common/icons/TrendDownIcon'
import ListingsListWithHeader from './ListingsListWithHeader'
import pick from 'lodash/pick'
import Formatters from '../../api/constants/Formatters'

const scorecardMetricTypes = [
  'section_title_inventory',
  'inventory',
  'market_days_supply',
  'wholesale_inventory',
  'section_title_sold_units',
  'days_on_lot',
  'sold_per_day',
  'price_change_portion',
]

const Scorecard = ({ valuation, className }) => {
  const {
    year,
    make,
    model,
    series: trim,
    zip_code: zip,
  } = valuation

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({ year, make, model, trim, zip })
  }, [zip, year, make, model, trim])

  const [
    {
      isLoading,
      data: scorecard,
    },
    setState,
  ] = useState({
    isLoading: ValuationStore.getRegionalModelScorecardIsLoadingWithClientID(clientID),
    data: ValuationStore.getRegionalModelScorecardWithClientID(clientID),
  })

  const regionalListingsParams = useMemo(() => {
    return scorecard ? pick(scorecard, ['year', 'make', 'model', 'trim', 'zip', 'radius']) : null
  }, [scorecard])

  const onDataChanged = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.getRegionalModelScorecardWithClientID(clientID),
    }))
  }, [clientID])

  const onIsLoadingChanged = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: ValuationStore.getRegionalModelScorecardIsLoadingWithClientID(clientID) }))
  }, [clientID])

  useLayoutEffect(() => {
    const loadingEv = `regional_model_scorecard_is_loading_change_${clientID}`
    const dataEv = `regional_model_scorecard_change_${clientID}`

    ValuationStore.on(dataEv, onDataChanged)
    ValuationStore.on(loadingEv, onIsLoadingChanged)

    return () => {
      ValuationStore.removeListener(dataEv, onDataChanged)
      ValuationStore.removeListener(loadingEv, onIsLoadingChanged)
    }
  }, [onDataChanged, onIsLoadingChanged, clientID])

  useLayoutEffect(() => {
    if (!scorecard) {
      ValuationActionCreators.loadRegionalModelScorecard({ year, make, model, trim, zip, clientID })
    }
  }, [zip, year, make, model, trim, clientID, scorecard])

  if (isLoading) return <Spinner />

  return (
    <div className={className}>
      <div className='split-row split-row-left scorecard-c'>
        <div className='my-lot-regional-metrics'>
          {
            // FIXME: Handle no scorecard data (i.e. no make/model stats in query)
            !scorecard ?
            <div>No data available</div> :
            scorecardMetricTypes.map(key => {
              if (key === 'section_title_inventory') {
                return <h2 key={key} className='subsection-title'>Local Inventory</h2>
              } else if (key === 'section_title_sold_units') {
                return <h2 key={key} className='subsection-title' style={{marginTop: '10px'}}>Sold Unit Inventory</h2>
              }

              const data = scorecard[key]
              // FIXME Handle missing data
              if (data == null) return null
              let levelBound
              let levelIndicator
              let trendText
              let trendIcon
              let title
              let currentValueText
              let levelName
              let level = data.level
              let trend = data.trend

              // FIXME: Handle insufficient data
              const insufficientLevelDataText = 'Low data'

              switch (key) {
                case 'inventory':
                  title = 'Retail'
                  currentValueText = `${data.current} within ${pluralize('mile', scorecard.radius, true)}`
                  levelName = 'supply'
                  break
                case 'wholesale_inventory':
                  title = 'Wholesale'
                  currentValueText = [data.current, 'recently within 100 miles'].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'supply'
                  break
                case 'days_on_lot':
                  title = 'Days to Turn'
                  currentValueText = data.current == null ? 'N/A' : [Formatters.formatNumber(data.current, {maximumFractionDigits: 0}), pluralize('day', data.current, false)].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'turn'
                  break
                case 'sold_per_day':
                  title = 'Daily Sales Rate'
                  currentValueText = data.current == null ? 'N/A' : [Formatters.formatNumber(data.current, {maximumFractionDigits: 1}), 'per day'].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'rate'
                  break
                case 'price_change_portion':
                  title = 'Price Change Percent'
                  currentValueText = data.current == null ? 'N/A' : [
                    Formatters.formatNumber(Math.abs(data.current) * 100, {maximumFractionDigits: 1}),
                    '% ',
                    data.current > 0 ? 'increase' : 'reduction',
                  ].join('')
                  levelName = data.level == null ? insufficientLevelDataText : 'percent'
                  trend = (function() {
                    if (data.current == null) return 0
                    if (data.current == 0) return 0
                    if (data.current < 0) return trend * -1
                    return trend
                  })()
                  break
                case 'market_days_supply':
                  title = 'Market Day Supply'
                  currentValueText = data.current == null ? 'N/A' : [Formatters.formatNumber(data.current, {maximumFractionDigits: 0}), pluralize('day', data.current, false)].join(' ')
                  levelName = data.level == null ? insufficientLevelDataText : 'supply'
                  break
                default:
                  throw new Error(`Unknown scorecard metric type: ${key}`)
              }

              switch (level) {
                case -1:
                  levelBound = 'Low'
                  levelIndicator = '▼'
                  break
                case 0:
                  levelBound = 'Normal'
                  levelIndicator = '-'
                  break
                case 1:
                  levelBound = 'High'
                  levelIndicator = '▲'
                  break
              }

              switch (trend) {
                case -1:
                  trendText = 'Trending down'
                  trendIcon = <TrendDownIcon />
                  break
                case 0:
                  trendText = 'Trending flat'
                  trendIcon = <TrendFlatIcon />
                  break
                case 1:
                  trendText = 'Trending up'
                  trendIcon = <TrendUpIcon />
                  break
              }

              return (
                <section key={key}>
                  <div className='split-row'>
                    <div className='section-label'>{title}</div>
                  </div>
                  <h2>{currentValueText}</h2>
                  <div className='split-row'>
                    <div><span className='indicator'>{levelIndicator}</span> {levelBound} {levelName}</div>
                    <div>{trendText} <span className='indicator'>{trendIcon}</span></div>
                  </div>
                </section>
              )
            })
          }
        </div>

        <div className='my-lot-regional-comps'>
          {
            regionalListingsParams &&
            <ListingsListWithHeader regionalListingsParams={regionalListingsParams} showYMMT={false} />
          }
        </div>
      </div>
    </div>
    )
  }

export default Scorecard
