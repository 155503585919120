import Formatters from '../../../api/constants/Formatters'
import CommonFunctions from '../../../api/constants/CommonFunctions'
import pluralize from 'pluralize'
import get from 'lodash/get'

const secondTrendLineProps = {
  borderColor: 'rgba(233, 86, 86, 1)',
  backgroundColor: 'rgba(233, 86, 86, 1)',
  hoverBackgroundColor: 'rgba(233, 86, 86, 1)',
  pointHoverBackgroundColor: 'rgba(233, 86, 86, 1)',
  pointHoverBorderColor: 'rgba(233, 86, 86, 1)',
  pointBorderColor: 'rgba(0, 0, 0, 0)',
  fill: {
    above: 'rgba(233, 86, 86, 0.1)',
    target: 'origin',
  },
}

const dolColors = ['#7ead42', '#a5d46a', '#ffdf80', '#ffc080', '#ffa080']
const valueNA = 'N/A'

const componentProps = ((allData, type, options = {}) => {
  let data = get(allData, type)

  switch (type) {
    case 'lot_analysis.groups.days_on_lot.num_on_lot':
    case 'lot_analysis.groups.days_on_lot.num_sold':
    case 'lot_analysis.groups.days_on_lot.sales_rate':
    case 'lot_analysis.groups.days_on_lot.retail_value':
    case 'lot_analysis.groups.body_style.num_on_lot':
    case 'lot_analysis.groups.body_style.num_sold':
    case 'lot_analysis.groups.body_style.avg_days_on_lot_sold':
    case 'lot_analysis.groups.body_style.avg_days_on_lot':
    case 'lot_analysis.groups.body_style.sales_rate':
    case 'lot_analysis.groups.body_style.retail_value':
    case 'lot_analysis.groups.make.num_on_lot':
    case 'lot_analysis.groups.make.num_sold':
    case 'lot_analysis.groups.make.avg_days_on_lot':
    case 'lot_analysis.groups.make.avg_days_on_lot_sold':
    case 'lot_analysis.groups.make.sales_rate':
    case 'lot_analysis.groups.make.retail_value':
    case 'lot_analysis.groups.price.num_on_lot':
    case 'lot_analysis.groups.price.num_sold':
    case 'lot_analysis.groups.price.avg_days_on_lot':
    case 'lot_analysis.groups.price.avg_days_on_lot_sold':
    case 'lot_analysis.groups.price.sales_rate': {
      const splitType = type.split('.')
      const yAxisKey = splitType.pop()
      const typeKey = splitType[splitType.length - 1]
      const dataKey = splitType.join('.')
      const xAxisKey = 'category'
      const idsKey = `${yAxisKey}_ids`
      data = get(allData, dataKey)

      let colors
      let yTooltipLabelFormatter
      let tooltipTitleFormatter
      let yAxisFormatter = Formatters.formatNumber
      let values

      switch (yAxisKey) {
        case 'avg_days_on_lot':
        case 'avg_days_on_lot_sold':
          yTooltipLabelFormatter = v => v == null ? valueNA : `${Formatters.formatNumber(v, { maximumFractionDigits: 1, minimumFractionDigits: 1 })} days on lot`
          break
        case 'sales_rate':
          yTooltipLabelFormatter = v => v == null ? valueNA : Formatters.formatNumber(v * 100, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%'
          yAxisFormatter = v => v == null ? valueNA : Formatters.formatNumber(v * 100) + '%'
          break
        case 'retail_value':
          yTooltipLabelFormatter = v => v == null ? valueNA : Formatters.formatAbbreviatedNumberCurrency(v)
          yAxisFormatter = v => v == null ? valueNA : Formatters.formatAbbreviatedNumberCurrency(v)
          break
        case 'num_on_lot':
          yTooltipLabelFormatter = v => v == null ? valueNA : `${Formatters.formatNumber(v)} ${pluralize('vehicle', v, false)}`
          break
        case 'num_sold':
          yTooltipLabelFormatter = v => v == null ? valueNA : `${Formatters.formatNumber(v)} ${pluralize('vehicle', v, false)} sold`
          break
        default:
          yTooltipLabelFormatter = v => v == null ? valueNA : Formatters.formatNumber(v)
      }

      switch (typeKey) {
        case 'days_on_lot':
          colors = dolColors
          values = data.sort((a, b) => a[xAxisKey][0] - b[xAxisKey][0])
          .map(d => {
            const v = d[xAxisKey]
            const x = [v[0], v[1] != null ? `-${v[1]}` : '+'].join('')
            return {
              x,
              y: d[yAxisKey],
              ids: d[idsKey],
            }
          })
          tooltipTitleFormatter = v => `${v} days on lot`
          break
        case 'price':
          values = data.sort((a, b) => a[xAxisKey][0] - b[xAxisKey][0])
          .map(d => {
            const v = d[xAxisKey]
            const x = [Formatters.formatAbbreviatedNumberCurrency(v[0]), v[1] != null ? `-${Formatters.formatAbbreviatedNumberCurrency(v[1])}` : '+'].join('')
            return {
              x: x,
              y: d[yAxisKey],
              ids: d[idsKey],
            }
          })
          break
        default:
          values = data.sort((a, b) => b[yAxisKey] - a[yAxisKey] || a[xAxisKey].localeCompare(b[xAxisKey]))
          .map(d => {
            return {
              x: d[xAxisKey],
              y: d[yAxisKey],
              ids: d[idsKey],
            }
          })
      }

      return {
        getVehiclesListProps: el => {
          const ids = el.raw.ids
          if (!ids) return null
          return {
            trackClickMetrics: true,
            listComponentType: 'lot',
            title: 'Included Vehicles',
            defaultOrder: 'days_on_lot DESC',
            query: {
              id_in: ids,
            },
          }
        },
        chartProps: {
          options: {
            elements: {
              bar: {
                backgroundColor: colors,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  title: tooltipTitleFormatter ? items => tooltipTitleFormatter(items[0].label) : undefined,
                  label: ctx => yTooltipLabelFormatter(ctx.parsed.y),
                },
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: yAxisFormatter,
                },
              },
            },
          },
          data: {
            datasets: [
              {
                categoryPercentage: 1,
                data: values,
              },
            ],
          },
        },
      }
    }

    case 'lot_analysis.trends.avg_days_on_lot':
    case 'lot_analysis.trends.avg_days_on_lot_sold':
    case 'lot_analysis.trends.num_on_lot':
    case 'lot_analysis.trends.num_sold':
    case 'lot_analysis.trends.sales_rate':
    case 'lot_analysis.trends.retail_value': {
      const splitType = type.split('.')
      const yAxisKey = splitType.pop()
      const xAxisKey = 'date'
      const idsKey = `${yAxisKey}_ids`
      const dataKey = splitType.join('.')
      const xFormatFunction = v => Formatters.formatMonthDayDate(v)
      let yFormatFunction
      let yTooltipLabelFormatter

      data = get(allData, dataKey).map(d => {
        return {
          x: d[xAxisKey],
          y: d[yAxisKey],
          ids: d[idsKey],
        }
      })

      switch (yAxisKey) {
        case 'avg_days_on_lot':
        case 'avg_days_on_lot_sold':
          yFormatFunction = v => v == null ? valueNA : Formatters.formatNumber(v, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
          yTooltipLabelFormatter = v => v == null ? valueNA : Formatters.formatNumber(v, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + ' days on lot'
          break
        case 'sales_rate':
          yFormatFunction = v => v == null ? valueNA : Formatters.formatNumber(v * 100, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%'
          yTooltipLabelFormatter = yFormatFunction
          break
        case 'retail_value':
          yFormatFunction = v => v == null ? valueNA : Formatters.formatAbbreviatedNumberCurrency(v, 2, 2)
          yTooltipLabelFormatter = yFormatFunction
          break
        case 'num_on_lot':
          yFormatFunction = v => v == null ? valueNA : Formatters.formatNumber(v)
          yTooltipLabelFormatter = v => v == null ? valueNA : `${Formatters.formatNumber(v)} ${pluralize('vehicle', v, false)}`
          break
        case 'num_sold':
          yFormatFunction = v => v == null ? valueNA : Formatters.formatNumber(v)
          yTooltipLabelFormatter = v => v == null ? valueNA : `${Formatters.formatNumber(v)} ${pluralize('vehicle', v, false)} sold`
          break
        default:
          yFormatFunction = v => v == null ? valueNA : Formatters.formatNumber(v)
          yTooltipLabelFormatter = yFormatFunction
      }

      return {
        getVehiclesListProps: el => {
          const ids = el.raw.ids
          if (!ids) return null
          return {
            trackClickMetrics: true,
            listComponentType: 'lot',
            title: 'Included Vehicles',
            defaultOrder: 'days_on_lot DESC',
            query: {
              id_in: ids,
            },
          }
        },
        chartProps: {
          height: options.height,
          options: {
            plugins: {
              datalabels: {
                formatter: v => yFormatFunction(v.y)
              },
              tooltip: {
                displayColors: false,
                callbacks: {
                  title: items => xFormatFunction(items[0].label),
                  label: ctx => yTooltipLabelFormatter(ctx.parsed.y),
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function(value) {
                    return xFormatFunction(this.getLabelForValue(value))
                  },
                },
              },
            },
          },
          data: {
            datasets: [
              {
                data,
              },
            ],
          },
        },
      }
    }

    case 'lot_analysis.snapshots.avg_days_on_lot':
    case 'lot_analysis.snapshots.avg_days_on_lot_sold':
    case 'lot_analysis.snapshots.num_on_lot':
    case 'lot_analysis.snapshots.sales_rate':
    case 'lot_analysis.snapshots.num_sold':
    case 'lot_analysis.snapshots.retail_value': {
      const splitType = type.split('.')
      const key = splitType.pop()
      const dataKey = splitType.join('.')

      data = get(allData, dataKey)
      const current = data[1][key]
      const previous = data[0][key]
      const diff = current == null || previous == null ? null : current - previous
      let currentFormatter = v => Formatters.formatNumber(v)
      let roundFunction = v => CommonFunctions.round(v)
      let diffFormatter

      switch (key) {
        case 'avg_days_on_lot':
        case 'avg_days_on_lot_sold':
          roundFunction = v => CommonFunctions.round(v, 1)
          currentFormatter = v => Formatters.formatNumber(v, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
          break
        case 'sales_rate':
          roundFunction = v => CommonFunctions.round(v * 100, 1)
          currentFormatter = v => Formatters.formatNumber(v * 100, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%'
          break
        case 'retail_value':
          currentFormatter = v => Formatters.formatAbbreviatedNumberCurrency(v, 2)
          diffFormatter = v => Formatters.formatCurrency(v)
          break
      }

      diffFormatter = diffFormatter || currentFormatter
      const roundedDiff = diff == null ? null : roundFunction(diff)

      let deltaMessage = ''
      let direction = ''
      if (roundedDiff === null) {
        deltaMessage = ''
        direction = null
      } else if (roundedDiff > 0) {
        deltaMessage = `Up ${diffFormatter(Math.abs(diff))}`
        direction = 'up'
      } else if (roundedDiff < 0) {
        deltaMessage = `Down ${diffFormatter(Math.abs(diff))}`
        direction = 'down'
      } else {
        deltaMessage = 'Flat'
        direction = 'flat'
      }

      const formattedCurrent = current == null ? valueNA : currentFormatter(current)

      return {
        formattedCurrent,
        deltaMessage,
        current,
        direction,
      }
    }

    case 'rankings_trend': {
      const sorted = data.sort((a, b) => a.date.localeCompare(b.date))
      const averageDaysOnLotValues = sorted.map(d => d.avg_days_on_lot.rank)
      const inventoryValues = sorted.map(d => d.inventory.rank)
      const inventoryValueValues = sorted.map(d => d.inventory_value.rank)
      const numSoldValues = sorted.map(d => d.num_sold.rank)
      const yFormatFunction = Formatters.formatNumber

      return {
        chartProps: {
          options: {
            plugins: {
              datalabels: {
                formatter: yFormatFunction,
              },
              tooltip: {
                displayColors: true,
                callbacks: {
                  label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
                },
              },
            },
            scales: {
              y: {
                min: Math.min(...averageDaysOnLotValues, ...inventoryValues, ...inventoryValueValues, ...numSoldValues) * 0.75,
              },
            },
          },
          data: {
            labels: sorted.map(d => Formatters.formatMonthDayDate(d.date)),
            datasets: [
              {
                label: 'Days on lot',
                data: averageDaysOnLotValues,
                borderColor: 'rgba(44, 110, 142, 1)',
                backgroundColor: 'rgba(44, 110, 142, 1)',
                hoverBackgroundColor: 'rgba(44, 110, 142, 1)',
                pointHoverBackgroundColor: 'rgba(44, 110, 142, 1)',
                pointHoverBorderColor: 'rgba(44, 110, 142, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
              {
                label: 'Inventory levels',
                data: inventoryValues,
                borderColor: 'rgba(131, 87, 125, 1)',
                backgroundColor: 'rgba(131, 87, 125, 1)',
                hoverBackgroundColor: 'rgba(131, 87, 125, 1)',
                pointHoverBackgroundColor: 'rgba(131, 87, 125, 1)',
                pointHoverBorderColor: 'rgba(131, 87, 125, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
              {
                label: 'Inventory value',
                data: inventoryValueValues,
                borderColor: 'rgba(135, 92, 61, 1)',
                backgroundColor: 'rgba(135, 92, 61, 1)',
                hoverBackgroundColor: 'rgba(135, 92, 61, 1)',
                pointHoverBackgroundColor: 'rgba(135, 92, 61, 1)',
                pointHoverBorderColor: 'rgba(135, 92, 61, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
              {
                label: 'Vehicles sold',
                data: numSoldValues,
                borderColor: 'rgba(63, 112, 77, 1)',
                backgroundColor: 'rgba(63, 112, 77, 1)',
                hoverBackgroundColor: 'rgba(63, 112, 77, 1)',
                pointHoverBackgroundColor: 'rgba(63, 112, 77, 1)',
                pointHoverBorderColor: 'rgba(63, 112, 77, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
            ],
          },
        },
      }
    }

    case 'region.avg_days_on_lot_trend': {
      const values = data.map(d => d.value)
      const lotValues = get(allData, 'lot_analysis.trends').map(d => d.avg_days_on_lot_sold)
      const yFormatFunction = v => Formatters.formatNumber(v, { maximumFractionDigits: 1, minimumFractionDigits: 1 })

      return {
        chartProps: {
          options: {
            plugins: {
              legend: {
                display: true,
              },
              datalabels: {
                formatter: v => yFormatFunction(v),
              },
              tooltip: {
                displayColors: true,
                callbacks: {
                  label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
                },
              },
            },
            scales: {
              y: {
                min: Math.min(...values, ...lotValues) * 0.75,
              },
            },
          },
          data: {
            labels: data.map(d => Formatters.formatMonthDayDate(d.date)),
            datasets: [
              {
                data: values,
                label: 'Region',
              },
              {
                label: 'Lot',
                data: lotValues,
                ...secondTrendLineProps,
              },
            ],
          },
        },
      }
    }

    case 'region.inventory_levels_by_body_style': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        chartProps: {
          options: {
            parsing: {
              yAxisKey: 'value',
              xAxisKey: 'category',
            },
          },
          data: {
            datasets: [
              {
                categoryPercentage: 1,
                data: sortedData,
              },
            ],
          },
        },
        getVehiclesListProps: el => {
          return {
            listComponentType: 'regional',
            title: `${el.raw.category}s In Your Region`,
            regionalListingsParams: {
              zip: allData.meta.zip,
              radius: allData.meta.radius,
              end_date: allData.meta.end_date,
              bodyStyle: el.raw.category,
            },
          }
        },
      }
    }

    case 'region.inventory_levels_by_make': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        chartProps: {
          options: {
            parsing: {
              yAxisKey: 'value',
              xAxisKey: 'category',
            },
          },
          data: {
            datasets: [
              {
                categoryPercentage: 1,
                data: sortedData,
              },
            ],
          },
        },
        getVehiclesListProps: el => {
          return {
            listComponentType: 'regional',
            title: `${el.raw.category} In Your Region`,
            regionalListingsParams: {
              zip: allData.meta.zip,
              radius: allData.meta.radius,
              end_date: allData.meta.end_date,
              make: el.raw.category,
            },
          }
        },
      }
    }

    default:
      return data
  }
})

export default componentProps
