import { useMemo, useCallback, useRef } from 'react'
import pluralize from 'pluralize'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import VehiclesListContainer from "../../pages/myLot/VehiclesListContainer"
import MyLotRow from '../../pages/myLot/ListingRow'
import Formatters from '../../api/constants/Formatters'

const renderRow = ({ listing }) => (
  <MyLotRow
    key={listing.uuid}
    listing={listing}
  />
)

const LotHistoryModal = ({ onClose, year, make, model, trim, modelStats }) => {
  const ref = useRef(null)

  const getScrollParent = useCallback(() => {
    return ref.current
  }, [])

  const lotHistoryListQuery = useMemo(() => {
    return { state: 'sold', year, make, model, trim }
  }, [year, make, model, trim])

  // FIXME: For pagination, moved scroll to parent, so footer close button is not visible

  return (
    <Modal isOpen innerRef={ref} centered toggle={onClose} backdropClassName='modal-bg' contentClassName='modal-c' style={{ minWidth: 1200 }}>
      <ModalHeader className='modal-header'>
        Lot History
      </ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div className='lot-history-modal-metrics'>
            {
              modelStats.num_sold > 1 &&
              <div>
                {pluralize('average day', modelStats.avg_days_on_lot_sold.toFixed(1), true)} on lot
              </div>
            }

            {
              modelStats.avg_profit_sold != null &&
              <div>
                {Formatters.formatCurrency(modelStats.avg_profit_sold)} average profit
              </div>
            }

            {
              modelStats.avg_price_change_portion_sold != null &&
              <div>
                {Math.round(modelStats.avg_price_change_portion_sold * 100)}% average price change
              </div>
            }

            {
              modelStats.avg_sale_price != null &&
              <div>
                {Formatters.formatCurrency(modelStats.avg_sale_price)} average sale price
              </div>
            }

            {
              modelStats.avg_num_price_changes != null &&
              <div>
                {pluralize('average number of price change', modelStats.avg_num_price_changes.toFixed(1), true)}
              </div>
            }

            {
              modelStats.avg_mileage != null &&
              <div>
                {Formatters.formatNumber(modelStats.avg_mileage)} average mileage
              </div>
            }

            {
              modelStats.avg_acv != null &&
              <div>
                {Formatters.formatCurrency(modelStats.avg_acv)} average ACV
              </div>
            }

            {
              modelStats.avg_costs != null &&
              <div>
                {Formatters.formatCurrency(modelStats.avg_costs)} average costs
              </div>
            }

            {
              modelStats.avg_portion_of_market != null &&
              <div>
                {Formatters.formatNumber(modelStats.avg_portion_of_market * 100, {maximumFractionDigits: 1})} average % of market
              </div>
            }
          </div>
          <div>
            <VehiclesListContainer
              getScrollParent={getScrollParent}
              renderRow={renderRow}
              rowOptions={{target: '_blank'}}
              query={lotHistoryListQuery}
              order='sold_at DESC'
              containerStyle={{minHeight: 'inherit'}}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default LotHistoryModal
