import TFDispatcher from './TFDispatcher'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes

export default {

  loginResponseReceived: function(response, user, errors) {
    TFDispatcher.dispatchAction(ActionTypes.LOGIN_RESPONSE, null, {response: response, user: user, errors: errors} )
  },

  submitForgotPasswordResponseReceived: function(errors) {
    TFDispatcher.dispatchAction(ActionTypes.FORGOT_PASSWORD_RECEIVED, null, {errors: errors} )
  },

  setNewPasswordResponseReceived: function(errors) {
    TFDispatcher.dispatchAction(ActionTypes.SET_PASSWORD_RECEIVED, null, {errors: errors} )
  },

  userRefreshReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_REFRESH_RECEIVED, null, {user: json, errors: errors} )
  },

  heartbeatReceived: function(json, hasSessionControl) {
    TFDispatcher.dispatchAction(ActionTypes.HEARTBEAT_RECEIVED, null, {payload: json, hasSessionControl: hasSessionControl} )
  },

  newsReceived: function(json) {
    TFDispatcher.dispatchAction(ActionTypes.NEWS_RECEIVED, null, {news: json} )
  },

  accountDetailsReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.ACCOUNT_DETAILS_RECEIVED, null, {accountDetails: json} )
  },

  availableOfferingsReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.AVAILABLE_OFFERINGS_RECEIVED, null, {availableOfferings: json} )
  },

  pricingForOfferingsReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.PRICING_FOR_OFFERINGS_RECEIVED, null, {pricingForOfferings: json} )
  },

  pricingForBillingIntervalChangeReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.PRICING_FOR_BILLING_INTERVAL_CHANGE_RECEIVED, null, {pricingForOfferings: json} )
  },

  offeringsAdded: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.OFFERINGS_ADDED, null, {user: json} )
  },

  offeringsSlateReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.OFFERINGS_SLATE_RECEIVED, null, {offeringsSlate: json} )
  },

  reactivateAccountReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.REACTIVATE_ACCOUNT_RECEIVED, null, {user: json} )
  },

  updateBillingIntervalReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.UPDATE_BILLING_INTERVAL_RECEIVED, null, {user: json} )
  },

  ccUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.CC_UPDATED, null, {user: json} )
  },

  ccRetried: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.CC_RETRIED, null, {user: json} )
  },

  reclaimControlReceived: function(hasSessionControl) {
    TFDispatcher.dispatchAction(ActionTypes.RECLAIM_CONTROL_RECEIVED, null, {hasSessionControl: hasSessionControl} )
  },

  userUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_UPDATED, null, {user: json, errors: errors} )
  },

  rooftopUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.ROOFTOP_UPDATED, null, {user: json, errors: errors} )
  },

  userAuctionFiltersUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_AUCTION_FILTERS_UPDATED, null, {user: json, errors: errors} )
  },

  userAdded: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_ADDED, null, {user: json, errors: errors} )
  },

  additionalUserPricingReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.ADDITIONAL_USER_PRICING_RECEIVED, null, {pricing: json, errors: errors} )
  },

  carfaxCredentialsUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_CARFAX_CREDENTIALS_UPDATED, null, {user: json, errors: errors} )
  },

  carfaxCredentialsRemoved: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_CARFAX_CREDENTIALS_REMOVED, null, {user: json, errors: errors} )
  },

  carfaxConnectAuthorizeUrlRecieved: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.CARFAX_AUTHORIZE_URL_RECEIVED, null, {carfaxConnectAuthorizeUrl: json.carfax_connect_authorize_url , errors: errors} )
  },

  carfaxConnectLoggedOut: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_LOGOUT_CARFAX_CONNECT_RECEIVED, null, {user: json, errors: errors} )
  },

  autoCheckReportReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_AUTOCHECK_REPORT, null, {report: json, errors: errors} )
  },

  mileageFamilyReceived: function(json, errors, vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_MILEAGE_FAMILY, null, {mileageFamily: json, vehicleUUID: vehicleUUID, errors: errors} )
  },

  autoCheckCredentialsUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_AUTOCHECK_CREDENTIALS_UPDATED, null, {user: json, errors: errors} )
  },

  autoCheckCredentialsRemoved: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.USER_AUTOCHECK_CREDENTIALS_REMOVED, null, {user: json, errors: errors} )
  },

  valuationReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VALUATION, null, {valuation: json, errors: errors, clientID: params.clientID, isNew: params.isNew ? params.isNew : false} )
  },

  publicVINValuationReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_PUBLIC_VALUATION, null, {valuation: json, errors: errors} )
  },

  valuationPreviewReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VALUATION_PREVIEW, null, {preview: json, errors: errors, clientID: clientID} )
  },

  previewForVehicleReceived:  function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_PREVIEW_FOR_VEHICLE, null, {preview: json, errors: errors} )
  },

  vehicleChoicesUpdated:  function() {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_UPDATE_VALUATION_CHOICES, null, {} )
  },

  existingValuationReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_EXISTING_VALUATION, null, {valuation: json, errors: errors, clientID: clientID} )
  },

  valuationUpdated: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_UPDATE_VALUATION, null, {valuation: json, errors: errors, clientID: clientID} )
  },

  valuationChoiceUpdated: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_UPDATE_VALUATION_CHOICE, null, {valuation: json, errors: errors, clientID: clientID} )
  },

  valuationDeleted: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_DELETE_VALUATION, null, {valuation: json, errors: errors, clientID: clientID} )
  },

  valuationLinkedToPartner: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_LINKED_VALUATION_PARTNER, null, {valuation: json, errors: errors, clientID: clientID} )
  },

  valuationNoteCreated: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.VALUATION_NOTE_CREATED, null, {valuation: json, errors: errors, clientID: clientID} )
  },

  vehicleNotesRecieved: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VALUATION_NOTES, null, {notes: json, errors: errors, clientID: clientID} )
  },

  unauthorizedUserReceived: function() {
    TFDispatcher.dispatchAction(ActionTypes.UNAUTHORIZED_USER, null)
  },

  invalidAPIVersion: function() {
    TFDispatcher.dispatchAction(ActionTypes.INVALID_API_VERSION, null)
  },

  serverMaintenanceMode: function(messages) {
    TFDispatcher.dispatchAction(ActionTypes.SERVER_MAINTENANCE_MODE, null, messages)
  },

  vehiclesHomeReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VEHICLES_HOME, null, {data: json, errors: errors} )
  },

  pmrSampleDetailReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_PMR_SAMPLE, null, {sample: json, errors: errors, clientID: clientID} )
  },

  pmrAllSamplesReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_ALL_PMR_SAMPLES, null, {samples: json, errors: errors, clientID: clientID} )
  },

  folderCreated: function() {
    TFDispatcher.dispatchAction(ActionTypes.FOLDER_CREATED)
  },

  folderUpdated: function() {
    TFDispatcher.dispatchAction(ActionTypes.FOLDER_UPDATED)
  },

  foldersOrderUpdated: function() {
    TFDispatcher.dispatchAction(ActionTypes.FOLDERS_ORDER_UPDATED)
  },

  folderDeleted: function() {
    TFDispatcher.dispatchAction(ActionTypes.FOLDER_DELETED)
  },

  foldersReceived: function(json) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_FOLDERS, null, {folders: json})
  },

  loadFolderInfoReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_FOLDER_INFO, null, {folder: json, errors: errors, clientID: params.clientID} )
  },

  recentValuationsReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_RECENT_VALUATIONS, null, {valuations: json, errors: errors, listResponseType: params.listResponseType, clientID: params.clientID, order: params.order} )
  },

  valuationsCorpusReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VALUATIONS_CORPUS, null, {corpus: json, errors: errors} )
  },

  valuationsSearchReceived: function(json, errors, { order, listResponseType }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VALUATIONS_SEARCH, null, {results: json, errors, order, listResponseType} )
  },

  carfaxSummaryRecieved: function(json, errors, vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_CARFAX_SUMMARY, null, {summary: json, errors: errors, vehicleUUID: vehicleUUID} )
  },

  vintelReportReceived: function(json, errors, vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VINTEL_REPORT, null, {report: json, errors, vehicleUUID} )
  },

  autoCheckSummaryRecieved: function(json, errors, vehicleUUID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_AUTOCHECK_SUMMARY, null, {summary: json, errors: errors, vehicleUUID: vehicleUUID} )
  },

  availableYearsReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_AVAILABLE_YEARS, null, {availableYears: json, errors: errors} )
  },

  availableMakesModelsReceived: function(json, errors) {
    let makes = null
    let year = null
    // FIXME: need this since the server is super flaky
    if (json && !errors) {
      makes = json.makes
      year = json.year
    }

    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_AVAILABLE_MAKES_MODELS, null, {year: year, availableMakesModels: makes, errors: errors} )
  },

  auctionVehiclesSearchReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_VEHICLES_SEARCH_RECEIVED, null, {vehicles: json, errors: errors, page: params.page, clientID: params.clientID, queryID: params.queryID} )
  },

  dotAuctionEventsReceived: function(json, errors, page) {
    TFDispatcher.dispatchAction(ActionTypes.DOT_AUCTION_EVENTS_RECEIVED, null, {events: json, errors: errors, page: page} )
  },

  auctionLiveSalesStatusReceived: function(json) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LIVE_SALES_STATUS_RECEIVED, null, {status: json} )
  },

  auctionsByStateReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTIONS_BY_STATE_RECEIVED, null, {auctions: json, errors: errors} )
  },

  auctionListingUpdated: function() {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LISTING_UPDATED, null )
  },

  auctionListingReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LISTING_RECEIVED, null, {auctionListing: json, errors: errors, storeWithSourceID: params?.storeWithSourceID} )
  },

  auctionListingPrefetchReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LISTING_PREFETCH_RECEIVED, null, {auctionListing: json, errors: errors} )
  },

  nextAuctionLanesReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.NEXT_AUCTION_SALES_RECEIVED, null, {locations: json, errors: errors} )
  },

  auctionListingForLaneReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LISTINGS_FOR_LANE_RECEIVED, null, {listings: json, errors: errors, clientID: params.clientID, afterRunNumber: params.afterRunNumber} )
  },

  auctionListingForSaleReceived: function(json, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LISTINGS_FOR_SALE_RECEIVED, null, {listings: json, errors: errors, clientID: params.clientID, offset: params.offset} )
  },

  auctionsWatchlistReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_WATCHLIST_RECEIVED, null, {vehicles: json, errors: errors} )
  },

  auctionsWatchlistArchiveReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_WATCHLIST_ARCHIVE_RECEIVED, null, {vehicles: json, errors} )
  },

  auctionsWatchlistCountsReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_WATCHLIST_VEHICLE_COUNTS_RECEIVED, null, {counts: json, errors} )
  },

  vinWatchlisted: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.VIN_WATCHLISTED, null, {vehicle: json, errors: errors, clientID: clientID} )
  },

  auctionsForVINReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTIONS_FOR_VIN_RECEIVED, null, {auction: json, clientID: clientID} )
  },

  valuationShareCreated: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.VALUATION_SHARE_CREATED, null, {valuationShare: json, errors: errors, clientID: clientID} )
  },

  sharedValuationReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_SHARED_VALUATION, null, {valuation: json, errors: errors} )
  },

  photoUpdated: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.PHOTO_UPDATED, null, {photo: json, errors: errors, clientID: clientID} )
  },

  photoDeleted: function(json, errors, targetData) {
    TFDispatcher.dispatchAction(ActionTypes.PHOTO_DELETED, null, {deletedPhotoID: targetData['photoID'], errors: errors, clientID: targetData['clientID']} )
  },

  vehicleActivitiesReceived: function(json, errors, { listResponseType, clientID, order }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VEHICLE_ACTIVITIES, null, {data: json, errors, listResponseType, clientID, order} )
  },

  edgeAuthorizeURLReceieved: function(json) {
    TFDispatcher.dispatchAction(ActionTypes.EDGE_AUTHORIZE_URL_RECEIVED, null, {url: json} )
  },

  auctionListingBidsReceived: function(json) {
    TFDispatcher.dispatchAction(ActionTypes.AUCTION_LISTING_BIDS_RECEIVED, null, {listingBids: json} )
  },

  llmSampleDetailReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_LLM_SAMPLE_DETAIL, null, {sampleDetail: json, errors: errors, clientID: clientID} )
  },

  llmTrimSamplesReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_LLM_TRIM_SAMPLES, null, {samples: json, errors: errors, clientID: clientID} )
  },

  myLotVehiclesReceived: function(data, errors, { clientID, page }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_MY_LOT_VEHICLES, null, {data, errors, clientID, page} )
  },

  myLotVehiclesDetailsReceived: function(data, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_MY_LOT_VEHICLES_DETAILS, null, {data, errors} )
  },

  myLotReportReceived: function(data, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_MY_LOT_REPORT, null, {data, errors} )
  },

  myLotCompetitorsReportReceived: function(data, errors, { clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_MY_LOT_COMPETITORS_REPORT, null, { data, errors, clientID } )
  },

  lotVehicleUpdated: function(json, errors, { clientID, params }) {
    TFDispatcher.dispatchAction(ActionTypes.LOT_VEHICLE_UPDATED, null, {lotVehicle: json, errors: errors, clientID, params} )
  },

  regionalReportReceived: function(data, errors, params) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_REGIONAL_REPORT, null, { data, errors, params } )
  },

  regionalListingsReceived: function(data, errors, { page, clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_REGIONAL_LISTINGS, null, { data, errors, clientID, page } )
  },

  regionalModelStatsReceived: function(data, errors, { clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_REGIONAL_MODEL_STATS, null, { data, errors, clientID } )
  },

  regionalModelScorecardReceived: function(data, errors, { clientID }) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_REGIONAL_MODEL_SCORECARD, null, { data, errors, clientID } )
  },

  mostRecentRetailListingReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_MOST_RECENT_RETAIL_LISTING, null, {mostRecentRetailListing: json, errors} )
  },

  savedSearchesReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.SAVED_SEARCHES_RECEIVED, null, {savedSearches: json, errors} )
  },

  savedSearchReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.SAVED_SEARCH_RECEIVED, null, {savedSearch: json, errors, clientID: clientID} )
  },

  globalSavedSearchUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.GLOBAL_SAVED_SEARCH_UPDATED, null, {savedSearch: json, errors} )
  },

  savedSearchDeleted: function(errors) {
    TFDispatcher.dispatchAction(ActionTypes.SAVED_SEARCH_DELETED, null, {errors} )
  },

  toggleUserGlobalAuctionFiltersUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.TOGGLE_USER_GLOBAL_AUCTION_FILTERS_UPDATED, null, {user: json, errors} )
  },

  coordinatesForZipCodeReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.COORDINATES_FOR_ZIPCODE_RECEIVED, null, {coordinates: json, errors} )
  },

  logoutAuctionEdgeReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.LOGOUT_AUCTION_EDGE_RECEIVED, null, {user: json, errors} )
  },

  vehicleReportReceived: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_VEHICLE_REPORT, null, {vehicleReport: json, errors: errors, clientID} )
  },

  vehicleReportPurchased: function(json, errors, clientID) {
    TFDispatcher.dispatchAction(ActionTypes.VEHICLE_REPORT_PURCHASED, null, {vehicleReport: json, errors: errors, clientID} )
  },

  permissionsUpdated: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.PERMISSIONS_UPDATED, null, {user: json, errors: errors} )
  },

  vdsBaseCorpusReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.VDS_BASE_CORPUS_RECEIVED, null, {corpus: json, errors: errors} )
  },

  vdsMakesCorpusReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.VDS_MAKES_CORPUS_RECEIVED, null, {corpus: json, errors: errors} )
  },

  loginWithApolloCampaignContactIDReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_APOLLO_CAMPAIGN_CONTACT_ID, null, {response: json, errors: errors} )
  },

  verifyApolloCampaignContactIDReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.VERIFIED_APOLLO_CAMPAIGN_CONTACT_ID, null, {response: json, errors: errors} )
  },

  registerWithPartnerOriginCodeReceived: function(json, errors) {
    TFDispatcher.dispatchAction(ActionTypes.RECEIVED_REGISTER_WITH_PARTNER_ORIGIN_CODE, null, {response: json, errors: errors} )
  }
}
