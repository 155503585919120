import { memo } from 'react'
import { Line } from 'react-chartjs-2'
import merge from 'lodash/merge'

const getChartProps = ({
  data,
  options = {},
  plugins = [],
  onClick,
}) => {
  const defaultOptions = {
    clip: false,
    interaction: {
      intersect: false,
      mode: 'index',
      axis: 'x',
    },
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 20,
      },
    },
    onClick: (_el, items) => {
      // Assumes single item
      if (!onClick) return
      const [item] = items
      if (!item) return
      onClick(item.element.$context)
    },

    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        align: 'center',
        maxHeight: 24,
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
        },
      },
      datalabels: {
        display: 'auto',
        padding: 0,
        anchor: 'end',
        align: 'top',
      },
      tooltip: {
        usePointStyle: true,
        displayColors: false,
        boxWidth: 9,
        boxHeight: 9,
        boxPadding: 4,
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          padding: 1,
        },
      },
      y: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        borderColor: 'rgba(44, 110, 142, 1)',
        fill: {
          above: 'rgba(44, 110, 142, 0.1)',
          target: 'origin',
        },
      },
      point: {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0)',
        radius: 4,
        hoverRadius: 4,
        hoverBorderWidth: 1,
        backgroundColor: 'rgba(44, 110, 142, 1)',
        hoverBorderColor: 'rgba(44, 110, 142, 1)',
      },
    },
  }

  return {
    options: merge(defaultOptions, options),
    data,
    plugins,
  }
}

const getOrCreateLegendList = (_chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.flexWrap = 'wrap';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.style.justifyContent = 'center';
    listContainer.style.columnGap = '10px';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, _args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach(item => {
      const li = document.createElement('li')
      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'
      li.style.display = 'flex'
      li.style.flexDirection = 'row'
      li.style.fontSize = '12px'

      li.onclick = () => {
        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        chart.update()
      }

      // Color box
      const size = 10
      const boxSpan = document.createElement('span')
      boxSpan.style.background = item.fillStyle
      boxSpan.style.display = 'inline-block'
      boxSpan.style.flexShrink = 0
      boxSpan.style.marginRight = size / 2 + 'px'
      boxSpan.style.width = size + 'px'
      if (item.pointStyle === 'circle') {
        boxSpan.style.height = size + 'px'
        boxSpan.style.borderRadius = size / 2 + 'px'
      } else if (item.pointStyle === 'line') {
        boxSpan.style.height = '3px'
      }

      // Text
      const textContainer = document.createElement('div')
      textContainer.style.color = item.fontColor
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  }
}

const LineChart = memo(props => {
  const { height, plugins: propsPlugins, ...rest } = props
  const legendContainerId = rest.options?.plugins?.htmlLegend?.containerID
  let finalProps = rest
  if (legendContainerId) {
    finalProps = {
      ...finalProps,
      plugins: [
        ...(propsPlugins || []),
        htmlLegendPlugin,
      ],
    }
  }

  const chartProps = getChartProps(finalProps)

  return (
    <div>
      <div style={{ height }}>
        <div style={{width: '100%', height: '100%', position: 'relative'}}>
          <Line {...chartProps} />
        </div>
      </div>
      { legendContainerId && <div id={legendContainerId} /> }
    </div>
  )
})

export default LineChart
