import React from 'react'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'
import CommonFunctions from '../../api/constants/CommonFunctions'
import ProviderLogo from '../common/icons/providers/ProviderLogo'
import { Link } from 'react-router-dom/cjs/react-router-dom'


export default class BooksUpsellList extends React.Component {
  state = {
    isLoadingAvailableOfferings: true,
    availableOfferings: null
  }

  componentDidMount() {
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)
  
    if (SessionStore.isAccountOwner()) {
      UserActionCreators.loadAvailableOfferings()
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
  }

  availableOfferingsReceived = () => {
    const reorderedAvailableOfferings = CommonFunctions.reorderOfferingsForDisplay(SessionStore.availableOfferings?.offerings)

    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: reorderedAvailableOfferings
    })
  }

  render() {
    if (!SessionStore.isAccountOwner()) { return null }

    const { isLoadingAvailableOfferings, availableOfferings } = this.state

    if (isLoadingAvailableOfferings || !availableOfferings || availableOfferings.length === 0) {
      return null
    }

    return (
      <div style={{margin: '0 40px'}}>
        <h2>Want more?</h2>
        <div><Link to='/account'>Manage your account</Link> and add the Carbly features you need.</div>
        <Link to='/account'>
         <div className='upsell-logos'>
          {
            availableOfferings.map((offering) => {
              let providerKey = offering.provider_key
              if (!providerKey && offering.sku === AppConstants.skuMappings['carbly_plus_bundle']) {
                providerKey = 'carbly_plus_bundle'
              } else if (!providerKey && offering.sku === AppConstants.skuMappings['my_lot']) {
                providerKey = 'lot_sense'
              } else if (!providerKey && offering.sku === AppConstants.skuMappings['auctions_plus']) {
                providerKey = 'auctions_plus'
              }
              return (
                <ProviderLogo key={`logo-${offering.sku}`} providerKey={providerKey} /> 
              )
            })
          }
          </div>
        </Link>
      </div>
    )
  }
}

